import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';

import { NgxSpinnerModule } from 'ngx-spinner';
import { DataTablesModule } from "angular-datatables";

import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { IdeasComponent } from './ideas/ideas.component';
import { AuthorizeComponent } from './authorize/authorize.component';
import { ViewAbstractComponent } from './view-abstract/view-abstract.component';
import { PublicationsComponent } from './publications/publications.component';
import { NewsComponent } from './news/news.component';
import { ClinicalTrailsComponent } from './clinical-trails/clinical-trails.component';
import { HomeComponent } from './home/home.component';

// Define the routes
const routes:Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'ideas',
    component: IdeasComponent
  },
  {
    path: 'list-ideas/:category',
    component: IdeasComponent
  },
  {
    path: 'authorize/:token',
    component: AuthorizeComponent
  },
  {
    path: 'view-abstract/:abstractid',
    component: ViewAbstractComponent
  },
  {
    path: 'view-abstract',
    component: ViewAbstractComponent
  },
  {
    path: 'publications',
    component: PublicationsComponent
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'clinical-trials',
    component: ClinicalTrailsComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    IdeasComponent,
    AuthorizeComponent,
    ViewAbstractComponent,
    PublicationsComponent,
    NewsComponent,
    ClinicalTrailsComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    SharedModule,
    HttpModule,
    FormsModule,
    NgxSpinnerModule,
    DataTablesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

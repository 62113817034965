import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';

@Injectable()
export class UserService {
    constructor(private http: Http) {}

    authorize(token) {
        var url = "/api/authorize?token="+token
 
        return this.http.get(url)
            .map(res => res.json())
    }

    login(user) {
        var url = "/api/login"

        return this.http.post(url, user)
            .map(res => res.json())
    }

    logSearch(searchData) {
        var url = "/api/save-search"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, searchData, options)
            .map(res => res.json())
    }

    getIdeas(id, category, filters = null) {
        var url = "/api/ideas?id="+id

        if(category != 'all') {
            url += '&category='+category
        }

        console.log(filters)

        for (let filter in filters) {
            if(filters[filter] instanceof Array) {
                filters[filter].forEach(keyword => {
                    url += '&'+filter+'='+keyword
                });
            } else {
                url += '&'+filter+'='+filters[filter]
            }
        }

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    getTargetCount(id, category) {
        var url = "/api/target-count?id="+id+"&category="+category

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    saveAbstract(id, abstractId) {
        let url = "/api/save-abstract"
        let data = {
            id: id,
            abstractId: abstractId
        }

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, data, options)
            .map(res => res.json())
    }

    claimAbstract(id, abstractId) {
        let url = "/api/claim-abstract"
        let data = {
            id: id,
            abstractId: abstractId
        }

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, data, options)
            .map(res => res.json())
    }

    unSaveAbstract(id, abstractId) {
        let url = "/api/unsave-abstract"
        let data = {
            id: id,
            abstractId: abstractId
        }

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, data, options)
            .map(res => res.json())
    }

    unClaimAbstract(id, abstractId) {
        let url = "/api/unclaim-abstract"
        let data = {
            id: id,
            abstractId: abstractId
        }

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, data, options)
            .map(res => res.json())
    }

    getOrganizationTypes() {
        var url = "/api/organization-type"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    saveTargets(user) {
        let url = "/api/saveTargets"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, user, options)
            .map(res => res.json())
    }

    saveMemberTargets(memberId, targets) {
        let url = "/api/saveMemberTargets"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, {
            memberId: memberId,
            targets: targets
        }, options)
            .map(res => res.json())
    }

    updatePrefJournal(memberId, prefJournals) {
        let url = "/api/saveMemberPrefJournal"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, {
            memberId: memberId,
            prefJournals: prefJournals
        }, options)
            .map(res => res.json())
    }

    updateViewCount(id) {
        let url = "/api/updateAbstractViewCount"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, {
                    abstractId: id
                }, options)
            .map(res => res.json())
    }

    getTopMatchedKeywords(id, abstractId) {
        let url = "/api/getTopMatchedKeywords?id="+id+"&abstractId="+abstractId

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    getNews(term) {
        let url = "/api/getNews?term="+term
        
        return this.http.request(url)
        .map(res => {
          return res.json()
        });
    }

    getClinicalTrials(term, start, end) {
        let url = "/api/getClinicalTrials?term="+term+"&start="+start+"&end="+end
        
        return this.http.request(url)
        .map(res => {
          return res.json()
        });
    }

    getClinicalTrialsForTerm(term, start, end) {
        let url = "https://clinicaltrials.gov/api/query/study_fields?expr="+encodeURI(term)+"&fields=BriefTitle,OfficialTitle,NCTId,Condition,Phase,StartDate,StartDateType,CompletionDate,CompletionDateType,StudyType,LastKnownStatus,ResponsiblePartyInvestigatorFullName,ResponsiblePartyOldNameTitle,ResponsiblePartyInvestigatorAffiliation,ResponsiblePartyOldOrganization,LeadSponsorName,DetailedDescription&fmt=json&min_rnk="+start+"&max_rnk="+end
        return this.http.get(url)
            .map(res => res.json())
    }

    getClinicalTrialsFilters(term, start, end) {
        let url = "https://clinicaltrials.gov/api/query/study_fields?expr="+encodeURI(term)+"&fields=Phase,ResponsiblePartyInvestigatorFullName,ResponsiblePartyInvestigatorAffiliation,LeadSponsorName&fmt=json&min_rnk=1&max_rnk=100"
        return this.http.get(url)
            .map(res => res.json())
    }

    eSearchforTerm(term, sortBy, page, publishYearFilter) {
        let retStart = (page - 1) * 10
        let url = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&term="+term+"&retmode=json&datetype=pdat&retstart="+retStart+"&retmax=20&sort="+sortBy

        if(publishYearFilter) {
            url += "&reldate="+(publishYearFilter * 365)
        }

        return this.http.get(url)
            .map(res => res.json())
    }

    eSummaryforUID(uids) {
        let url = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&id="+uids+"&retmode=json"
        
        return this.http.get(url)
            .map(res => res.json())
    }

    eFetchForUID(uid) {
        let url = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=pubmed&id="+uid+"&retmode=xml&rettype=abstract"
        
        return this.http.get(url)
            .map(res => res.text())
    }

    eFetchForUIDText(uid) {
        let url = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=pubmed&id="+uid+"&retmode=text&rettype=abstract"
        
        return this.http.get(url)
            .map(res => res.text())
    }
    
    getSavedAbstracts(id, start, limit) {
        let url = "/api/getSavedAbstracts?id="+id+"&start="+start+"&limit="+limit

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }
    
    getClaimedAbstracts(id, start, limit) {
        let url = "/api/getClaimedAbstracts?id="+id+"&start="+start+"&limit="+limit

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    getCommonWords() {
        let url = "/api/getCommmonWords"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    getAbstractById(userId) {
        var url = "/api/abstract/"+userId

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    getMemberById(userId) {
        var url = "/api/member-by-id/"+userId

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    getMemberTargets(id) {
        let url = "/api/getMemberTargets?id="+id

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    saveMember(member) {
        var url = "/api/save-member"
        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, member, options)
            .map(res => res.json())
    }

    getCountryList() {
        var url = "/api/country-list"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    getAuthorsList() {
        var url = "/api/author-list"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    saveToMySpace(id, ideaType, member, target, ideas) {
        let url = "/api/save-to-myspace"
        let data = {
            id: id,
            ideaType: ideaType,
            ideas: ideas,
            member: member,
            target: target
        }

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, data, options)
            .map(res => res.json())
    }

    getMySpaceIdeas(id) {
        let url = "/api/get-myspace-ideas?id="+id

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    unsaveFromMySpace(id, ideaType, targetId) {
        let url = "/api/unsave-from-myspace"
        let data = {
            id: id,
            ideaType: ideaType,
            targetId: targetId
        }

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, data, options)
            .map(res => res.json())
    }

    getPipelines(filters = null) {
        var url = "/api/pipelines?"

        console.log(filters)

        for (let filter in filters) {
            if(filters[filter] instanceof Array) {
                filters[filter].forEach(keyword => {
                    url += '&'+filter+'='+keyword
                });
            } else {
                url += '&'+filter+'='+filters[filter]
            }
        }

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }
}
import { Component, OnInit, Renderer2, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { UserService } from '../shared/user.service';

declare var pleaseWait :any;

import { NgxSpinnerService } from 'ngx-spinner';

import { Router, ActivatedRoute, Params, NavigationExtras } from "@angular/router";

import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-ideas',
  templateUrl: './ideas.component.html',
  styleUrls: ['./ideas.component.css']
})
export class IdeasComponent implements OnInit {

  public targetTitle: any = []
  public abstractData: any = {
    "ideaSubmitted": {},
    "ideaFunded": {}
  }
  public types: any = []
  public abstracts: any = []
  public totalRecordsInDb: number = 0
  public allIdeasCount: number = 0
  public targetedIdeasCount: number = 0
  public filteredRecords: number = 0
  public recordLength: number = 10
  public pagination: any = []
  public selectedPage: number = 1
  public totalPages: number = 1
  public columnSortOrder: any = {
    'relevance' : {
      'sortBy': 'score'
    }, 'pIName' : {
      'sortBy': 'researcher.name.last.keyword,researcher.name.first.keyword',
      'sortOrder': '1,1'
    }, 'organization' : {
      'sortBy': 'researcher.organization.keyword',
      'sortOrder': '1'
    }, 'FYearDesc' : {
      'sortBy': 'FYear.keyword',
      'sortOrder': '-1'
    }, 'FYearAsc' : {
      'sortBy': 'FYear.keyword',
      'sortOrder': '1'
    }, 'submittedOnDesc' : {
      'sortBy': 'submittedOn',
      'sortOrder': '-1'
    }, 'submittedOnAsc' : {
      'sortBy': 'submittedOn',
      'sortOrder': '1'
    }
  }

  public targetSelecteAll: boolean = false
  public memberTargetSelecteAll: any = {}

  public browseBy: string = 'targets';

  public analytics: any = []
  public timeTakenByQuery: number = 0

  public selectedTargetIndex: number = 0;
  public showClearAll: any = {}
  
  public organizationTypes: any = []
  public fYears: any = []
  public countries: any = []
  public countriesList: any = []
  public authorsList: any = []
  
  public ideaTypesColumnMapping: any = {
    'Funded': 'isIdeaFunded',
    'New Ideas': 'isNewIdea',
    'Submitted': 'isIdeaSubmitted',
    'Tech Transfer':'isTechTransfer'
  }
  public ideaTypes: any = {}
  public ideaTypesKeys: any = Object.keys(this.ideaTypesColumnMapping)


  public searchKeyword: any = ''
  public filterType: any = 'any'
  public selectedFilterText: string = 'Displaying Ideas containing ANY of the search terms'

  public appliedFilters: any = {
    'targets': [],
    'ideaTypes': [],
    'organizationTypes': [],
    'fYears': [],
    'countries': [],
    'authors': []
  }

  public category = 'targets'

  public user: any = {}
  public userType: string = ''
  public memberTargets: any = []

  public showEmptyResultMsg: boolean = false;

  public sortBy: string = 'relevance'

  public searchBy: string = 'keyword'
  public advancedSearch: boolean = false

  public showEmptyMatchResult: boolean = false
  public hasExactMatch: boolean = false
  public exactMatch: any = {}

  public loadingExactMatch: boolean = true
  public loadingSearchResult: boolean = true

  public relevantResultKeywords: string = ''
  public similarArticilesHeading: string = 'Displaying most relevant results'

  public selectedMember: number;

  public activeTabs: any = []
  public memberDetailsModal: string = ''
  public organizationData: any = {}
  public selectedTab: string = 'organization'

  public memberTypeList = [
    "Subordinate",
    "Restricted Member"
  ]

  @ViewChild('submitAbstractModel') submitAbstractModel:ElementRef;
  @ViewChild('closeAbstractModel') closeAbstractModel:ElementRef;
  @ViewChild('abstractFormElement') abstractForm:ElementRef;
  @ViewChild('editTargets') editTargets:ElementRef;
  @ViewChild('searchField') searchField: ElementRef;
  @ViewChild('editTargetsClose') editTargetsClose: ElementRef;
  @ViewChild('editMemberTargetsClose') editMemberTargetsClose: ElementRef
  @ViewChild('closeSetupOrganizationModel') closeSetupOrganizationModel:ElementRef;

  constructor(private userService: UserService, private renderer: Renderer2, private router: Router, private activatedRouter: ActivatedRoute, private chRef: ChangeDetectorRef, private spinner: NgxSpinnerService) {
    this.renderer.addClass(document.body, 'bg-light');
    this.renderer.removeClass(document.body, 'bg-blue');

    this.activatedRouter.queryParams.subscribe(params => {
      if(typeof params['sortBy'] != 'undefined') {
        this.sortBy = 'submittedOnDesc'
        this.appliedFilters['sortBy'] = this.columnSortOrder['submittedOnDesc'].sortBy || false
        this.appliedFilters['sortOrder'] = this.columnSortOrder['submittedOnDesc'].sortOrder || false
      }
    });

    if(localStorage.getItem('sortBy')) {
      this.sortBy = 'submittedOnDesc'
      this.appliedFilters['sortBy'] = this.columnSortOrder['submittedOnDesc'].sortBy || false
      this.appliedFilters['sortOrder'] = this.columnSortOrder['submittedOnDesc'].sortOrder || false
    }
  }

  ngOnInit() {
    if (localStorage.getItem("user") === null) {
      localStorage.setItem('redirectUrl', window.location.href)
      // window.location.href = 'http://login.onixhub.com'
      window.location.href = 'http://login.onixhub.com'
    } else if (localStorage.getItem('redirectUrl') != null) {
      let redirectUrl = localStorage.getItem('redirectUrl')
      localStorage.removeItem('redirectUrl')
      window.location.href = redirectUrl
    }

    if(localStorage.getItem('searchKeyword') != null) {
      this.searchKeyword = localStorage.getItem('searchKeyword')
      this.searchBy = localStorage.getItem('searchBy')
      this.targetSelecteAll = false
    }

    this.user = JSON.parse(localStorage.getItem('user'))

    this.userType = this.user.member[0].type

    this.ideaTypesKeys.forEach((key, i) => this.ideaTypes[key] = 0)

    this.activatedRouter.queryParams.subscribe((params: Params) => {

      if(params['loadWithFilters'] == 'true') {
        this.appliedFilters = JSON.parse(params.appliedFilters)

        console.log(this.appliedFilters);
        this.searchKeyword = params.searchKeyword
        this.totalRecordsInDb = params.totalRecordsInDb
        this.browseBy = params.browseBy
        this.selectedPage = params.pageNo
      }

      let category = 'targets'

      this.userService.getMemberById(this.user._id).subscribe(
        (organization) => {
          this.activeTabs = []
          console.log(organization)
          this.memberDetailsModal = 'Update member details'
          this.organizationData = organization
          this.organizationData.member[0].confirmEmail = this.organizationData.member[0].email
          this.selectTab('organization')
        }
      )
      
      $(document).on('click', '.caret', function() {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      })

      var popOverSettings = {
        placement: 'right',
        trigger: 'hover',
        html: true,
        selector: '[data-toggle="popover"]'
      }
      
      $('body').popover(popOverSettings);

      this.userService.getOrganizationTypes().subscribe(
        organizationTypes => {
          if(organizationTypes.success || organizationTypes.success == false) this.sessionTimeOut()
          this.organizationTypes = organizationTypes

          
          this.userService.getTargetCount(this.user._id, category).subscribe(
            analytics => {
              analytics.forEach((keyword, keywordIndex) => {

                if(params['loadWithFilters'] == 'true') {
                  if(this.appliedFilters['targets'].indexOf(keyword.keyword) != -1) {
                    this.showClearAll[keyword.title] = (localStorage.getItem('searchKeyword') == null)
                  }
                } else {
                  if(keywordIndex == 0) {
                    this.appliedFilters['targets'].push(keyword.keyword)
                    this.showClearAll[keyword.title] = (localStorage.getItem('searchKeyword') == null)
                  }
                }


                if(!this.analytics[keyword.title]) this.analytics[keyword.title] = {
                  count: 0,
                  keywords: []
                }
                this.analytics[keyword.title]['count'] += keyword.count
                this.analytics[keyword.title]['keywords'].push(keyword)
              });

              if(analytics.length == 1) {
                this.targetSelecteAll = true;
              }
              this.targetTitle = Object.keys(this.analytics)


              if(this.user.member[0].type == 'Manager') {
                this.userService.getMemberTargets(this.user._id).subscribe(
                  (memberTargets) => {
                    this.memberTargets = memberTargets[0].member.slice(1)
                    this.memberTargets.forEach((member, memberIndex) => {
                      // this.memberTargetSelecteAll[memberIndex] = (localStorage.getItem('searchKeyword') == null)
                      if(member.target) {
                        if(params['loadWithFilters'] == 'true') {
                          this.memberTargetSelecteAll[memberIndex] = member.target.every((target) => {
                            return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
                          })
                        }

                        member.target.forEach(target => {
                          if(params['loadWithFilters'] == 'true') {
                            if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
                              // this.appliedFilters['targets'].push(target.booleanSearch)
                              this.showClearAll['MT_'+memberIndex+'_'+target.title] = (localStorage.getItem('searchKeyword') == null)
                            }
                          }
                        });
                      }
                    });
                    this.loadIdeasOfPage(true)
                  }
                )
              } else {
                this.memberTargets = this.user.member
                // this.memberTargetSelecteAll[0] = true

                if(params['loadWithFilters'] == 'true') {
                  this.memberTargetSelecteAll[0] = this.user.member[0].target.every((target) => {
                    return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
                  })
                }

                console.log(this.memberTargets)
                this.user.member[0].target.forEach(target => {
                  if(params['loadWithFilters'] == 'true') {
                    if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
                      // this.appliedFilters['targets'].push(target.booleanSearch)
                      this.showClearAll['MT_0_'+target.title] = true
                    }
                  }
                });
                this.loadIdeasOfPage(true)
              }

              console.log(this.memberTargetSelecteAll)
              
            }
          )
        }
      )

      this.category = category
    });

  }

  showKeywordOfTarget(targetIndex) {
    this.selectedTargetIndex = targetIndex
  }

  selectAllTargetTitles(event) {
    if(!this.targetSelecteAll) {
      Object.keys(this.analytics).forEach(target => {
        this.selectAllTarget(target, false)
      });
      this.targetSelecteAll = true
      this.selectAllTarget(null, true)
    } else {
      Object.keys(this.analytics).forEach(target => {
        this.clearAllTarget(target, false)
      });
      this.targetSelecteAll = false
      this.clearAllTarget(null, true)
    }
  }

  selectAllMemberTargets(memberIndex, event) {
    if(!this.memberTargetSelecteAll[memberIndex]) {
      this.memberTargets[memberIndex].target.forEach(target => {
        this.selectMemberTarget(memberIndex, target, false)
      });
      this.memberTargetSelecteAll[memberIndex] = true
      this.selectAllTarget(null, true)
    } else {
      this.memberTargets[memberIndex].target.forEach(target => {
        this.clearAllMemberTarget(memberIndex, target, false)
      });
      this.memberTargetSelecteAll[memberIndex] = false
      this.clearAllMemberTarget(memberIndex, null, true)
    }
  }

  selectMemberTarget(memberIndex, target, refreshPage) {
    if(target) {
      this.appliedFilters['targets'].push(target.booleanSearch)
      this.showClearAll['MT_'+memberIndex+'_'+target.title] = true

      var isAllSelected = this.memberTargets[memberIndex].target.every((target) => {
        return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
      })

      if(isAllSelected) {
        this.memberTargetSelecteAll[memberIndex] = true
      }
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  selectAllTarget(targetTitle, refreshPage) {
    // this.appliedFilters['targets'].push(keyword.keyword)
    if(targetTitle) {
      this.analytics[targetTitle].keywords.forEach(keyword => {
        this.appliedFilters['targets'].push(keyword.keyword)
      })
      this.showClearAll[targetTitle] = true
  
      var isAllSelected = this.user.target.every((target) => {
        return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
      })

      if(isAllSelected) {
        this.targetSelecteAll = true
      }
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  selectAllIdeaType(refreshPage) {
    this.ideaTypesKeys.forEach(ideaTypes => {
      this.appliedFilters['ideaTypes'].push(ideaTypes)
    });
    this.showClearAll['ideaTypes'] = true

    this.selectedPage = 1
    if(refreshPage) this.loadIdeasOfPage()
  }

  selectAllOrgType(refreshPage) {
    this.organizationTypes.forEach(organizationType => {
      this.appliedFilters['organizationTypes'].push(organizationType.type)
    });
    this.showClearAll['organizationTypes'] = true

    this.selectedPage = 1
    if(refreshPage) this.loadIdeasOfPage()
  }

  clearAllTarget(targetTitle, refreshPage) {
    if(targetTitle) {
      if(this.analytics[targetTitle]) {
        this.analytics[targetTitle].keywords.forEach(keyword => {
          let targetIndex = this.appliedFilters['targets'].indexOf(keyword.keyword)
          if(targetIndex != -1) {
            this.appliedFilters['targets'].splice(targetIndex, 1)
          }
        })
        this.targetSelecteAll = false
      } else {
        this.appliedFilters[targetTitle] = []
      }
      this.showClearAll[targetTitle] = false
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  clearAllMemberTarget(memberIndex, target, refreshPage) {
    if(target) {
      let targetIndex = this.appliedFilters['targets'].indexOf(target.booleanSearch)
      if(targetIndex != -1) {
        this.appliedFilters['targets'].splice(targetIndex, 1)
      }
      this.memberTargetSelecteAll[memberIndex] = false
      targetIndex = this.appliedFilters['targets'].indexOf(target.booleanSearch)
      
      this.showClearAll['MT_'+memberIndex+'_'+target.title] = false
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  loadContent(content) {
    this.abstractData = content
  }

  viewAbstract(content) {
    let contentParam = content
    contentParam['appliedFilters'] = JSON.stringify(this.appliedFilters)
    contentParam['searchKeyword'] = this.searchKeyword
    contentParam['totalRecordsInDb'] = this.totalRecordsInDb
    contentParam['browseBy'] = this.browseBy
    contentParam['pageNo'] = this.selectedPage

    let navigationExtras: NavigationExtras = {
        queryParams: contentParam, skipLocationChange: true
    };
    this.router.navigate(['/view-abstract'], navigationExtras)
  }

  logout() {
    swal({
      title: 'Are you sure?',
      text: "Do you want to logout?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I\'m Sure'
    }).then((result) => {
      if (result.value) {
        localStorage.clear()
        // this.router.navigate(['']);
        window.location.href = 'https://onixhub.com'
      }
    })
  }

  sessionTimeOut() {
    localStorage.clear()
    // window.location.href = 'http://login.onixhub.com?sessionTimeout=true'
    window.location.href = 'http://login.onixhub.com?sessionTimeout=true'
  }

  save(abstractId) {
    // swal({
    //   title: 'Are you sure?',
    //   text: "Do you want to save this abstract?",
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'I\'m Sure'
    // }).then((result) => {
    //   if (result.value) {
        this.userService.saveAbstract(this.user.member[0]._id, abstractId).subscribe(
          data => {
            if(data.success || data.success == false) this.sessionTimeOut()
            if(data._id) {
              localStorage.setItem('user', JSON.stringify(data))
              this.user = data
              // swal("Success!", "Abstract has been saved!", "success");
            }
            else swal("Error!", "Abstract has not been saved!", "error");
          }
        )
    //   }
    // })
  }

  claim(abstractId) {
    // swal({
    //   title: 'Are you sure?',
    //   text: "Do you want to save this abstract?",
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'I\'m Sure'
    // }).then((result) => {
    //   if (result.value) {
        this.userService.claimAbstract(this.user.member[0]._id, abstractId).subscribe(
          data => {
            if(data.success || data.success == false) this.sessionTimeOut()
            if(data._id) {
              localStorage.setItem('user', JSON.stringify(data))
              this.user = data
              // swal("Success!", "Abstract has been saved!", "success");
            }
            else swal("Error!", "Abstract has not been saved!", "error");
          }
        )
    //   }
    // })
  }

  unSave(abstractId) {
    // swal({
    //   title: 'Are you sure?',
    //   text: "Do you want to un save this abstract?",
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'I\'m Sure'
    // }).then((result) => {
    //   if (result.value) {
        this.userService.unSaveAbstract(this.user.member[0]._id, abstractId).subscribe(
          data => {
            if(data.success || data.success == false) this.sessionTimeOut()
            if(data._id) {
              localStorage.setItem('user', JSON.stringify(data))
              this.user = data
              // swal("Success!", "Abstract has been removed!", "success");
            }
            else swal("Error!", "Abstract has not been removed!", "error");
          }
        )
    //   }
    // })
  }

  unClaim(abstractId) {
    // swal({
    //   title: 'Are you sure?',
    //   text: "Do you want to un save this abstract?",
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'I\'m Sure'
    // }).then((result) => {
    //   if (result.value) {
        this.userService.unClaimAbstract(this.user.member[0]._id, abstractId).subscribe(
          data => {
            if(data.success || data.success == false) this.sessionTimeOut()
            if(data._id) {
              localStorage.setItem('user', JSON.stringify(data))
              this.user = data
              // swal("Success!", "Abstract has been removed!", "success");
            }
            else swal("Error!", "Abstract has not been removed!", "error");
          }
        )
    //   }
    // })
  }

  applyFilters(filterType, keyword, index, title = null) {
    if(this.appliedFilters[filterType].indexOf(keyword) != -1)
      this.appliedFilters[filterType].splice(this.appliedFilters[filterType].indexOf(keyword), 1)
    else
      this.appliedFilters[filterType].push(keyword)

    if(filterType == 'targets') {
      this.showClearAll[title] = this.analytics[title].keywords.every(keyword => {
        return (this.appliedFilters[filterType].indexOf(keyword.keyword) != -1)
      })
    }
    
    this.selectedPage = 1
    this.loadIdeasOfPage()

  }

  updateIdeasList(abstracts, isExactMatch) {
    if(!isExactMatch) {
      this.abstracts = []
      this.ideaTypesKeys.forEach((key, i) => this.ideaTypes[key] = 0)
  
      if(abstracts.allIdeasCount) {
        this.allIdeasCount = abstracts.allIdeasCount
        if(this.totalRecordsInDb == 0) this.totalRecordsInDb = abstracts.allIdeasCount
        
        // this.allIdeasCount = (this.searchKeyword == '') ? abstracts.allIdeasCount : abstracts.recordsTotal
  
        this.targetedIdeasCount = abstracts.recordsTotal
        // this.targetedIdeasCount = (this.searchKeyword == '') ? abstracts.recordsTotal : abstracts.allIdeasCount
      }
      
      // if(this.searchKeyword != '' && this.category == 'targets') {
      //   this.filteredRecords = this.allIdeasCount
      // } else if(this.searchKeyword != '' && this.category == 'all') {
      //   this.filteredRecords = this.targetedIdeasCount
      // } else {
      //   this.filteredRecords = abstracts.recordsTotal
      // }
      this.showEmptyResultMsg = (abstracts.hits.hits.length == 0);
      this.filteredRecords = abstracts.recordsTotal
      this.totalPages = Math.ceil(this.filteredRecords / this.recordLength)
  
      if(this.totalPages > 1000) this.totalPages = 1000
  
      abstracts.hits.hits.forEach(abstract => {
        this.abstracts.push({
          _id: abstract._id,
          title: abstract._source.title,
          name: abstract._source.researcher.name.last+', '+abstract._source.researcher.name.first,
          organization: abstract._source.researcher.organization,
          country: abstract._source.researcher.address.country,
          type: abstract._source.researcher.organizationType,
          submittedOn: abstract._source.submittedOn,
          abstract: abstract._source.abstract,
          aims: abstract._source.aims,
          FYear: abstract._source.FYear,
          remarks: abstract._source.remarks,
          viewCount: (abstract._source.viewCount) ? abstract._source.viewCount : 0,
          score: (abstract._score) ? abstract._score.toFixed(2) : 0
        })
  
        this.ideaTypesKeys.forEach(key => {
          this.ideaTypes[key] += (abstract[this.ideaTypesColumnMapping[key]] == 'Y') ? 1 : 0
        });
      })
  
      // abstracts.data.forEach(abstract => {
      //   this.abstracts.push({
      //     _id: abstract._id,
      //     title: abstract.title,
      //     name: abstract.researcher.name.last+', '+abstract.researcher.name.first,
      //     organization: abstract.researcher.organization,
      //     country: abstract.researcher.address.country,
      //     type: abstract.researcher.organizationType,
      //     submittedOn: abstract.submittedOn,
      //     abstract: abstract.abstract,
      //     aims: abstract.aims,
      //     FYear: abstract.FYear,
      //     remarks: abstract.remarks,
      //     viewCount: abstract.viewCount,
      //     score: (abstract.score) ? abstract.score.toFixed(2) : 0
      //   })
      //   this.ideaTypesKeys.forEach(key => {
      //     this.ideaTypes[key] += (abstract[this.ideaTypesColumnMapping[key]] == 'Y') ? 1 : 0
      //   });
      // });
    } else {
      if(abstracts.hits && abstracts.hits.hits[0]) {
        let abstract = abstracts.hits.hits[0]
        this.exactMatch = {
          _id: abstract._id,
          title: abstract._source.title,
          name: abstract._source.researcher.name.last+', '+abstract._source.researcher.name.first,
          organization: abstract._source.researcher.organization,
          country: abstract._source.researcher.address.country,
          type: abstract._source.researcher.organizationType,
          submittedOn: abstract._source.submittedOn,
          abstract: abstract._source.abstract,
          aims: abstract._source.aims,
          FYear: abstract._source.FYear,
          remarks: abstract._source.remarks,
          viewCount: (abstract._source.viewCount) ? abstract._source.viewCount : 0,
          score: (abstract._score) ? abstract._score.toFixed(2) : 0
        }
        this.hasExactMatch = true
      } else {
        this.hasExactMatch = false
        this.exactMatch = {}
      }
      this.showEmptyMatchResult = true
    }

    if(abstracts.aggregations && abstracts.aggregations.distinct_Country && abstracts.aggregations.distinct_Country.buckets[0]) {
      this.countries = []
      abstracts.aggregations.distinct_Country.buckets.forEach(country => {
        this.countries.push(country.key)
        console.log(country)
      });
      this.countries.sort()
    }

    if(abstracts.aggregations && abstracts.aggregations.distinct_FYear && abstracts.aggregations.distinct_FYear.buckets[0]) {
      this.fYears = []
      abstracts.aggregations.distinct_FYear.buckets.forEach(fyear => {
        if(fyear.key != '' && fyear.key.length == 4) {
          this.fYears.push(fyear.key)
        }
      });
      this.fYears.sort()
      this.fYears.reverse()
    }

    if(abstracts.aggregations && abstracts.aggregations.distinct_authors && abstracts.aggregations.distinct_authors.buckets[0]) {
      this.authorsList = []
      abstracts.aggregations.distinct_authors.buckets.forEach(author => {
        this.authorsList.push(author.key + "(" + author.doc_count + ")")
      });
      // this.authorsList.sort()
    }
    
    if(document.querySelector('.popover')) {
      document.querySelector('.popover').remove()
    }

    this.userService.getCountryList().subscribe(
      countries => {
        this.countriesList = countries
        this.countriesList.unshift({name:'Choose...', code: ''})
        console.log(this.countriesList)
      }
    )
  }

  loadPagination() {

    this.pagination = []
    
    if(this.totalPages <= 6) {
      console.log(this.totalPages)
      for(let pageNo = 1; pageNo <= this.totalPages; pageNo++) {
        console.log(pageNo)
        console.log(pageNo <= this.totalPages)
        this.pagination.push({
          pageNo: pageNo,
          active: (pageNo == this.selectedPage)
        })
      }
    } else {
      if(this.selectedPage > 3)
      this.pagination.push({
        pageNo: 1,
        active: false
      })
      if(this.selectedPage > 4)
        this.pagination.push({
          pageNo: '...',
          active: false
        })

      let renderPageNo = (this.selectedPage - 2)
      for(let renderedCount = 0; renderedCount < 5; renderedCount++) {
        if(renderPageNo >= 1 && renderPageNo <= this.totalPages) {
          this.pagination.push({
            pageNo: renderPageNo,
            active: (renderPageNo == this.selectedPage)
          })
        }
        renderPageNo++
      }
      
      if(this.selectedPage <= (this.totalPages - 4))
        this.pagination.push({
          pageNo: '...',
          active: false
        })
      if(this.selectedPage <= (this.totalPages - 3))
        this.pagination.push({
          pageNo: this.totalPages,
          active: false
        })
    }
    console.log(this.pagination)
  }

  loadIdeasOfPage(getRefinedByCount = false) {
    let filters = Object.assign({}, this.appliedFilters)
    
    if(this.category == 'all') {
      delete filters['targets']
    }

    if(this.searchBy == 'abstract') {
      filters['start'] = 0
    } else {
      filters['start'] = ((this.selectedPage - 1) * this.recordLength)
    }
    filters['length'] = this.recordLength

    if(getRefinedByCount) filters['getRefinedByCount'] = true

    filters['userType'] = this.user.member[0].type
    filters['organization'] = this.user.organizationName

    filters['filterType'] = this.filterType

    if(this.searchKeyword != '') {
      filters['search'] = this.searchKeyword
      if(this.searchBy == 'abstract') {
        filters['search'] = '"' + filters['search'] + '"'
      }
      // filters['targets'] = []
      // filters['ideaTypes'] = []
      // filters['organizationTypes'] = []
      // this.appliedFilters['targets'] = []
      // this.appliedFilters['ideaTypes'] = []
      // this.appliedFilters['organizationTypes'] = []
    }

    filters['searchBy'] = this.searchBy

    let queryStartsAt = new Date().getTime()

    this.userService.getIdeas(this.user._id, this.category, filters).subscribe(
      abstracts => {
            
        if(abstracts.success || abstracts.success == false) this.sessionTimeOut()

        if(this.searchBy == 'abstract') {
          this.loadingExactMatch = false
        } else {
          this.loadingSearchResult = false
        }
        
        this.updateIdeasList(abstracts, (this.searchBy == 'abstract' && this.category != 'all'))

        // if(getRefinedByCount) {
        //   this.selectAllIdeaType(false)
        //   this.selectAllOrgType(false)
        // }
        this.loadPagination()

        this.timeTakenByQuery = new Date().getTime() - queryStartsAt
      }
    )

    if(this.searchBy == 'abstract' && this.category != 'all') {
      this.userService.getCommonWords().subscribe(
        commonWords => {
          let keywordList = []
  
          let wordCount = {}
  
          let abstractContent = ''
          abstractContent = this.searchKeyword
  
          abstractContent.split(' ').forEach(word => {
            word = word.replace(/,/g, '').replace(/!/g, '').replace('.', '').toLowerCase()
            if(wordCount[word]) wordCount[word] += 1
            else wordCount[word] = 1
          });
  
          let sortable = []
          for (var word in wordCount) {
            sortable.push([word, wordCount[word]]);
          }
          
          sortable.sort(function(a, b) {
            return b[1] - a[1];
          });
  
          let keywordCount = 0;
          sortable.forEach(word => {
            let keyword = {}
            if(keywordCount < 10) {
              if(commonWords.indexOf(word[0].toLowerCase()) == -1) {
                // if(keyword != '') keyword += '+OR+'
                keyword['_id'] = word[0]
                keyword['value'] = word[1]
  
                if(keywordCount < 3) {
                  keyword['checked'] = true
                } else {
                  keyword['checked'] = false
                }
  
                keywordList.push(keyword)
                keywordCount++
              }
            }
          });
  
          // console.log(keywordList)
  
          // this.topKeywords = keyword.split('+OR+').join(',')
  
          let pubmedSearchKeyword = keywordList.slice(0,3).map(function(keyword, index) { return keyword._id}).join('+AND+')
          // let pubmedSearchKeyword = keyword.split('+OR+').join('+OR+')
  
          let abstractSearchFilter = filters
          abstractSearchFilter['search'] = pubmedSearchKeyword

          this.relevantResultKeywords = pubmedSearchKeyword.replace(/\+/g, " "); 
  
          this.userService.getIdeas(this.user._id, this.category, abstractSearchFilter).subscribe(
            abstracts => {
              if(abstracts.success || abstracts.success == false) this.sessionTimeOut()
              if(this.searchBy == 'abstract') {
                this.loadingSearchResult = false
              }

              this.updateIdeasList(abstracts, false)
            }
          )
        }
      )
    } else {
      this.relevantResultKeywords = ''
    }
  }

  navigateToPage(selectedPage) {
    if(selectedPage == '...') return
    this.selectedPage = selectedPage

    if(this.browseBy == 'saved') {
      this.loadSavedIdeas()
    } else if(this.browseBy == 'claimed') {
      this.loadClaimedIdeas()
    } else {
      this.loadIdeasOfPage()
    }
  }

  navigateToNexPage() {
    this.selectedPage = (this.selectedPage != this.totalPages) ? this.selectedPage + 1 : this.selectedPage
    if(this.browseBy == 'saved') {
      this.loadSavedIdeas()
    } else if(this.browseBy == 'claimed') {
      this.loadClaimedIdeas()
    } else {
      this.loadIdeasOfPage()
    }
  }

  navigateToPreviousPage() {
    this.selectedPage = (this.selectedPage != 1) ? this.selectedPage - 1 : this.selectedPage
    if(this.browseBy == 'saved') {
      this.loadSavedIdeas()
    } else if(this.browseBy == 'claimed') {
      this.loadClaimedIdeas()
    } else {
      this.loadIdeasOfPage()
    }
  }

  customSearch() {
    this.hasExactMatch = false
    this.exactMatch = {}
    this.abstracts = []

    if(this.searchKeyword != '') {
      localStorage.setItem('searchKeyword', this.searchKeyword)
      localStorage.setItem('searchBy', this.searchBy)
      
      let searchData = {
        organization: this.user._id,
        id: this.user.member[0]._id,
        email: this.user.member[0].email,
        search: this.searchKeyword,
        tab: "Ideas"
      }
      
      this.userService.logSearch(searchData).subscribe(response => {
        console.log(response)
      })
      
      this.appliedFilters['targets'] = []
      this.appliedFilters['ideaTypes'] = []
      this.appliedFilters['organizationTypes'] = []

      Object.keys(this.analytics).forEach(targetTitle => {
        this.analytics[targetTitle].keywords.forEach(keyword => {
          this.appliedFilters[targetTitle] = []
          this.showClearAll[targetTitle] = false
        })
      });

      this.memberTargets.forEach((member, memberIndex) => {
        console.log(member, memberIndex)
        console.log(this.showClearAll)
        if(member.target) {
          member.target.forEach(target => {
            this.showClearAll['MT_'+memberIndex+'_'+target.title] = false
          });
        }
        this.memberTargetSelecteAll[memberIndex] = false
      });


      this.targetSelecteAll = false
      
      this.selectedPage = 1
      this.loadIdeasOfPage(true)
      this.showAdvancedSearch(false)

      if(this.searchBy == 'keyword') {
        this.hasExactMatch = false
        this.exactMatch = {}
        this.relevantResultKeywords = ''
      }

      this.loadingExactMatch = true
      this.loadingSearchResult = true

    } else {
      this.clearSearch()
    }
  }

  formateDate(date) {
    let dateObj = new Date(date)
    return dateObj.getFullYear()+'-'+("00"+(dateObj.getMonth()+1)).substr(-2)+"-"+("00"+(dateObj.getDate())).substr(-2)
  }

  addNewTarget() {
    this.user.target.push({})
    this.organizationData.target.push({})
  }

  // addNewMemberTarget() {
  //   this.memberTargets[0].target.push({})
  // }

  addNewMemberTarget(memberIndex) {
    console.log(memberIndex)
    console.log(this.organizationData.member[memberIndex])
    console.log(this.memberTargets)

    let fromManageModal = (memberIndex != null)
    if(memberIndex == null) memberIndex = this.selectedMember
    
    console.log(memberIndex)
    
    if(!this.organizationData.member[memberIndex]['target']) {
      this.organizationData.member[memberIndex]['target'] = []
      this.memberTargets[(fromManageModal) ? (memberIndex - 1) : memberIndex] = {
        'target': []
      }
    }
    this.organizationData.member[memberIndex]['target'].push({})


    this.memberTargets[(fromManageModal) ? (memberIndex - 1) : memberIndex].target.push({})
  }

  deleteTarget(targetIndex) {
    this.user.target.splice(targetIndex, 1)
    this.organizationData.target.splice(targetIndex, 1)
  }

  deleteMemberTarget(memberIndex, targetIndex) {
    let fromManageModal = (memberIndex != null)
    if(memberIndex == null) memberIndex = this.selectedMember
    if(this.organizationData.member[memberIndex]['target']) this.organizationData.member[memberIndex]['target'].splice(targetIndex, 1)

    if(fromManageModal) memberIndex = memberIndex - 1
    this.memberTargets[memberIndex].target.splice(targetIndex, 1)
    console.log(memberIndex, targetIndex, this.organizationData.member)
  }

  // deleteMemberTarget(targetIndex) {
  //   this.memberTargets[0].target.splice(targetIndex, 1)
  // }

  saveTargets() {
    this.userService.saveTargets(this.user).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        
        if(response.status) localStorage.setItem('user', JSON.stringify(this.user))

        this.targetTitle = []
        this.analytics = []

        this.selectedPage = 1
        this.loadIdeasOfPage()

        this.userService.getTargetCount(this.user._id, this.category).subscribe(
          analytics => {
            analytics.forEach(keyword => {
              if(!this.analytics[keyword.title]) this.analytics[keyword.title] = {
                count: 0,
                keywords: []
              }
              this.analytics[keyword.title]['count'] += keyword.count
              this.analytics[keyword.title]['keywords'].push(keyword)
            });
            this.targetTitle = Object.keys(this.analytics)
            this.editTargets.nativeElement.click();
            this.editTargetsClose.nativeElement.click()
          }
        )
      }
    )
  }

  saveMemberTargets() {
    this.userService.saveMemberTargets(this.memberTargets[this.selectedMember]._id, this.memberTargets[this.selectedMember].target).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        
        if(response.status) localStorage.setItem('user', JSON.stringify(this.user))

        this.targetTitle = []
        this.analytics = []

        this.selectedPage = 1
        this.loadIdeasOfPage()


        this.userService.getMemberById(this.user._id).subscribe(
          (organization) => {
            this.activeTabs = []
            console.log(organization)
            this.memberDetailsModal = 'Update member details'
            this.organizationData = organization
            this.organizationData.member[0].confirmEmail = this.organizationData.member[0].email
            this.selectTab('organization')
          }
        )

        this.userService.getTargetCount(this.user._id, this.category).subscribe(
          analytics => {
            analytics.forEach(keyword => {
              if(!this.analytics[keyword.title]) this.analytics[keyword.title] = {
                count: 0,
                keywords: []
              }
              this.analytics[keyword.title]['count'] += keyword.count
              this.analytics[keyword.title]['keywords'].push(keyword)
            });
            this.targetTitle = Object.keys(this.analytics)
            this.editTargets.nativeElement.click();
            this.editMemberTargetsClose.nativeElement.click()
          }
        )
      }
    )
  }

  updateSortOrder(sortBy) {
    this.appliedFilters['sortBy'] = this.columnSortOrder[sortBy].sortBy || false
    this.appliedFilters['sortOrder'] = this.columnSortOrder[sortBy].sortOrder || false

    this.selectedPage = 1
    this.loadIdeasOfPage()
  }

  updateCategory(category) {
    if(category != 'targets') {
      this.searchKeyword = ''
      localStorage.removeItem('searchKeyword')
    }
    this.category = category
    this.browseBy = category
    if(category == 'all') {
      this.searchBy = 'keyword'
    }
    this.loadIdeasOfPage((category != 'all'))
  }

  loadSavedIdeas() {
    this.searchKeyword = ''
    let queryStartsAt = new Date().getTime(),
    start = ((this.selectedPage - 1) * this.recordLength),
    limit = this.recordLength

    this.browseBy = 'saved'

    this.userService.getSavedAbstracts(this.user._id, start, limit).subscribe(
      abstracts => {
            
        if(abstracts.success || abstracts.success == false) this.sessionTimeOut()

        this.updateIdeasList(abstracts, false)
        this.totalPages = Math.ceil(this.user.savedAbstracts.length / this.recordLength)
        this.loadPagination()

        this.timeTakenByQuery = new Date().getTime() - queryStartsAt
      }
    )
  }

  loadClaimedIdeas() {
    this.searchKeyword = ''
    let queryStartsAt = new Date().getTime(),
    start = ((this.selectedPage - 1) * this.recordLength),
    limit = this.recordLength

    this.browseBy = 'claimed'

    this.userService.getClaimedAbstracts(this.user._id, start, limit).subscribe(
      abstracts => {
            
        if(abstracts.success || abstracts.success == false) this.sessionTimeOut()

        this.updateIdeasList(abstracts, false)
        this.totalPages = Math.ceil(this.user.claimedAbstracts.length / this.recordLength)
        this.loadPagination()

        this.timeTakenByQuery = new Date().getTime() - queryStartsAt
      }
    )
  }

  restrictByTargets(filterType, filterText) {
    this.filterType = filterType
    this.selectedFilterText = filterText
    
    this.selectedPage = 1
    this.loadIdeasOfPage(true)
  }

  showAdvancedSearch(show) {
    this.advancedSearch = show
  }

  updateSearchBy(searchBy) {
    this.searchBy = searchBy
    this.searchKeyword = ''
    localStorage.removeItem('searchKeyword')
  }

  clearSearch() {
    this.searchKeyword = ''
    this.searchBy = 'keyword'
    localStorage.removeItem('searchKeyword')
    this.hasExactMatch = false
    this.exactMatch = {}
    this.abstracts = []
    this.showEmptyMatchResult = false
    this.loadingExactMatch = true
    this.loadingSearchResult = true

    this.selectAllTargetTitles(null);
    this.memberTargets.forEach((memberTarget, targetIndex) => {
      this.selectAllMemberTargets(targetIndex, null);
    });

    this.selectedPage = 1
    this.loadIdeasOfPage(true)
    this.showAdvancedSearch(false)

  }

  // pasteFromClipboard() {
  //   navigator.permissions.query({
  //     name:'clipboard-read'
  //   }).then(function(result) {
  //       console.log(result.state);
  //       if(result.state === 'granted'){
  //           console.log(navigator.clipboard.readText());
  //       }
  //   });
  //   // console.log(navigator.clipboard.readText())
  //   // this.searchKeyword = navigator.clipboard.readText();
  // }
  
  pasteFromClipboard(){
    this.searchField.nativeElement.focus()
    // element.select()
    document.execCommand("paste")
      // this.searchKeyword = window.getSelection().toString();
      // console.log(this.searchKeyword)
  }

  selectMember(memberId) {
    this.selectedMember = memberId
  }

  // loadContent(organization) {

  //   console.log(organization)
  //   this.activeTabs = []
  //   console.log(organization)
	// 	this.memberDetailsModal = 'Update member details'
  //   this.organizationData = organization
  //   this.organizationData.member[0].confirmEmail = this.organizationData.email
  //   this.selectTab('organization')
  //   // this.submitSetupOrganizationModel.nativeElement.style.display = 'none'

  //   // setTimeout(() => {
  //   //   var toggler = document.getElementsByClassName("caret");

  //   //   for (var i = this.caretEventHandled; i < toggler.length; i++) {
  //   //     toggler[i].addEventListener("click", function() {
  //   //       this.parentElement.querySelector(".nested").classList.toggle("active");
  //   //       this.classList.toggle("caret-down");
  //   //     });
  //   //     this.caretEventHandled = (toggler.length - 1)
  //   //   }
  //   // }, 500)
  // }

  saveMember() {
    console.log(this.organizationData)
    this.userService.saveMember(this.organizationData).subscribe(
      data => {
        if(data.status) {
          let alertMessage = 'Member Details has been updated successfully!'
          if(!this.organizationData._id) alertMessage = 'New Member is added successfully!'
          swal({
            title: alertMessage,
            type: 'success',
            confirmButtonText: 'Okay'
          })

          this.closeSetupOrganizationModel.nativeElement.click();

          this.userService.getMemberTargets(this.user._id).subscribe(
            (memberTargets) => {
              this.memberTargets = memberTargets[0].member.slice(1)
              // this.memberTargets.forEach((member, memberIndex) => {
              //   this.memberTargetSelecteAll[memberIndex] = (localStorage.getItem('searchKeyword') == null)
              //   if(member.target) {
              //     member.target.forEach(target => {
              //       this.appliedFilters['targets'].push(target.booleanSearch)
              //       this.showClearAll['MT_'+memberIndex+'_'+target.title] = (localStorage.getItem('searchKeyword') == null)
              //     });
              //   }
              // });
              this.loadIdeasOfPage(true)
            }
          )

        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

  addActiveTab(tabId) {
    if(this.activeTabs.indexOf(tabId) == -1) {
      this.activeTabs.push(tabId)
    }
    this.selectedTab = tabId
  }

  closeActiveTab(tabId) {
    this.activeTabs.splice(this.activeTabs.indexOf(tabId), 1)
    this.selectTab('organization')
  }

  selectTab(tabName) {
    this.selectedTab = tabName;
  }

  addNewMember() {
    this.organizationData.member.push({
      type: 'Subordinate'
    })
    console.log(this.organizationData.member)
    this.addActiveTab((this.organizationData.member.length - 1))

    // setTimeout(() => {
    //   var toggler = document.getElementsByClassName("caret");

    //   toggler[toggler.length - 1].addEventListener("click", function() {
    //     this.parentElement.querySelector(".nested").classList.toggle("active");
    //     this.classList.toggle("caret-down");
    //   });
    // }, 500)
  }

  deleteMember(memberIndex) {
    this.organizationData.member.splice(memberIndex, 1)
    let activeTabIndex = this.activeTabs.indexOf((memberIndex - 1))
    this.activeTabs.splice(activeTabIndex, 1)
    this.selectTab('organization')
  }
}

import { Component, OnInit, Renderer2, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../shared/user.service';

declare var pleaseWait :any;

import { NgxSpinnerService } from 'ngx-spinner';

import { Router, ActivatedRoute, Params, NavigationExtras } from "@angular/router";

import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationsComponent implements OnInit {
  
  public targetTitle: any = []
  public abstractData: any = {
    "ideaSubmitted": {},
    "ideaFunded": {}
  }
  public types: any = []
  public publications: any = []
  public totalRecordsInDb: number = 0
  public allIdeasCount: number = 0
  public targetedIdeasCount: number = 0
  public filteredRecords: number = 0
  public recordLength: number = 10
  public pagination: any = []
  public selectedPage: number = 1
  public totalPages: number = 1
  public columnSortOrder: any = {
    'relevance' : {
      'sortBy': 'score'
    }, 'pIName' : {
      'sortBy': 'researcher.name.last,researcher.name.first',
      'sortOrder': '1,1'
    }, 'organization' : {
      'sortBy': 'researcher.organization',
      'sortOrder': '1'
    }, 'FYearDesc' : {
      'sortBy': 'FYear',
      'sortOrder': '-1'
    }, 'FYearAsc' : {
      'sortBy': 'FYear',
      'sortOrder': '1'
    }, 'submittedOnDesc' : {
      'sortBy': 'submittedOn',
      'sortOrder': '-1'
    }, 'submittedOnAsc' : {
      'sortBy': 'submittedOn',
      'sortOrder': '1'
    }
  }

  public sortBy: string = 'pub+date'

  public browseBy: string = 'targets';

  public analytics: any = []
  public timeTakenByQuery: number = 0

  public selectedTargetIndex: number = 0;
  public showClearAll: any = {}
  
  public organizationTypes: any = []
  
  public ideaTypes: any = {}
  public ideaTypesKeys: any = []
  public journals: any = []
  public publishYears: any = []
  public countries: any = []
  public publishYearFilter: number = 0;

  public searchKeyword: any = ''
  public filterType: any = 'any'
  public selectedFilterText: string = 'Displaying publications containing ANY of the search terms'

  public targetSelecteAll: boolean = false
  public memberTargetSelecteAll: any = {}

  public appliedFilters: any = {
    'targets': [],
    'journals': [],
    'publishYears': [],
    'countries': []
  }

  public preferredJournals: any = []

  public category = 'targets'

  public esearchTerm = ''

  public user: any = {}
  public userType: string = ''
  public memberTargets: any = []
  public countriesList: any = []

  public memberTypeList = [
    "Subordinate",
    "Restricted Member"
  ]

  public showEmptyResultMsg: boolean = false;
  
  public searchBy: string = 'keyword'
  public advancedSearch: boolean = false

  public showEmptyMatchResult: boolean = false
  public hasExactMatch: boolean = false
  public exactMatch: any = {}
  
  public loadingExactMatch: boolean = true
  public loadingSearchResult: boolean = true

  public similarArticilesHeading: string = 'Displaying most relevant results'
  public selectedMember: number = 0;

  public relevantResultKeywords: string = ''

  public activeTabs: any = []
  public memberDetailsModal: string = ''
  public organizationData: any = {}
  public selectedTab: string = 'organization'

  @ViewChild('submitAbstractModel') submitAbstractModel:ElementRef;
  @ViewChild('closeAbstractModel') closeAbstractModel:ElementRef;
  @ViewChild('abstractFormElement') abstractForm:ElementRef;
  @ViewChild('editTargets') editTargets:ElementRef;
  @ViewChild('search') searchField: ElementRef;
  @ViewChild('editTargetsClose') editTargetsClose: ElementRef;
  @ViewChild('editMemberTargetsClose') editMemberTargetsClose: ElementRef
  @ViewChild('closeSetupOrganizationModel') closeSetupOrganizationModel:ElementRef;

  constructor(private userService: UserService, private renderer: Renderer2, private router: Router, private activatedRouter: ActivatedRoute, private chRef: ChangeDetectorRef, private spinner: NgxSpinnerService) {
    this.renderer.addClass(document.body, 'bg-light');
    this.renderer.removeClass(document.body, 'bg-blue');
  }

  ngOnInit() {
    if (localStorage.getItem("user") === null) {
      localStorage.setItem('redirectUrl', window.location.href)
      // window.location.href = 'http://login.onixhub.com'
      window.location.href = 'http://login.onixhub.com'
    }

    if(localStorage.getItem('searchKeyword') != null) {
      this.searchKeyword = localStorage.getItem('searchKeyword')
      this.searchBy = localStorage.getItem('searchBy')
      this.targetSelecteAll = false
    }

    this.user = JSON.parse(localStorage.getItem('user'))

    this.preferredJournals = this.user.member[0].prefJournal;

    this.userType = this.user.member[0].type
    // this.searchKeyword = localStorage.getItem('searchKeyword')

    this.ideaTypesKeys.forEach((key, i) => this.ideaTypes[key] = 0)

    this.activatedRouter.queryParams.subscribe((params: Params) => {

      if(params['loadWithFilters'] == 'true') {
        this.appliedFilters = JSON.parse(params.appliedFilters)
        this.searchKeyword = params.searchKeyword
        this.totalRecordsInDb = params.totalRecordsInDb
        this.browseBy = params.browseBy
        this.selectedPage = params.pageNo
      }

      let category = 'targets'

      this.userService.getMemberById(this.user._id).subscribe(
        (organization) => {
          this.activeTabs = []
          console.log(organization)
          this.memberDetailsModal = 'Update member details'
          this.organizationData = organization
          this.organizationData.member[0].confirmEmail = this.organizationData.member[0].email
          this.selectTab('organization')


        }
      )
      
      $(document).on('click', '.caret', function() {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      })

      var popOverSettings = {
        placement: 'right',
        trigger: 'hover',
        html: true,
        selector: '[data-toggle="popover"]'
      }
      
      $('body').popover(popOverSettings);

      this.userService.getOrganizationTypes().subscribe(
        organizationTypes => {
          if(organizationTypes.success || organizationTypes.success == false) this.sessionTimeOut()
          this.organizationTypes = organizationTypes

          // this.loadIdeasOfPage(true)
          
          this.userService.getTargetCount(this.user._id, category).subscribe(
            analytics => {
              analytics.forEach(keyword => {
                if(!this.analytics[keyword.title]) this.analytics[keyword.title] = {
                  count: 0,
                  keywords: []
                }
                this.analytics[keyword.title]['count'] += keyword.count
                this.analytics[keyword.title]['keywords'].push(keyword)
              });
              this.targetTitle = Object.keys(this.analytics)

              if(localStorage.getItem('searchKeyword') == null) {
                Object.keys(this.analytics).forEach((targetTitle, targetIndex) => {
                  if(params['loadWithFilters'] == 'true') {
                    if(this.appliedFilters['targets'].indexOf(this.analytics[targetTitle]['keywords'][0]['keyword']) != -1) {
                      this.showClearAll[targetTitle] = true

                      var isAllSelected = this.user.target.every((target) => {
                        return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
                      })

                      if(isAllSelected) {
                        this.targetSelecteAll = true
                      }
                    }
                  } else {
                    if(targetIndex == 0) this.selectAllTarget(targetTitle, false)
                  }
                })

                if(Object.keys(this.analytics).length == 1) {
                  this.targetSelecteAll = true;
                }
              }
              
              if(this.user.member[0].type == 'Manager') {
                this.userService.getMemberTargets(this.user._id).subscribe(
                  (memberTargets) => {
                    this.memberTargets = memberTargets[0].member.slice(1)
                    this.memberTargets.forEach((member, memberIndex) => {
                      if(params['loadWithFilters'] == 'true') {
                        this.memberTargetSelecteAll[memberIndex] = member.target.every((target) => {
                          return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
                        })
                      }
                      // this.memberTargetSelecteAll[memberIndex] = (localStorage.getItem('searchKeyword') == null)
                      if(member.target) {
                        member.target.forEach(target => {
                          if(params['loadWithFilters'] == 'true') {
                            if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
                              // this.appliedFilters['targets'].push(target.booleanSearch)
                              this.showClearAll['MT_'+memberIndex+'_'+target.title] = (localStorage.getItem('searchKeyword') == null)
                            }
                          }
                        });
                      }
                    });

                    if(localStorage.getItem('searchKeyword') != null) {
                      this.customSearch()
                    } else {
                      this.loadIdeasOfPage(true)
                    }
                  }
                )
              } else {
                this.memberTargets = this.user.member
                // this.memberTargetSelecteAll[0] = true
                if(params['loadWithFilters'] == 'true') {
                  this.memberTargetSelecteAll[0] = this.user.member[0].every((target) => {
                    return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
                  })
                }
                this.user.member[0].target.forEach(target => {
                  if(params['loadWithFilters'] == 'true') {
                    if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
                      // this.appliedFilters['targets'].push(target.booleanSearch)
                      this.showClearAll['MT_0_'+target.title] = (localStorage.getItem('searchKeyword') == null)
                    }
                  }
                  // this.appliedFilters['targets'].push(target.booleanSearch)
                  // this.showClearAll['MT_0_'+target.title] = true
                });
                if(localStorage.getItem('searchKeyword') != null) {
                  this.customSearch()
                } else {
                  this.loadIdeasOfPage(true)
                }
              }


              // Object.values(this.analytics).forEach(target => {
              //   console.log(target)
              //   target['keywords'].forEach(keyword => {
              //     this.appliedFilters['targets'].push(keyword.keyword)
              //   });
              // });
              
              // this.loadIdeasOfPage(true)
            }
          )
        }
      )

      this.category = category
    });

  }

  showKeywordOfTarget(targetIndex) {
    this.selectedTargetIndex = targetIndex
  }

  selectAllTargetTitles(event) {
    if(!this.targetSelecteAll) {
      Object.keys(this.analytics).forEach(target => {
        this.selectAllTarget(target, false)
      });
      this.targetSelecteAll = true
      this.selectAllTarget(null, true)
    } else {
      Object.keys(this.analytics).forEach(target => {
        this.clearAllTarget(target, false)
      });
      this.targetSelecteAll = false
      this.clearAllTarget(null, true)
    }
  }

  selectAllMemberTargets(memberIndex, event) {
    if(!this.memberTargetSelecteAll[memberIndex]) {
      this.memberTargets[memberIndex].target.forEach(target => {
        this.selectMemberTarget(memberIndex, target, false)
      });
      this.memberTargetSelecteAll[memberIndex] = true
      this.selectAllTarget(null, true)
    } else {
      this.memberTargets[memberIndex].target.forEach(target => {
        this.clearAllMemberTarget(memberIndex, target, false)
      });
      this.memberTargetSelecteAll[memberIndex] = false
      this.clearAllMemberTarget(memberIndex, null, true)
    }
  }

  selectMemberTarget(memberIndex, target, refreshPage) {
    if(target) {
      this.appliedFilters['targets'].push(target.booleanSearch)
      this.showClearAll['MT_'+memberIndex+'_'+target.title] = true

      var isAllSelected = this.memberTargets[memberIndex].target.every((target) => {
        return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
      })

      if(isAllSelected) {
        this.memberTargetSelecteAll[memberIndex] = true
      }
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  selectAllTarget(targetTitle, refreshPage) {
    if(targetTitle) {
      this.analytics[targetTitle].keywords.forEach(keyword => {
        this.appliedFilters['targets'].push(keyword.keyword)
      })
      this.showClearAll[targetTitle] = true

      // if(Object.keys(this.analytics).length == this.appliedFilters['targets'].length) {
      //   this.targetSelecteAll = true
      // }

      var isAllSelected = this.user.target.every((target) => {
        return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
      })

      if(isAllSelected) {
        this.targetSelecteAll = true
      }
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  selectAllJournals(refreshPage) {
    this.journals.forEach(journal => {
      this.appliedFilters['journals'].push(journal)
    });
    this.showClearAll['journals'] = true

    this.selectedPage = 1
    if(refreshPage) this.loadIdeasOfPage()
  }

  selectAllMyPrefJournals(refreshPage) {
    this.preferredJournals.forEach(journal => {
      if(this.appliedFilters['journals'].indexOf(journal) == -1) this.appliedFilters['journals'].push(journal)
    });
    this.showClearAll['prefJournals'] = true

    this.showClearAll['journals'] = this.journals.every(keyword => {
      return (this.appliedFilters['journals'].indexOf(keyword) != -1)
    })
    this.showClearAll['prefJournals'] = this.preferredJournals.every(keyword => {
      return (this.appliedFilters['journals'].indexOf(keyword) != -1)
    })
    this.memberTargets.forEach((member, memberIndex) => {
      if(member.prefJournal) {
        this.showClearAll['MPJ_'+memberIndex] = member.prefJournal.every(keyword => {
          return (this.appliedFilters['journals'].indexOf(keyword) != -1)
        })
      }
    });

    this.selectedPage = 1
    if(refreshPage) this.loadIdeasOfPage()
  }

  selectAllPrefJournals(memberIndex, refreshPage) {
    this.memberTargets[memberIndex].prefJournal.forEach(journal => {
      if(this.appliedFilters['journals'].indexOf(journal) == -1) this.appliedFilters['journals'].push(journal)
    });
    this.showClearAll['MPJ_'+memberIndex] = true
    this.showClearAll['journals'] = this.journals.every(keyword => {
      return (this.appliedFilters['journals'].indexOf(keyword) != -1)
    })
    this.showClearAll['prefJournals'] = this.preferredJournals.every(keyword => {
      return (this.appliedFilters['journals'].indexOf(keyword) != -1)
    })
    this.memberTargets.forEach((member, memberIndex) => {
      if(member.prefJournal) {
        this.showClearAll['MPJ_'+memberIndex] = member.prefJournal.every(keyword => {
          return (this.appliedFilters['journals'].indexOf(keyword) != -1)
        })
      }
    });
    this.selectedPage = 1
    if(refreshPage) this.loadIdeasOfPage()
  }

  selectAllCountries(refreshPage) {
    this.countries.forEach(journal => {
      this.appliedFilters['countries'].push(journal)
    });
    this.showClearAll['countries'] = true

    this.selectedPage = 1
    if(refreshPage) this.loadIdeasOfPage()
  }

  selectAllPublishedYear(refreshPage) {
    this.publishYears.forEach(journal => {
      this.appliedFilters['publishYears'].push(journal)
    });
    this.showClearAll['publishYears'] = true

    this.selectedPage = 1
    if(refreshPage) this.loadIdeasOfPage()
  }

  clearAllMemberJournal(memberIndex, refreshPage) {
    this.memberTargets[memberIndex].pre
    // this.showClearAll['MPJ_'+memberIndex] = false
    this.memberTargets[memberIndex].prefJournal.forEach(prefJournal => {
      let journalIndex = this.appliedFilters['journals'].indexOf(prefJournal)
      if(journalIndex != -1) {
        this.appliedFilters['journals'].splice(journalIndex, 1)
      }
    });

    this.showClearAll['journals'] = this.journals.every(keyword => {
      return (this.appliedFilters['journals'].indexOf(keyword) != -1)
    })
    this.showClearAll['prefJournals'] = this.preferredJournals.every(keyword => {
      return (this.appliedFilters['journals'].indexOf(keyword) != -1)
    })
    this.memberTargets.forEach((member, memberIndex) => {
      if(member.prefJournal) {
        this.showClearAll['MPJ_'+memberIndex] = member.prefJournal.every(keyword => {
          return (this.appliedFilters['journals'].indexOf(keyword) != -1)
        })
      }
    });

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  clearAllTarget(targetTitle, refreshPage) {
    if(targetTitle) {
      if(targetTitle == 'publishYears') {
        this.appliedFilters['publishYears'] = []
      } else if(targetTitle == 'journals') {
        this.appliedFilters['journals'] = []
      } else if(targetTitle == 'myPrefJournals') {
        this.showClearAll['prefJournals'] = false
        this.user.member[0].prefJournal.forEach(prefJournal => {
          let journalIndex = this.appliedFilters['journals'].indexOf(prefJournal)
          if(journalIndex != -1) {
            this.appliedFilters['journals'].splice(journalIndex, 1)
          }
        });
      } else if(targetTitle == 'countries') {
        this.appliedFilters['countries'] = []
      } else if(this.analytics[targetTitle]) {
        this.analytics[targetTitle].keywords.forEach(keyword => {
          let targetIndex = this.appliedFilters['targets'].indexOf(keyword.keyword)
          if(targetIndex != -1) {
            this.appliedFilters['targets'].splice(targetIndex, 1)
          }
        })
        this.targetSelecteAll = false
      }

      if(targetTitle == 'journals' || targetTitle == 'prefJournals') {
        this.showClearAll['journals'] = this.journals.every(keyword => {
          return (this.appliedFilters['journals'].indexOf(keyword) != -1)
        })
        this.showClearAll['prefJournals'] = this.preferredJournals.every(keyword => {
          return (this.appliedFilters['journals'].indexOf(keyword) != -1)
        })
        this.memberTargets.forEach((member, memberIndex) => {
          if(member.prefJournal) {
            this.showClearAll['MPJ_'+memberIndex] = member.prefJournal.every(keyword => {
              return (this.appliedFilters['journals'].indexOf(keyword) != -1)
            })
          }
        });
      } else {
        this.showClearAll[targetTitle] = false
      }
    }
    
    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  clearAllMemberTarget(memberIndex, target, refreshPage) {
    if(target) {
      let targetIndex = this.appliedFilters['targets'].indexOf(target.booleanSearch)
      if(targetIndex != -1) {
        this.appliedFilters['targets'].splice(targetIndex, 1)
      }
      this.memberTargetSelecteAll[memberIndex] = false
      targetIndex = this.appliedFilters['targets'].indexOf(target.booleanSearch)
      
      this.showClearAll['MT_'+memberIndex+'_'+target.title] = false
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }


  loadContent(content) {
    this.abstractData = content
  }

  viewAbstract(content) {
    let contentParam = content
    console.log(content)
    contentParam['appliedFilters'] = JSON.stringify(this.appliedFilters)
    contentParam['searchKeyword'] = this.searchKeyword
    contentParam['totalRecordsInDb'] = this.totalRecordsInDb
    contentParam['browseBy'] = this.browseBy
    contentParam['pageNo'] = this.selectedPage
    contentParam['fromPublication'] = true

    let navigationExtras: NavigationExtras = {
        queryParams: contentParam, skipLocationChange: true
    };
    this.router.navigate(['/view-abstract'], navigationExtras)
  }

  logout() {
    swal({
      title: 'Are you sure?',
      text: "Do you want to logout?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I\'m Sure'
    }).then((result) => {
      if (result.value) {
        localStorage.clear()
        // this.router.navigate(['']);
        window.location.href = 'https://onixhub.com'
      }
    })
  }

  sessionTimeOut() {
    localStorage.clear()
    // window.location.href = 'http://login.onixhub.com?sessionTimeout=true'
    window.location.href = 'http://login.onixhub.com?sessionTimeout=true'
  }

  applyFilters(filterType, keyword, index, title = null) {
    if(filterType == 'prefJournals') filterType = 'journals'

    if(this.appliedFilters[filterType].indexOf(keyword) != -1)
      this.appliedFilters[filterType].splice(this.appliedFilters[filterType].indexOf(keyword), 1)
    else
      this.appliedFilters[filterType].push(keyword)

    if(filterType == 'targets') {
      this.showClearAll[title] = this.analytics[title].keywords.every(keyword => {
        return (this.appliedFilters[filterType].indexOf(keyword.keyword) != -1)
      })
    } else if(filterType == 'journals') {
      this.showClearAll['journals'] = this.journals.every(keyword => {
        return (this.appliedFilters['journals'].indexOf(keyword) != -1)
      })
      this.showClearAll['prefJournals'] = this.preferredJournals.every(keyword => {
        return (this.appliedFilters['journals'].indexOf(keyword) != -1)
      })
      this.memberTargets.forEach((member, memberIndex) => {
        if(member.prefJournal) {
          this.showClearAll['MPJ_'+memberIndex] = member.prefJournal.every(keyword => {
            return (this.appliedFilters['journals'].indexOf(keyword) != -1)
          })
        }
      });
    } else if(filterType == 'countries') {
      this.showClearAll['countries'] = this.countries.every(keyword => {
        return (this.appliedFilters['countries'].indexOf(keyword) != -1)
      })
    } else if(filterType == 'publishYears') {
      this.showClearAll['publishYears'] = this.publishYears.every(keyword => {
        return (this.appliedFilters['publishYears'].indexOf(keyword) != -1)
      })
    }
    
    this.selectedPage = 1
    this.loadIdeasOfPage()

  }

  updateIdeasList(abstracts, isExactMatch) {

    if(!isExactMatch) {
      this.publications = []
      this.journals = []
      this.publishYears = []
      this.countries = []
    }

    if(abstracts.PubmedArticleSet && abstracts.PubmedArticleSet.PubmedArticle) {
      abstracts.PubmedArticleSet.PubmedArticle.forEach(abstract => {
        let abstractText = {}
        let shortAbstractText = ''

        if(abstract.MedlineCitation[0].Article[0].Abstract) {
          abstract.MedlineCitation[0].Article[0].Abstract[0].AbstractText.forEach((abstract, index) => {
            if(abstract['_']) {
              if(abstract['$'] && abstract['$']['Label']) {
                abstractText[index+'_'+abstract['$']['Label']] = abstract['_']
              } else {
                abstractText[index+'_Abstract'] = abstract['_']
              }
            } else {
              abstractText['0_Abstract'] = abstract
            }
          });
        } else {
          abstractText = {
            '0_Abstract' : 'Empty'
          }
        }

        Object.keys(abstractText).forEach((abstractTitle) => {
          if(shortAbstractText != '') shortAbstractText +=  '<br/>'
          shortAbstractText += '<strong>'+abstractTitle.substr(2) + ':</strong> ' + abstractText[abstractTitle]  
        });
        
        
        let publication = {
          title: (abstract.MedlineCitation[0].Article[0].ArticleTitle[0]['_']) ? abstract.MedlineCitation[0].Article[0].ArticleTitle[0]['_'] : abstract.MedlineCitation[0].Article[0].ArticleTitle[0],
          abstract: JSON.stringify(abstractText),
          shortAbstractText: shortAbstractText,
          publishedOn: (abstract.MedlineCitation[0].Article[0].ArticleDate) ? abstract.MedlineCitation[0].Article[0].ArticleDate[0].Year[0] + '-' + abstract.MedlineCitation[0].Article[0].ArticleDate[0].Month[0] + '-' + abstract.MedlineCitation[0].Article[0].ArticleDate[0].Day[0] : 'Not Available',
          researcher: (abstract.MedlineCitation[0].Article[0].AuthorList) ? (abstract.MedlineCitation[0].Article[0].AuthorList[0].Author[0].LastName + ' ' + abstract.MedlineCitation[0].Article[0].AuthorList[0].Author[0].ForeName + ' ' + abstract.MedlineCitation[0].Article[0].AuthorList[0].Author[0].Initials) : 'No Author',
          journal: abstract.MedlineCitation[0].Article[0].Journal[0].Title[0],
          country: abstract.MedlineCitation[0].MedlineJournalInfo[0].Country,
          pmid: abstract.MedlineCitation[0].PMID[0]['_'],
          doi: (abstract.MedlineCitation[0].Article[0].ELocationID) ? abstract.MedlineCitation[0].Article[0].ELocationID[(abstract.MedlineCitation[0].Article[0].ELocationID.length - 1)]['_'] : null,
          isPrefJournal: this.hasPrefJournal(abstract.MedlineCitation[0].Article[0].Journal[0].Title[0])
        }

        if(!isExactMatch) {
          this.publications.push(publication)
        } else {
          this.exactMatch = publication
          this.hasExactMatch = true
        }
  
        if(this.journals.indexOf(publication.journal) == -1){
          this.journals.push(publication.journal)
        }

        if(this.countries.indexOf(publication.country[0]) == -1){
          this.countries.push(publication.country[0])
        }
  
        if(abstract.MedlineCitation[0].Article[0].ArticleDate && this.publishYears.indexOf(abstract.MedlineCitation[0].Article[0].ArticleDate[0].Year[0]) == -1) {
          this.publishYears.push(abstract.MedlineCitation[0].Article[0].ArticleDate[0].Year[0])
        } else if(abstract.MedlineCitation[0].DateRevised[0] && this.publishYears.indexOf(abstract.MedlineCitation[0].DateRevised[0].Year[0]) == -1) {
          // this.publishYears.push(abstract.MedlineCitation[0].DateRevised[0].Year[0])
        }
      });

    } 
    
    if(abstracts.PubmedArticleSet && abstracts.PubmedArticleSet.PubmedBookArticle) {
      abstracts.PubmedArticleSet.PubmedBookArticle.forEach(abstract => {
        let abstractText = {}
        let shortAbstractText = ''

        if(abstract.BookDocument[0].Abstract) {
          abstract.BookDocument[0].Abstract[0].AbstractText.forEach((abstract, index) => {
            if(abstract['_']) {
              if(abstract['$'] && abstract['$']['Label']) {
                abstractText[index+'_'+abstract['$']['Label']] = abstract['_']
              } else {
                abstractText[index+'_Abstract'] = abstract['_']
              }
            } else {
              abstractText['0_Abstract'] = abstract
            }
          });
        } else {
          abstractText = {
            '0_Abstract' : 'Empty'
          }
        }

        Object.keys(abstractText).forEach((abstractTitle) => {
          if(shortAbstractText != '') shortAbstractText +=  '<br/>'
          shortAbstractText += '<strong>'+abstractTitle.substr(2) + ':</strong> ' + abstractText[abstractTitle]  
        });

        let publication = {
          title: (abstract.BookDocument[0].Book[0].BookTitle[0]['_']) ? abstract.BookDocument[0].Book[0].BookTitle[0]['_'] : abstract.BookDocument[0].Book[0].BookTitle[0],
          abstract: ((abstract.BookDocument[0].Abstract[0].AbstractText[0]['_']) ? abstract.BookDocument[0].Abstract[0].AbstractText[0]['_'] : abstract.BookDocument[0].Abstract[0].AbstractText[0]),
          shortAbstractText: shortAbstractText,
          publishedOn: (abstract.BookDocument[0].Book[0].PubDate) ? (abstract.BookDocument[0].Book[0].PubDate[0].Year[0] + ((abstract.BookDocument[0].Book[0].PubDate[0].Month) ? '-' + abstract.BookDocument[0].Book[0].PubDate[0].Month[0] : '' ) + ((abstract.BookDocument[0].Book[0].PubDate[0].Day) ? '-' + abstract.BookDocument[0].Book[0].PubDate[0].Day[0] : '')) : null,
          researcher: (abstract.BookDocument[0].Book[0].AuthorList) ? ((abstract.BookDocument[0].Book[0].AuthorList[0].Author[0].ForeName) ? (abstract.BookDocument[0].Book[0].AuthorList[0].Author[0].LastName + ' ' + abstract.BookDocument[0].Book[0].AuthorList[0].Author[0].ForeName + ' ' + abstract.BookDocument[0].Book[0].AuthorList[0].Author[0].Initials) : abstract.BookDocument[0].Book[0].AuthorList[0].Author[0].CollectiveName) : (abstract.BookDocument[0].AuthorList[0].Author[0].CollectiveName[0]),
          publisher: abstract.BookDocument[0].Book[0].Publisher[0].PublisherName[0],
          country: abstract.BookDocument[0].Book[0].Publisher[0].PublisherLocation[0],
          pmid: abstract.BookDocument[0].PMID[0]['_'],
          doi: null
        }
        
        if(!isExactMatch) {
          this.publications.push(publication)
        } else {
          this.exactMatch = publication
          this.hasExactMatch = true
        }
  
        // if(this.journals.indexOf(publication.journal) == -1)
        //   this.journals.push(publication.journal)
  
        if(abstract.BookDocument[0].Book[0].PubDate && this.publishYears.indexOf(abstract.BookDocument[0].Book[0].PubDate[0].Year[0]) == -1)
          this.publishYears.push(abstract.BookDocument[0].Book[0].PubDate[0].Year[0])
      });
    }

    if(isExactMatch && !abstracts.PubmedArticleSet) {
      this.similarArticilesHeading = 'Displaying most relevant results'
    } else if(isExactMatch) {
      this.similarArticilesHeading = 'Displaying most relevant results'
    }

    if(!isExactMatch) {
      this.showEmptyResultMsg = (this.publications.length == 0)
    } else {
      this.showEmptyMatchResult = true
    }
    
    if(document.querySelector('.popover')) {
      document.querySelector('.popover').remove()
    }

    
    this.userService.getCountryList().subscribe(
      countries => {
        this.countriesList = countries
        this.countriesList.unshift({name:'Choose...', code: ''})
        console.log(this.countriesList)
      }
    )

  }

  loadPagination() {
    this.pagination = []
    
    if(this.totalPages <= 6) {
      for(let pageNo = 1; pageNo <= this.totalPages; pageNo++)
        this.pagination.push({
          pageNo: pageNo,
          active: (pageNo == this.selectedPage)
        })
    } else {
      if(this.selectedPage > 3)
      this.pagination.push({
        pageNo: 1,
        active: false
      })
      if(this.selectedPage > 4)
        this.pagination.push({
          pageNo: '...',
          active: false
        })

      let renderPageNo = (this.selectedPage - 2)
      for(let renderedCount = 0; renderedCount < 5; renderedCount++) {
        if(renderPageNo >= 1 && renderPageNo <= this.totalPages) {
          this.pagination.push({
            pageNo: renderPageNo,
            active: (renderPageNo == this.selectedPage)
          })
        }
        renderPageNo++
      }
      
      if(this.selectedPage <= (this.totalPages - 4))
        this.pagination.push({
          pageNo: '...',
          active: false
        })
      if(this.selectedPage <= (this.totalPages - 3))
        this.pagination.push({
          pageNo: this.totalPages,
          active: false
        })
    }
  }

  loadIdeasOfPage(getRefinedByCount = false) {
    let filters = Object.assign({}, this.appliedFilters)
    filters['start'] = ((this.selectedPage - 1) * this.recordLength)
    filters['length'] = this.recordLength

    if(getRefinedByCount) filters['getRefinedByCount'] = true

    filters['userType'] = this.user.member[0].type
    filters['organization'] = this.user.organizationName

    this.esearchTerm = ''


    let seperatedTargetFilters = {}
    Object.keys(this.analytics).forEach(targetSection => {
      seperatedTargetFilters[targetSection] = this.analytics[targetSection].keywords.filter((keyword) => {
        return (this.appliedFilters['targets'].indexOf(keyword.keyword) != -1)
      }).map(function(keyword) {
        return keyword.keyword
      })
      // console.log(targetSection, this.analytics[targetSection].keywords.filter((keyword) => {
      //   return (this.appliedFilters['targets'].indexOf(keyword.keyword) != -1)
      // }).map(function(keyword) {
      //   return keyword.keyword
      // }))
    });

    this.memberTargets.forEach((member, memberIndex) => {
      if(member.target) {
        member.target.forEach(target => {
          if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
            seperatedTargetFilters[memberIndex+'_'+target.title] = [target.booleanSearch]
        });
      }
    });
    

    // var targets = this.appliedFilters['targets'].map(function(target) {
    //   return '"'+target+'"[tiab]'
    // });

    var journals = this.appliedFilters['journals'].map(function(journals) {
      return '"'+journals+'"[ta]'
    });

    var countries = this.appliedFilters['countries'].map(function(countries) {
      return '"'+countries+'"[pl]'
    });

    var publishYears = this.appliedFilters['publishYears'].map(function(publishYears) {
      return '"'+publishYears+'"[dp]'
    });
    
    if(journals.length > 0) {
      this.esearchTerm += "("+journals.join('+OR+')+")"
    }

    if(publishYears.length > 0) {
      if(this.esearchTerm != '') this.esearchTerm += 'AND'
      this.esearchTerm += "("+publishYears.join('+OR+')+")"
    }

    if(countries.length > 0) {
      if(this.esearchTerm != '') this.esearchTerm += 'AND'
      this.esearchTerm += "("+countries.join('+OR+')+")"
    }

    if(Object.keys(seperatedTargetFilters).length > 0) {
      let targetFilter = ''
      Object.keys(seperatedTargetFilters).forEach(targetFilterKey => {
        if(seperatedTargetFilters[targetFilterKey].length > 0) {
          if(targetFilter != '') targetFilter += 'OR'
          if(this.filterType == 'all')
            targetFilter += "("+seperatedTargetFilters[targetFilterKey].join('+AND+')+")"
          else
            targetFilter += "("+seperatedTargetFilters[targetFilterKey].join('+OR+')+")"
        }
      });

      if(targetFilter != '') {
        if(this.esearchTerm != '') this.esearchTerm += 'AND'
        this.esearchTerm += "("+targetFilter+")"
      }
    }

    // if(targets.length > 0) {
    //   if(this.esearchTerm != '') this.esearchTerm += 'AND'
    //   if(this.filterType == 'all') {
    //     this.esearchTerm += "("+targets.join('+AND+')+")"
    //   }
    //   else this.esearchTerm += "("+targets.join('+OR+')+")"
    // }

    if(this.searchKeyword != '') {
      if(this.esearchTerm != '') this.esearchTerm += 'AND'

      // let searchTerm = this.searchKeyword.replace(/'/g, "\\'").replace(/"/g, '\\"')
      let searchTerm = this.searchKeyword

      if(this.searchBy == 'abstract') {
        searchTerm = searchTerm.trim()
        let searchTermIndex = (searchTerm.indexOf('\n') != -1) ? searchTerm.indexOf('\n') : (searchTerm.length)
        searchTerm = searchTerm.substr(0, searchTermIndex)+'[TIAB]'
        // searchTerm = searchTerm + '[TIAB]'
      }

      if(this.searchBy == 'journal') {
        searchTerm = searchTerm.trim()
        let searchTermIndex = (searchTerm.indexOf('\n') != -1) ? searchTerm.indexOf('\n') : (searchTerm.length)
        searchTerm = ''+searchTerm.substr(0, searchTermIndex)+'[ta]'
        // searchTerm = searchTerm + '[TIAB]'
      }


      //   let randomIndex = searchTerm.split('').reduce(function(a, e, i) {
      //     if (e === ' ')
      //         a.push(i);
      //     return a;
      //   }, []);
        
      //   let minIndex = 0
      //   let maxIndex = (randomIndex.length - 1)
  
      //   for (let index = 0; index < 5; index++) {
      //     let replaceIndex = randomIndex[Math.floor(Math.random() * (maxIndex - minIndex) ) + minIndex];
      //     searchTerm = (this.replaceAt(searchTerm, replaceIndex, '" OR "'))
      //   }
  
      //   searchTerm = '"' + searchTerm + '"'
      // }

      this.esearchTerm += searchTerm
    }

    let queryStartsAt = new Date().getTime()

    let similarArticles = []

    if(this.searchBy == 'abstract') {
      let publicationsList = {}

      this.userService.eSearchforTerm(this.esearchTerm, this.sortBy, this.selectedPage, this.publishYearFilter).subscribe(
        result => {
          // this.totalRecordsInDb = result.esearchresult.count
          // this.allIdeasCount = result.esearchresult.count
          // this.targetedIdeasCount = result.esearchresult.count
      
          // this.filteredRecords = result.esearchresult.count
          // this.totalPages = Math.ceil(result.esearchresult.count / 10)
  
          let uIds = result.esearchresult.idlist.join(',')
          this.userService.eFetchForUID(uIds).subscribe(
            result => {
              var parseString = require('xml2js').parseString;
              parseString(result, (err, publications) => {
                // if(publications.PubmedArticleSet && publications.PubmedArticleSet.PubmedArticle) {
                //   publicationsList = publications
                // }

                this.loadingExactMatch = false

                this.updateIdeasList(publications, true)
                // if(getRefinedByCount) {
                //   this.selectAllJournals(false)
                //   this.selectAllPublishedYear(false)
                // }

                
                setTimeout(() => {

                  this.userService.getCommonWords().subscribe(
                    commonWords => {
                      let keywordList = []

                      let wordCount = {}

                      let abstractContent = ''
                      abstractContent = this.searchKeyword
                      abstractContent.split(' ').forEach(word => {
                        word = word.replace(/,/g, '').replace(/!/g, '').replace('.', '').toLowerCase()
                        if(wordCount[word]) wordCount[word] += 1
                        else wordCount[word] = 1
                      });

                      let sortable = []
                      for (var word in wordCount) {
                        sortable.push([word, wordCount[word]]);
                      }
                      
                      sortable.sort(function(a, b) {
                        return b[1] - a[1];
                      });

                      let keywordCount = 0;
                      sortable.forEach(word => {
                        let keyword = {}
                        if(keywordCount < 10) {
                          if(commonWords.indexOf(word[0].toLowerCase()) == -1) {
                            // if(keyword != '') keyword += '+OR+'
                            keyword['_id'] = word[0]
                            keyword['value'] = word[1]

                            if(keywordCount < 3) {
                              keyword['checked'] = true
                            } else {
                              keyword['checked'] = false
                            }

                            keywordList.push(keyword)
                            keywordCount++
                          }
                        }
                      });

                      // console.log(keywordList)

                      // this.topKeywords = keyword.split('+OR+').join(',')

                      let pubmedSearchKeyword = keywordList.slice(0,3).map(function(keyword, index) { return keyword._id}).join('+AND+')
                      // let pubmedSearchKeyword = keyword.split('+OR+').join('+OR+')

                      this.relevantResultKeywords = pubmedSearchKeyword.replace(/\+/g, " "); 
                      this.userService.eSearchforTerm(pubmedSearchKeyword, this.sortBy, this.selectedPage, this.publishYearFilter).subscribe(
                        result => {
                          this.totalRecordsInDb = result.esearchresult.count
                          this.allIdeasCount = result.esearchresult.count
                          this.targetedIdeasCount = result.esearchresult.count

                          this.filteredRecords = result.esearchresult.count
                          this.totalPages = Math.ceil(this.filteredRecords / 10)
                          
                          let uIds = result.esearchresult.idlist.join(',')
                          this.userService.eFetchForUID(uIds).subscribe(
                            result => {
                              var parseString = require('xml2js').parseString;
                              parseString(result, (err, publications) => {

                                if(publications.PubmedArticleSet && publications.PubmedArticleSet.PubmedArticle) {
                                  if(!publicationsList['PubmedArticleSet']) {
                                    publicationsList = publications
                                  } else {
                                    publicationsList['PubmedArticleSet']['PubmedArticle'].concat(publications.PubmedArticleSet.PubmedArticle)
                                  }
                                }

                                this.loadingSearchResult = false
                                
                                this.updateIdeasList(publicationsList, false)
                                this.loadPagination()
                              })
                            }
                          )
                        }
                      )
                    }
                  )
                }, 1000)

              });
            }
          )
        }
      )

    } else {
      this.relevantResultKeywords = ''

      if(this.searchBy == 'journal') {
        this.sortBy = 'relevance';
      }

      this.userService.eSearchforTerm(this.esearchTerm, this.sortBy, this.selectedPage, this.publishYearFilter).subscribe(
        result => {
          this.totalRecordsInDb = result.esearchresult.count
          this.allIdeasCount = result.esearchresult.count
          this.targetedIdeasCount = result.esearchresult.count
      
          this.filteredRecords = result.esearchresult.count
          this.totalPages = Math.ceil(result.esearchresult.count / 10)
  
  
          let uIds = result.esearchresult.idlist.join(',')
          this.userService.eFetchForUID(uIds).subscribe(
            result => {
              var parseString = require('xml2js').parseString;
              parseString(result, function (err, publications) {

                this.loadingSearchResult = false

                this.updateIdeasList(publications, false)
                this.loadPagination()
                // if(getRefinedByCount) {
                //   this.selectAllJournals(false)
                //   this.selectAllPublishedYear(false)
                // }
              }.bind(this));
            }
          )
        }
      )
    }
  }

  navigateToPage(selectedPage) {
    if(selectedPage == '...') return
    this.selectedPage = selectedPage
    this.loadIdeasOfPage()
  }

  navigateToNexPage() {
    this.selectedPage = (this.selectedPage != this.totalPages) ? this.selectedPage + 1 : this.selectedPage
    this.loadIdeasOfPage()
  }

  navigateToPreviousPage() {
    this.selectedPage = (this.selectedPage != 1) ? this.selectedPage - 1 : this.selectedPage
    this.loadIdeasOfPage()
  }

  customSearch() {
    this.hasExactMatch = false
    this.exactMatch = {}
    this.publications = []

    if(this.searchKeyword != '') {
      localStorage.setItem('searchKeyword', this.searchKeyword)
      localStorage.setItem('searchBy', this.searchBy)
      
      let searchData = {
        organization: this.user._id,
        id: this.user.member[0]._id,
        email: this.user.member[0].email,
        search: this.searchKeyword,
        tab: "Publications"
      }
      
      this.userService.logSearch(searchData).subscribe(response => {
        console.log(response)
      })
  
      // localStorage.setItem("searchKeyword", this.searchKeyword)
  
      this.appliedFilters['targets'] = []
      this.appliedFilters['ideaTypes'] = []
      this.appliedFilters['organizationTypes'] = []
      this.appliedFilters['publishYears'] = []
      this.appliedFilters['journals'] = []
      this.appliedFilters['countries'] = []
  
      this.showClearAll['publishYears'] = false
      this.showClearAll['journals'] = false
      this.showClearAll['countries'] = false
      this.showClearAll['prefJournals'] = false
  
      Object.keys(this.analytics).forEach(targetTitle => {
        this.analytics[targetTitle].keywords.forEach(keyword => {
          this.appliedFilters[targetTitle] = []
          this.showClearAll[targetTitle] = false
        })
      });

      this.memberTargets.forEach((member, memberIndex) => {
        if(member.target) {
          member.target.forEach(target => {
            this.showClearAll['MT_'+memberIndex+'_'+target.title] = false
          });
        }
        this.memberTargetSelecteAll[memberIndex] = false
      })
  
      this.targetSelecteAll = false
      
      this.selectedPage = 1
      this.loadIdeasOfPage(true)
      this.showAdvancedSearch(false)
  
      if(this.searchBy == 'keyword') {
        this.hasExactMatch = false
        this.exactMatch = {}
        this.relevantResultKeywords = ''
      }

      this.loadingExactMatch = true
      this.loadingSearchResult = true
      // this.showEmptyResultMsg = false
      // this.showEmptyMatchResult = false
    } else {
      this.clearSearch()
    }
  }

  formateDate(date) {
    let dateObj = new Date(date)
    return dateObj.getFullYear()+'-'+("00"+(dateObj.getMonth()+1)).substr(-2)+"-"+("00"+(dateObj.getDate())).substr(-2)
  }

  addNewTarget() {
    this.user.target.push({})
    this.organizationData.target.push({})
  }

  // addNewMemberTarget() {
  //   this.memberTargets[0].target.push({})
  // }

  addNewMemberTarget(memberIndex) {
    console.log(memberIndex)
    console.log(this.organizationData.member[memberIndex])
    console.log(this.memberTargets)

    let fromManageModal = (memberIndex != null)
    if(memberIndex == null) memberIndex = this.selectedMember
    
    console.log(memberIndex)
    
    if(!this.organizationData.member[memberIndex]['target']) {
      this.organizationData.member[memberIndex]['target'] = []
      this.memberTargets[(fromManageModal) ? (memberIndex - 1) : memberIndex] = {
        'target': []
      }
    }
    this.organizationData.member[memberIndex]['target'].push({})


    this.memberTargets[(fromManageModal) ? (memberIndex - 1) : memberIndex].target.push({})
  }

  deleteTarget(targetIndex) {
    this.user.target.splice(targetIndex, 1)
    this.organizationData.target.splice(targetIndex, 1)
  }

  // deleteMemberTarget(targetIndex) {
  //   this.memberTargets[0].target.splice(targetIndex, 1)
  // }

  deleteMemberTarget(memberIndex, targetIndex) {
    let fromManageModal = (memberIndex != null)
    if(memberIndex == null) memberIndex = this.selectedMember
    if(this.organizationData.member[memberIndex]['target']) this.organizationData.member[memberIndex]['target'].splice(targetIndex, 1)

    if(fromManageModal) memberIndex = memberIndex - 1
    this.memberTargets[memberIndex].target.splice(targetIndex, 1)
    console.log(memberIndex, targetIndex, this.organizationData.member)
  }

  saveTargets() {
    this.userService.saveTargets(this.user).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        
        if(response.status) localStorage.setItem('user', JSON.stringify(this.user))

        this.targetTitle = []
        this.analytics = []

        this.selectedPage = 1
        this.loadIdeasOfPage()

        this.userService.getTargetCount(this.user._id, this.category).subscribe(
          analytics => {
            analytics.forEach(keyword => {
              if(!this.analytics[keyword.title]) this.analytics[keyword.title] = {
                count: 0,
                keywords: []
              }
              this.analytics[keyword.title]['count'] += keyword.count
              this.analytics[keyword.title]['keywords'].push(keyword)
            });
            this.targetTitle = Object.keys(this.analytics)
            this.editTargets.nativeElement.click();
            this.editTargetsClose.nativeElement.click()
          }
        )
      }
    )
  }

  saveMemberTargets() {
    this.userService.saveMemberTargets(this.memberTargets[this.selectedMember]._id, this.memberTargets[this.selectedMember].target).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        
        if(response.status) localStorage.setItem('user', JSON.stringify(this.user))

        this.targetTitle = []
        this.analytics = []

        this.selectedPage = 1
        this.loadIdeasOfPage()

        this.userService.getMemberById(this.user._id).subscribe(
          (organization) => {
            this.activeTabs = []
            console.log(organization)
            this.memberDetailsModal = 'Update member details'
            this.organizationData = organization
            this.organizationData.member[0].confirmEmail = this.organizationData.member[0].email
            this.selectTab('organization')
          }
        )

        this.userService.getTargetCount(this.user._id, this.category).subscribe(
          analytics => {
            analytics.forEach(keyword => {
              if(!this.analytics[keyword.title]) this.analytics[keyword.title] = {
                count: 0,
                keywords: []
              }
              this.analytics[keyword.title]['count'] += keyword.count
              this.analytics[keyword.title]['keywords'].push(keyword)
            });
            this.targetTitle = Object.keys(this.analytics)
            this.editTargets.nativeElement.click();
            this.editMemberTargetsClose.nativeElement.click()
          }
        )
      }
    )
  }

  addToPrefJournal(journalName, publicationIndex) {
    if(!this.user.member[0].prefJournals) {
      this.user.member[0].prefJournals = [] 
    }

    if(this.user.member[0].prefJournals && this.user.member[0].prefJournals.indexOf(journalName) == -1) {
      this.user.member[0].prefJournals.push(journalName)
      this.preferredJournals.push(journalName)
    }

    localStorage.setItem('user', JSON.stringify(this.user))

    this.userService.updatePrefJournal(this.user.member[0]._id, this.user.member[0].prefJournals).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        this.publications[publicationIndex].isPrefJournal = true
      }
    )
  }

  removeFromPrefJournal(journalName, publicationIndex) {
    if(this.user.member[0].prefJournals && this.user.member[0].prefJournals.indexOf(journalName) != -1) {
      this.user.member[0].prefJournals.splice(this.user.member[0].prefJournals.indexOf(journalName), 1)
      this.preferredJournals.splice(this.user.member[0].prefJournals.indexOf(journalName), 1)
    }

    console.log(this.user)
    localStorage.setItem('user', JSON.stringify(this.user))
    console.log(localStorage.getItem('user'))

    this.userService.updatePrefJournal(this.user.member[0]._id, this.user.member[0].prefJournals).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        this.publications[publicationIndex].isPrefJournal = false
      }
    )
  }

  updateSortOrder(sortBy) {
    this.sortBy = sortBy

    this.selectedPage = 1
    this.loadIdeasOfPage()
  }

  restrictByTargets(filterType, filterText) {
    this.filterType = filterType
    this.selectedFilterText = filterText
    
    this.selectedPage = 1
    this.loadIdeasOfPage(true)
  }

  filterPublishYear(filterYear) {
    if(filterYear == this.publishYearFilter)
      this.publishYearFilter = 0
    else 
      this.publishYearFilter = filterYear

    this.selectedPage = 1
    this.loadIdeasOfPage(true)
  }

  sortData(array) {
    return array.sort((a, b) => a < b ? -1 : 1);
  }

  showAdvancedSearch(show) {
    this.advancedSearch = show
  }

  updateSearchBy(searchBy) {
    this.searchBy = searchBy
    this.searchKeyword = ''
    localStorage.removeItem('searchKeyword')
  }

  clearSearch() {
    this.searchKeyword = ''
    this.searchBy = 'keyword'
    localStorage.removeItem('searchKeyword')
    this.hasExactMatch = false
    this.exactMatch = {}
    this.publications = []
    this.loadingExactMatch = true
    this.loadingSearchResult = true

    Object.keys(this.analytics).forEach(targetTitle => {
      this.selectAllTarget(targetTitle, false)
    })

    this.memberTargets.forEach((memberTarget, targetIndex) => {
      this.selectAllMemberTargets(targetIndex, null);
    });
    
    this.showEmptyMatchResult = false

    this.selectedPage = 1
    this.loadIdeasOfPage(true)
    this.showAdvancedSearch(false)
  }

  replaceAt(string, index, replacement) {
    return (string.substr(0, index) + replacement + string.substr(index + replacement.length));
  }

  selectMember(memberId) {
    this.selectedMember = memberId
  }
  
  saveMember() {
    console.log(this.organizationData)
    this.userService.saveMember(this.organizationData).subscribe(
      data => {
        if(data.status) {
          let alertMessage = 'Member Details has been updated successfully!'
          if(!this.organizationData._id) alertMessage = 'New Member is added successfully!'
          swal({
            title: alertMessage,
            type: 'success',
            confirmButtonText: 'Okay'
          })

          this.userService.getMemberTargets(this.user._id).subscribe(
            (memberTargets) => {
              this.memberTargets = memberTargets[0].member.slice(1)
              // this.memberTargets.forEach((member, memberIndex) => {
              //   this.memberTargetSelecteAll[memberIndex] = (localStorage.getItem('searchKeyword') == null)
              //   if(member.target) {
              //     member.target.forEach(target => {
              //       this.appliedFilters['targets'].push(target.booleanSearch)
              //       this.showClearAll['MT_'+memberIndex+'_'+target.title] = (localStorage.getItem('searchKeyword') == null)
              //     });
              //   }
              // });
              this.loadIdeasOfPage(true)
          })

          this.closeSetupOrganizationModel.nativeElement.click();
        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

  addActiveTab(tabId) {
    if(this.activeTabs.indexOf(tabId) == -1) {
      this.activeTabs.push(tabId)
    }
    this.selectedTab = tabId
  }

  closeActiveTab(tabId) {
    this.activeTabs.splice(this.activeTabs.indexOf(tabId), 1)
    this.selectTab('organization')
  }

  selectTab(tabName) {
    this.selectedTab = tabName;
  }

  addNewMember() {
    this.organizationData.member.push({
      type: 'Subordinate'
    })
    console.log(this.organizationData.member)
    this.addActiveTab((this.organizationData.member.length - 1))

    // setTimeout(() => {
    //   var toggler = document.getElementsByClassName("caret");

    //   toggler[toggler.length - 1].addEventListener("click", function() {
    //     this.parentElement.querySelector(".nested").classList.toggle("active");
    //     this.classList.toggle("caret-down");
    //   });
    // }, 500)
  }

  deleteMember(memberIndex) {
    this.organizationData.member.splice(memberIndex, 1)
    let activeTabIndex = this.activeTabs.indexOf((memberIndex - 1))
    this.activeTabs.splice(activeTabIndex, 1)
    this.selectTab('organization')
  }

  hasPrefJournal(journal) {
    let isExists = false

    console.log('Testing')
    if(this.user.member[0].prefJournal) {
      console.log(this.user.member[0], this.user.member[0].prefJournal)
      this.user.member[0].prefJournal.forEach(journalName => {
        console.log(journalName)
        if(journalName == journal) isExists = true
      });
    }
    // this.memberTargets.forEach(member => {
    //   // console.log(this.memberTargets)
    //   console.log(member)
    //   console.log(member.prefJournals)
    //   if(member.prefJournals) {
    //     member.prefJournals.forEach(journalName => {
    //       if(journalName == journal) isExists = true
    //       console.log(journalName, journal)
    //     });
    //   }
    // });

    console.log(isExists)

    return isExists;
  }

}
import { Component, OnInit, Renderer2, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../shared/user.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';

import { Router, ActivatedRoute, Params, NavigationExtras } from "@angular/router";

import swal from 'sweetalert2';
import { Subject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public currentPage: string = 'ideas'
  public user: any = {}
  public userType: string = ''
  public currentTab: string = 'filteredAbstracts'

  // Filters
  public appliedFilters: any = {
    'targets': [],
    'sponsors': [],
    'organizations': [],
    'phases': [],
    'ideaTypes': [],
    'organizationTypes': [],
    'countries': [],
    'fYears': [],
    'journals': [],
    'pubYear': 0,
    'organizationIdeas': [],
    'authors': [],
    'pubType': []
  }
  public isChecked: any = {}
  public isTargetUpdated: boolean = false;
  public isSelectAllChecked: any = {}
  public memberTargets: any = []
  
  public ideaTypesColumnMapping: any = {
    'Funded': 'isIdeaFunded',
    'New Ideas': 'isNewIdea',
    'Submitted': 'isIdeaSubmitted',
    'Tech Transfer':'isTechTransfer'
  }
  public ideaTypesKeys: any = Object.keys(this.ideaTypesColumnMapping)
  public organizationTypes: any = []
  public countries: any = []
  public organizationIdeas: any = []
  public authors: any = []
  public fYears: any = []
  public journals: any = []
  public publishYearFilter: number = 0;
  public sponsors: any = []
  public organizations: any = []
  public phases: any = ['Phase 1', 'Phase 2', 'Phase 3', 'Phase 4']
  public publicationTypes: any = ['Adaptive Clinical Trial', 'Address', 'Autobiography', 'Bibliography', 'Biography', 'Case Reports', 'Classical Article', 'Clinical Conference', 'Clinical Study', 'Clinical Trial', 'Clinical Trial, Phase I', 'Clinical Trial, Phase II', 'Clinical Trial, Phase III', 'Clinical Trial, Phase IV', 'Clinical Trial Protocol', 'Clinical Trial, Veterinary', 'Collected Work', 'Comment', 'Comparative Study', 'Congress', 'Consensus Development Conference', 'Consensus Development Conference, NIH', 'Controlled Clinical Trial', 'Corrected and Republished Article', 'Dataset', 'Dictionary', 'Directory', 'Duplicate Publication', 'Editorial', 'Electronic Supplementary Materials', 'English Abstract', 'Equivalence Trial', 'Evaluation Study', 'Expression of Concern', 'Festschrift', 'Government Publication', 'Guideline', 'Historical Article', 'Interactive Tutorial', 'Interview', 'Introductory Journal Article', 'Journal Article', 'Lecture', 'Legal Case', 'Legislation', 'Letter', 'Meta-Analysis', 'Multicenter Study', 'News', 'Newspaper Article', 'Observational Study', 'Observational Study, Veterinary', 'Overall', 'Patient Education Handout', 'Periodical Index', 'Personal Narrative', 'Portrait', 'Practice Guideline', 'Preprint', 'Pragmatic Clinical Trial', 'Published Erratum', 'Randomized Controlled Trial', 'Randomized Controlled Trial, Veterinary', 'Research Support, American Recovery and Reinvestment Act', 'Research Support, N.I.H., Extramural', 'Research Support, N.I.H., Intramural', 'Research Support, Non-U.S. Gov\'t', 'Research Support, U.S. Gov\'t, Non-P.H.S.', 'Research Support, U.S. Gov\'t, P.H.S.', 'Retracted Publication', 'Retraction of Publication', 'Review', 'Scientific Integrity Review', 'Systematic Review', 'Technical Report', 'Twin Study', 'Validation Study', 'Video-Audio Media', 'Webcast']
  // public phases: any = []
  public preferredJournals: any = []

  //Manage
  public selectedMember: number = 1
  public countriesList: any = []
  public activeTabs: any = []
  public memberDetailsModal: string = ''
  public organizationData: any = {}
  public selectedTab: string = 'organization'

  //Search
  public category = 'targets'
  public searchBy: string = 'keyword'
  public advancedSearch: boolean = false
  public searchKeyword: any = ''
  public pagesNameMapping: any = {
    'ideas': 'Ideas',
    'publications': 'Publications',
    'clinical-trials': 'Clinical Trials',
    'news': 'News'
  }
  public columnSortOrder: any = {
    'relevance' : {
      'sortBy': 'score'
    }, 'pIName' : {
      'sortBy': 'researcher.name.last.keyword,researcher.name.first.keyword',
      'sortOrder': '1,1'
    }, 'organization' : {
      'sortBy': 'researcher.organization.keyword',
      'sortOrder': '1'
    }, 'FYearDesc' : {
      'sortBy': 'FYear.keyword',
      'sortOrder': '-1'
    }, 'FYearAsc' : {
      'sortBy': 'FYear.keyword',
      'sortOrder': '1'
    }, 'submittedOnDesc' : {
      'sortBy': 'submittedOn',
      'sortOrder': '-1'
    }, 'submittedOnAsc' : {
      'sortBy': 'submittedOn',
      'sortOrder': '1'
    }
  }
  public pipelineColumnSortOrder: any = {
    'relevance' : {
      'sortBy': 'score'
    }, 'companyName' : {
      'sortBy': 'pipelineWebsite.keyword',
      'sortOrder': '1'
    }, 'city' : {
      'sortBy': 'city.keyword',
      'sortOrder': '1'
    }, 'State' : {
      'sortBy': 'state.keyword',
      'sortOrder': '1'
    }, 'Country' : {
      'sortBy': 'country.keyword',
      'sortOrder': '1'
    }
  }
  public sortBy: string = 'pub+date'
  public esearchTerm: string = ''

  

  //Pagination
  public selectedPage: number = 1
  public recordLength: number = 10
  public pagination: any = []
  public totalPages: number = 1

  //Search Results
  public hasExactMatch: boolean = false
  public exactMatch: any = {}
  public showEmptyMatchResult: boolean = false
  public relevantResultKeywords: string = ''
  public targetedIdeasCount: number = 0
  public totalRecordsInDb: number = 0
  public clinicalTrials: any = []
  public filteredRecords: number = 0
  public allIdeasCount: number = 0
  public browseBy: string = 'targets';
  public loadingExactMatch: boolean = true
  public loadingSearchResult: boolean = true
  public abstracts: any = []
  public showEmptyResultMsg: boolean = false;
  public similarArticilesHeading: string = 'Displaying most relevant results';
  public publications: any = []
  public pipelines: any = []
  public news: any = []
  public isBackToSearch: boolean = false

  // MySpace
  public mySpaceIdeaType: string = ''
  public selectedMySpaceIdeas: any = {}
  public myspaceTarget: string = ''
  public myspace: any = {}
  public myspaceIdeas: any = {
    "ideas": [],
    "publications": [],
    "clinicaltrials": [],
    "news": [],
    "pipelines": []
  }
  public selectedMySpaceTarget: string = '_0'
  public myspaceIdeaTypeFilter: string = 'ideas'
  public myspaceIdeasCount: any = {
    "ideas": 0,
    "publications": 0,
    "clinicaltrials": 0,
    "news": 0,
    "pipelines": 0
  }

  // Pipeline
  public companyName: string = ''
  public countryFilter: string = ''
  public cityFilter: string = ''
  public stateFilter: string = ''
  public columnSearchVisibility: any = {
    'country': false,
    'companyName': false,
    'city': false,
    'state': false
  }

  @ViewChild('closeSetupOrganizationModel') closeSetupOrganizationModel:ElementRef;
  @ViewChild('editTargetsClose') editTargetsClose: ElementRef;
  @ViewChild('editMemberTargetsClose') editMemberTargetsClose: ElementRef;
  @ViewChild('closeSaveToMySpace') closeSaveToMySpace: ElementRef;

  constructor(private userService: UserService, private renderer: Renderer2, private router: Router, private activatedRouter: ActivatedRoute, private chRef: ChangeDetectorRef, private spinner: NgxSpinnerService) {
    this.renderer.addClass(document.body, 'bg-light');
    this.renderer.removeClass(document.body, 'bg-blue');
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    // console.log(this.user)
    if(!this.user) this.sessionTimeOut()
    this.userType = this.user.member[0].type
    this.preferredJournals = this.user.member[0].prefJournal;

    this.activatedRouter.queryParams.subscribe((params: Params) => {

      $('#pageLoader').css('display', 'none')

      if(params['loadWithFilters'] == 'true') {
        this.appliedFilters = JSON.parse(params.appliedFilters)

        this.searchKeyword = params.searchKeyword
        this.totalRecordsInDb = params.totalRecordsInDb
        this.browseBy = params.browseBy
        this.selectedPage = params.pageNo
        this.currentPage = params.destination
        this.searchBy = params.searchBy
        this.myspaceIdeaTypeFilter = params.myspaceIdeaType
        this.selectedMySpaceTarget = params.selectedMySpaceTarget

        console.log(params)

        this.user.target.forEach((target, targetIndex) => {
          if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
            this.selectFilter(targetIndex, 'ORG_TAR', 'targets', false, true)
          }
        });

        this.publishYearFilter = this.appliedFilters['pubYear']

        // var selectAllKeys = ['IDEA_TYPE', 'ORG_TYPE', 'COUNTRY', 'FYEAR', 'JOURNAL', 'SPONSOR', 'ORGANIZATION', 'PHASE'];

        this.isSelectAllChecked['IDEA_TYPE'] = this.ideaTypesKeys.every(ideaType => {
          return (this.appliedFilters['ideaTypes'].indexOf(ideaType) != -1)
        })

        this.isBackToSearch = true
      } else {
        this.isChecked['ORG_TAR_0'] = true;
        this.appliedFilters['targets'].push(this.user.target[0].booleanSearch)
    
        this.isSelectAllChecked['ORG_TAR'] = this.user.target.every(target => {
          return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
        })
      }
      this.loadMemberTargets();
      this.loadMySpaceIdeas();
      this.getCountriesList();
      this.loadManageOrganization();

      if(this.currentPage == 'ideas') this.loadOrganizationTypes();
    })
  }

  // selectAppliedFilters(skip) {
  //   if(skip) {
  //     this.user.target.forEach((target, targetIndex) => {
  //       if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
  //         this.selectFilter(targetIndex, 'ORG_TAR', 'targets', false, true)
  //       }
  //     });

  //     this.user.member[0].prefJournal.forEach((journal, journalIndex) => {
  //       this.selectFilter(journalIndex, 'MY_PREF_JOURNAL', 'journals', false, true)
  //     });
  // }
  //   }

  loadMySpaceIdeas() {
    this.userService.getMySpaceIdeas(this.user.member[0]._id).subscribe(
      (mySpace) => {
        this.myspace = mySpace;
        // if(mySpace.status || mySpace.status == false) this.sessionTimeOut()
        this.myspaceIdeas['ideas'] = []
        mySpace['ideas'].forEach(savedIdeas => {
          if(savedIdeas.ideas) {
            savedIdeas.ideas.forEach(idea => {
              var parsedIdea = JSON.parse(idea)
              this.myspaceIdeas['ideas'].push(parsedIdea._id)
            });
          }
        });

        this.myspaceIdeas['publications'] = []
        mySpace['publications'].forEach(savedIdeas => {
          if(savedIdeas.ideas) {
            savedIdeas.ideas.forEach(idea => {
              var parsedIdea = JSON.parse(idea)
              this.myspaceIdeas['publications'].push(parsedIdea.pmid)
            });
          }
        });

        this.myspaceIdeas['clinicaltrials'] = []
        mySpace['clinicaltrials'].forEach(savedIdeas => {
          if(savedIdeas.ideas) {
            savedIdeas.ideas.forEach(idea => {
              var parsedIdea = JSON.parse(idea)
              this.myspaceIdeas['clinicaltrials'].push(parsedIdea.NCTId[0])
            });
          }
        });

        this.myspaceIdeas['news'] = []
        mySpace['news'].forEach(savedIdeas => {
          if(savedIdeas.ideas) {
            savedIdeas.ideas.forEach(idea => {
              var parsedIdea = JSON.parse(idea)
              this.myspaceIdeas['news'].push(parsedIdea.guid[0]['_'])
            });
          }
        });

        this.myspaceIdeas['pipelines'] = []
        mySpace['pipelines'].forEach(savedIdeas => {
          if(savedIdeas.ideas) {
            savedIdeas.ideas.forEach(idea => {
              var parsedIdea = JSON.parse(idea)
              this.myspaceIdeas['pipelines'].push(parsedIdea._id)
            });
          }
        });
        
        if(this.currentPage == 'myspace') this.filterMySpaceIdeas()
      }
    );
  }

  goToPage(page) {
    this.currentPage = page;
    if(this.searchBy == 'journal') this.searchBy = 'keyword'

    this.hasExactMatch = false
    this.exactMatch = {}
    this.showEmptyMatchResult = false

    this.loadingSearchResult = true
    this.loadIdeasOfPage(true);
    this.showAdvancedSearch(false)
    this.totalRecordsInDb = 0
    this.selectedPage = 1

    if(page == 'myspace') this.filterMySpaceIdeas()
  }

  selectFilter(targetIndex, filterFor, filterType, refreshPage, skipAddingApplyFilter) {
    var targets = []

    this.loadingSearchResult = true;

    if(filterFor == 'ORG_TAR') {
      targets = this.user.target;
      this.isTargetUpdated = true;
    } else if(filterFor.indexOf('MEM_TAR') != -1) {
      var memberIndex = filterFor.substr((filterFor.lastIndexOf('_') + 1))
      targets = this.memberTargets[memberIndex].target
      this.isTargetUpdated = true;
    } else if(filterFor == 'MY_PREF_JOURNAL') {
      targets = (this.user.member[0].prefJournal) ? this.user.member[0].prefJournal : [];
    } else if(filterFor.indexOf('MEM_PREF_JOURNAL') != -1) {
      var memberIndex = filterFor.substr((filterFor.lastIndexOf('_') + 1))
      targets = this.memberTargets[memberIndex].prefJournal
    }

    var filter = ''
    if(filterType == 'targets') {
      filter = targets[targetIndex].booleanSearch
    } else if(filterType == 'ideaTypes') {
      filter = this.ideaTypesKeys[targetIndex]
    } else if(filterType == 'organizationTypes') {
      filter = this.organizationTypes[targetIndex].type
    } else if(filterType == 'countries') {
      filter = (this.countries[targetIndex].key) ? this.countries[targetIndex].key : this.countries[targetIndex]
    } else if(filterType == 'fYears') {
      filter = this.fYears[targetIndex].key
    } else if(filterType == 'authors') {
      filter = this.authors[targetIndex].key
    } else if(filterType == 'organizationIdeas') {
      filter = this.organizationIdeas[targetIndex].key
    } else if(filterFor == 'MY_PREF_JOURNAL' || (filterFor.indexOf('MEM_PREF_JOURNAL') != -1)) {
      filter = targets[targetIndex]
    } else if(filterType == 'journals') {
      filter = this.journals[targetIndex]
    } else if(filterType == 'sponsors') {
      filter = this.sponsors[targetIndex]
    } else if(filterType == 'organizations') {
      filter = this.organizations[targetIndex]
    } else if(filterType == 'phases') {
      filter = this.phases[targetIndex]
    } else if(filterType == 'pubType') {
      filter = this.publicationTypes[targetIndex]
    }

    var filterIndex = this.appliedFilters[filterType].indexOf(filter)

    if(filterIndex == -1 || skipAddingApplyFilter) {
      if(!skipAddingApplyFilter) this.appliedFilters[filterType].push(filter)
      this.isChecked[filterFor+'_'+targetIndex] = true;
    } else {
      this.appliedFilters[filterType].splice(filterIndex, 1)
      this.isChecked[filterFor+'_'+targetIndex] = false;
    }

    if(filterType == 'targets') {
      this.isSelectAllChecked[filterFor] = targets.every(target => {
        return (this.appliedFilters[filterType].indexOf(target.booleanSearch) != -1)
      })
    } else if(filterType == 'ideaTypes') {
      this.isSelectAllChecked[filterFor] = this.ideaTypesKeys.every(ideaType => {
        return (this.appliedFilters[filterType].indexOf(ideaType) != -1)
      })
    } else if(filterType == 'organizationTypes') {
      this.isSelectAllChecked[filterFor] = this.organizationTypes.every(orgType => {
        return (this.appliedFilters[filterType].indexOf(orgType.type) != -1)
      })
    } else if(filterType == 'countries') {
      this.isSelectAllChecked[filterFor] = this.countries.every(country => {
        var countryName = (country.key) ? country.key : country;
        return (this.appliedFilters[filterType].indexOf(countryName) != -1)
      })
    } else if(filterType == 'fYears') {
      this.isSelectAllChecked[filterFor] = this.fYears.every(fyear => {
        return (this.appliedFilters[filterType].indexOf(fyear.key) != -1)
      })
    } else if(filterType == 'authors') {
      this.isSelectAllChecked[filterFor] = this.authors.every(author => {
        return (this.appliedFilters[filterType].indexOf(author.key) != -1)
      })
    } else if(filterType == 'organizationIdeas') {
      this.isSelectAllChecked[filterFor] = this.organizationIdeas.every(organization => {
        return (this.appliedFilters[filterType].indexOf(organization.key) != -1)
      })
    } else if(filterFor == 'MY_PREF_JOURNAL' || (filterFor.indexOf('MEM_PREF_JOURNAL') != -1) || filterFor == 'JOURNAL') {
      this.isSelectAllChecked['JOURNAL'] = this.journals.every(journal => {
        return (this.appliedFilters[filterType].indexOf(journal) != -1)
      })
      this.isSelectAllChecked['MY_PREF_JOURNAL'] = this.user.member[0].prefJournal.every(journal => {
        return (this.appliedFilters[filterType].indexOf(journal) != -1)
      })
      this.memberTargets.forEach((member, memberIndex) => {
        if(member.prefJournal) {
          this.isSelectAllChecked['MEM_PREF_JOURNAL_'+memberIndex] = member.prefJournal.every(journal => {
            return (this.appliedFilters[filterType].indexOf(journal) != -1)
          })
        }
      });
    } else if(filterType == 'pubType') {
      this.isSelectAllChecked[filterFor] = this.publicationTypes.every(pubType => {
        return (this.appliedFilters[filterType].indexOf(pubType) != -1)
      })
    } else if(filterType == 'sponsors') {
      this.isSelectAllChecked[filterFor] = this.sponsors.every(sponsor => {
        return (this.appliedFilters[filterType].indexOf(sponsor) != -1)
      })
    } else if(filterType == 'organizations') {
      this.isSelectAllChecked[filterFor] = this.organizations.every(organization => {
        return (this.appliedFilters[filterType].indexOf(organization) != -1)
      })
    } else if(filterType == 'phases') {
      this.isSelectAllChecked[filterFor] = this.phases.every(phase => {
        return (this.appliedFilters[filterType].indexOf(phase) != -1)
      })
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  selectAllFilters(filterFor, filterType) {
    var targets = []
    this.loadingSearchResult = true;

    if(filterFor == 'ORG_TAR') {
      targets = this.user.target;
      this.isTargetUpdated = true;
    } else if(filterFor.indexOf('MEM_TAR') != -1) {
      var memberIndex = filterFor.substr((filterFor.lastIndexOf('_') + 1))
      targets = this.memberTargets[memberIndex].target
      this.isTargetUpdated = true;
    } else if(filterFor == 'IDEA_TYPE') {
      targets = this.ideaTypesKeys
    } else if(filterFor == 'ORG_TYPE') {
      targets = this.organizationTypes
    } else if(filterFor == 'COUNTRY') {
      targets = this.countries
    } else if(filterFor == 'FYEAR') {
      targets = this.fYears
    } else if(filterFor == 'ORGANIZATIONIDEAS') {
      targets = this.organizationIdeas
    } else if(filterFor == 'AUTHORS') {
      targets = this.authors
    } else if(filterFor == 'MY_PREF_JOURNAL') {
      targets = (this.user.member[0].prefJournal) ? this.user.member[0].prefJournal : [];
    } else if(filterFor.indexOf('MEM_PREF_JOURNAL') != -1) {
      var memberIndex = filterFor.substr((filterFor.lastIndexOf('_') + 1))
      targets = this.memberTargets[memberIndex].prefJournal
    } else if(filterFor == 'JOURNAL') {
      targets = this.journals
    } else if(filterFor == 'SPONSOR') {
      targets = this.sponsors
    } else if(filterFor == 'ORGANIZATION') {
      targets = this.organizations
    } else if(filterFor == 'PHASE') {
      targets = this.phases
    } else if(filterFor == 'PUBTYPE') {
      targets = this.publicationTypes
    }

    targets.forEach((target, targetIndex) => {
      var filter = ''
      if(filterFor == 'ORG_TAR' || filterFor.indexOf('MEM_TAR') != -1) {
        filter = target.booleanSearch
      } else if(filterFor == 'IDEA_TYPE') {
        filter = this.ideaTypesKeys[targetIndex]
      } else if(filterFor == 'ORG_TYPE') {
        filter = this.organizationTypes[targetIndex].type
      } else if(filterFor == 'COUNTRY') {
        filter = (this.countries[targetIndex].key) ? this.countries[targetIndex].key : this.countries[targetIndex]
      } else if(filterFor == 'FYEAR') {
        filter = this.fYears[targetIndex].key
      } else if(filterFor == 'AUTHORS') {
        filter = this.authors[targetIndex].key
      } else if(filterFor == 'ORGANIZATIONIDEAS') {
        filter = this.organizationIdeas[targetIndex].key
      } else if(filterFor == 'MY_PREF_JOURNAL' || (filterFor.indexOf('MEM_PREF_JOURNAL') != -1)) {
        filter = target
      } else if(filterFor == 'JOURNAL') {
        filter = target
      } else if(filterFor == 'SPONSOR') {
        filter = target
      } else if(filterFor == 'ORGANIZATION') {
        filter = target
      } else if(filterFor == 'PHASE') {
        filter = target
      } else if(filterFor == 'PUBTYPE') {
        filter = target
      }

      var filterIndex = this.appliedFilters[filterType].indexOf(filter);
      if(!this.isSelectAllChecked[filterFor] && filterIndex == -1) {
        this.appliedFilters[filterType].push(filter)
        this.isChecked[filterFor+'_'+targetIndex] = true;
      } else if(this.isSelectAllChecked[filterFor]) {
        this.appliedFilters[filterType].splice(filterIndex, 1)
        this.isChecked[filterFor+'_'+targetIndex] = false;
      }
    });

    this.isSelectAllChecked[filterFor] = !this.isSelectAllChecked[filterFor]

    this.selectedPage = 1
    this.loadIdeasOfPage()
  }

  sessionTimeOut() {
    localStorage.clear()
    window.location.href = 'http://login.onixhub.com?sessionTimeout=true'
  }

  logout() {
    swal({
      title: 'Are you sure?',
      text: "Do you want to logout?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I\'m Sure'
    }).then((result) => {
      if (result.value) {
        localStorage.clear()
        // this.router.navigate(['']);
        window.location.href = 'https://onixhub.com'
      }
    })
  }

  loadMemberTargets() {
    if(this.userType == 'Manager') {
      this.userService.getMemberTargets(this.user._id).subscribe(
        (memberTargets) => {
          if(memberTargets.success || memberTargets.success == false) this.sessionTimeOut()
          
          this.memberTargets = memberTargets[0].member.slice(1)

          this.memberTargets.forEach((memberTarget, memberTargetIndex) => {
            memberTarget.target.forEach((target, targetIndex) => {
              if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
                this.selectFilter(targetIndex, 'MEM_TAR_'+memberTargetIndex, 'targets', false, true)
              }
            });

            if(memberTarget.prefJournal) {
              memberTarget.prefJournal.forEach((journal, targetIndex) => {
                if(this.appliedFilters['journals'].indexOf(journal) != -1) {
                  this.selectFilter(targetIndex, 'MEM_PREF_JOURNAL_'+memberTargetIndex, 'journals', false, true)
                }
              });
            }
          });
          this.loadIdeasOfPage(true)
        }
      );
    } else {
      this.memberTargets = this.user.member
      this.memberTargets.forEach((memberTarget, memberTargetIndex) => {
        memberTarget.target.forEach((target, targetIndex) => {
          if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
            this.selectFilter(targetIndex, 'MEM_TAR_'+memberTargetIndex, 'targets', false, true)
          }
        });
      });
      this.loadIdeasOfPage(true)
    }
  }

  loadOrganizationTypes() {
    this.userService.getOrganizationTypes().subscribe(
      organizationTypes => {
        this.organizationTypes = organizationTypes
        

        this.isSelectAllChecked['ORG_TYPE'] = this.organizationTypes.every(orgType => {
          return (this.appliedFilters['organizationTypes'].indexOf(orgType.type) != -1)
        })
      }
    )
  }

  selectMember(memberId) {
    this.selectedMember = memberId
  }

  sortData(array) {
    return array.sort((a, b) => a < b ? -1 : 1);
  }

  filterPublishYear(filterYear) {
    if(filterYear == this.publishYearFilter)
      this.publishYearFilter = 0
    else 
      this.publishYearFilter = filterYear
    
    this.appliedFilters['pubYear'] = this.publishYearFilter

    this.selectedPage = 1
    this.loadIdeasOfPage(true)
  }

  showAdvancedSearch(show) {
    this.advancedSearch = show
  }

  updateSearchBy(searchBy) {
    this.searchBy = searchBy
    this.searchKeyword = ''
    localStorage.removeItem('searchKeyword')
  }
  
  customSearch() {
    if(this.searchKeyword != '') {

      localStorage.setItem('searchKeyword', this.searchKeyword)
      localStorage.setItem('searchBy', this.searchBy)
      
      let searchData = {
        organization: this.user._id,
        id: this.user.member[0]._id,
        email: this.user.member[0].email,
        search: this.searchKeyword,
        tab: this.pagesNameMapping[this.currentPage]
      }
      
      this.userService.logSearch(searchData).subscribe(response => {
        console.log(response)
      })

      this.appliedFilters = {
        'targets': [],
        'sponsors': [],
        'organizations': [],
        'phases': [],
        'ideaTypes': [],
        'organizationTypes': [],
        'countries': [],
        'fYears': [],
        'organizationIdeas': [],
        'authors': [],
        'journals': [],
        'pubType': []
      }
  
      this.isChecked = {}
      this.isSelectAllChecked = {}
  
      this.selectedPage = 1
      this.loadIdeasOfPage(true)
      this.showAdvancedSearch(false)

      if(this.searchBy == 'keyword') {
        this.hasExactMatch = false
        this.exactMatch = {}
        this.relevantResultKeywords = ''
      }
    } else {
      this.clearSearch()
    }
  }

  clearSearch() {
    this.searchKeyword = ''
    this.searchBy = 'keyword'
    localStorage.removeItem('searchKeyword')
    this.hasExactMatch = false
    this.exactMatch = {}
    this.showEmptyMatchResult = false

    this.isChecked['ORG_TAR_0'] = true;
    this.appliedFilters['targets'].push(this.user.target[0].booleanSearch)

    this.selectedPage = 1
    this.loadIdeasOfPage(true)
    this.showAdvancedSearch(false)
  }

  updateSortOrder(sortBy) {
    if(this.currentPage == 'ideas') {
      this.appliedFilters['sortBy'] = this.columnSortOrder[sortBy].sortBy || false
      this.appliedFilters['sortOrder'] = this.columnSortOrder[sortBy].sortOrder || false
    } else if(this.currentPage == 'pipeline') {
      this.appliedFilters['sortBy'] = this.pipelineColumnSortOrder[sortBy].sortBy || false
      this.appliedFilters['sortOrder'] = this.pipelineColumnSortOrder[sortBy].sortOrder || false
    } else {
      this.sortBy = sortBy;
    }

    this.selectedPage = 1
    this.loadIdeasOfPage()
  }

  loadPagination() {
    this.pagination = []
    
    if(this.totalPages <= 6) {
      for(let pageNo = 1; pageNo <= this.totalPages; pageNo++) {
        this.pagination.push({
          pageNo: pageNo,
          active: (pageNo == this.selectedPage)
        })
      }
    } else {
      if(this.selectedPage > 3)
      this.pagination.push({
        pageNo: 1,
        active: false
      })
      if(this.selectedPage > 4)
        this.pagination.push({
          pageNo: '...',
          active: false
        })

      let renderPageNo = (this.selectedPage - 2)
      for(let renderedCount = 0; renderedCount < 5; renderedCount++) {
        if(renderPageNo >= 1 && renderPageNo <= this.totalPages) {
          this.pagination.push({
            pageNo: renderPageNo,
            active: (renderPageNo == this.selectedPage)
          })
        }
        renderPageNo++
      }
      
      if(this.selectedPage <= (this.totalPages - 4))
        this.pagination.push({
          pageNo: '...',
          active: false
        })
      if(this.selectedPage <= (this.totalPages - 3))
        this.pagination.push({
          pageNo: this.totalPages,
          active: false
        })
    }
  }

  save(abstractId) {
    // swal({
    //   title: 'Are you sure?',
    //   text: "Do you want to save this abstract?",
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'I\'m Sure'
    // }).then((result) => {
    //   if (result.value) {
        this.userService.saveAbstract(this.user.member[0]._id, abstractId).subscribe(
          data => {
            if(data.success || data.success == false) this.sessionTimeOut()
            if(data._id) {
              localStorage.setItem('user', JSON.stringify(data))
              this.user = data
              // swal("Success!", "Abstract has been saved!", "success");
            }
            else swal("Error!", "Abstract has not been saved!", "error");
          }
        )
    //   }
    // })
  }

  claim(abstractId) {
    // swal({
    //   title: 'Are you sure?',
    //   text: "Do you want to save this abstract?",
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'I\'m Sure'
    // }).then((result) => {
    //   if (result.value) {
        this.userService.claimAbstract(this.user.member[0]._id, abstractId).subscribe(
          data => {
            if(data.success || data.success == false) this.sessionTimeOut()
            if(data._id) {
              localStorage.setItem('user', JSON.stringify(data))
              this.user = data
              // swal("Success!", "Abstract has been saved!", "success");
            }
            else swal("Error!", "Abstract has not been saved!", "error");
          }
        )
    //   }
    // })
  }

  unSave(abstractId) {
    // swal({
    //   title: 'Are you sure?',
    //   text: "Do you want to un save this abstract?",
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'I\'m Sure'
    // }).then((result) => {
    //   if (result.value) {
        this.userService.unSaveAbstract(this.user.member[0]._id, abstractId).subscribe(
          data => {
            if(data.success || data.success == false) this.sessionTimeOut()
            if(data._id) {
              localStorage.setItem('user', JSON.stringify(data))
              this.user = data
              // swal("Success!", "Abstract has been removed!", "success");
            }
            else swal("Error!", "Abstract has not been removed!", "error");
          }
        )
    //   }
    // })
  }

  unClaim(abstractId) {
    // swal({
    //   title: 'Are you sure?',
    //   text: "Do you want to un save this abstract?",
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'I\'m Sure'
    // }).then((result) => {
    //   if (result.value) {
        this.userService.unClaimAbstract(this.user.member[0]._id, abstractId).subscribe(
          data => {
            if(data.success || data.success == false) this.sessionTimeOut()
            if(data._id) {
              localStorage.setItem('user', JSON.stringify(data))
              this.user = data
              // swal("Success!", "Abstract has been removed!", "success");
            }
            else swal("Error!", "Abstract has not been removed!", "error");
          }
        )
    //   }
    // })
  }
  
  loadIdeasOfPage(getRefinedByCount = false) {
    // if(this.isBackToSearch) {
    //   this.selectAppliedFilters(this.isBackToSearch)
    //   // this.isBackToSearch = false
    // }
    if(this.currentPage == 'ideas') {
      if(this.currentTab == 'filteredAbstracts') this.loadIdeasOfPageForIdeas(getRefinedByCount)
      else if(this.currentTab == 'savedAbstracts') this.loadSavedIdeas()
      else if(this.currentTab == 'claimedAbstracts') this.loadClaimedIdeas()
    } else if(this.currentPage == 'publications') {
      this.loadIdeasOfPageForPublications(getRefinedByCount)
    } else if(this.currentPage == 'clinical-trials') {
      this.loadIdeasOfPageForClinicalTrial(getRefinedByCount)
    } else if(this.currentPage == 'news') {
      this.loadIdeasOfPageForNews(getRefinedByCount)
    } else if(this.currentPage == 'pipeline') {
      this.loadIdeasOfPageForPipeline(getRefinedByCount)
    }
  }

  loadIdeasOfPageForIdeas(getRefinedByCount = false) {
    let filters = Object.assign({}, this.appliedFilters)
    
    if(this.category == 'all') {
      delete filters['targets']
    }

    if(this.searchBy == 'abstract') {
      filters['start'] = 0
    } else {
      filters['start'] = ((this.selectedPage - 1) * this.recordLength)
    }
    filters['length'] = this.recordLength

    if(getRefinedByCount) filters['getRefinedByCount'] = true

    filters['userType'] = this.user.member[0].type
    filters['organization'] = this.user.organizationName

    // filters['filterType'] = this.filterType

    if(this.searchKeyword != '') {
      filters['search'] = this.searchKeyword
      if(this.searchBy == 'abstract') {
        filters['search'] = '"' + filters['search'] + '"'
      }
    }

    filters['searchBy'] = this.searchBy

    filters['isBackToSearch'] = this.isBackToSearch;

    if(this.isTargetUpdated)
      filters['targetUpdated'] = 'true'
    else
      filters['targetUpdated'] = 'false'

    if(this.searchBy != 'abstract' && this.isBackToSearch) {
      let backToSearchFilters = Object.assign({}, filters)

      delete backToSearchFilters['countries']
      delete backToSearchFilters['fYears']
      delete backToSearchFilters['authors']
      delete backToSearchFilters['organizationIdeas']


      if(this.searchBy == 'abstract' && this.category != 'all') backToSearchFilters['getRefinedByCount'] = false

      this.userService.getIdeas(this.user._id, this.category, backToSearchFilters).subscribe(
        abstracts => {
          this.isTargetUpdated = false;
          this.updateIdeasListForIdeas(abstracts, (this.searchBy == 'abstract' && this.category != 'all'))
          this.isBackToSearch = false

          filters['getRefinedByCount'] = false

          this.userService.getIdeas(this.user._id, this.category, filters).subscribe(
            abstracts => {
                  
              if(abstracts.success || abstracts.success == false) this.sessionTimeOut()
      
              if(this.searchBy == 'abstract') {
                this.loadingExactMatch = false
              } else {
                this.loadingSearchResult = false
              }
              
              delete abstracts.aggregations

              this.updateIdeasListForIdeas(abstracts, (this.searchBy == 'abstract' && this.category != 'all'))
      
              this.loadPagination()
      
            }
          )
        }
      )
    } else {
      this.userService.getIdeas(this.user._id, this.category, filters).subscribe(
        abstracts => {
          this.isTargetUpdated = false;
          if(abstracts.success || abstracts.success == false) this.sessionTimeOut()
  
          if(this.searchBy == 'abstract') {
            this.loadingExactMatch = false
          } else {
            this.loadingSearchResult = false
          }
          
          this.updateIdeasListForIdeas(abstracts, (this.searchBy == 'abstract' && this.category != 'all'))
  
          this.loadPagination()
  
        }
      )
    }

    

    if(this.searchBy == 'abstract' && this.category != 'all') {
      this.userService.getCommonWords().subscribe(
        commonWords => {
          let keywordList = []
  
          let wordCount = {}
  
          let abstractContent = ''
          abstractContent = this.searchKeyword
  
          abstractContent.split(' ').forEach(word => {
            word = word.replace(/,/g, '').replace(/!/g, '').replace('.', '').toLowerCase()
            if(wordCount[word]) wordCount[word] += 1
            else wordCount[word] = 1
          });
  
          let sortable = []
          for (var word in wordCount) {
            sortable.push([word, wordCount[word]]);
          }
          
          sortable.sort(function(a, b) {
            return b[1] - a[1];
          });
  
          let keywordCount = 0;
          sortable.forEach(word => {
            let keyword = {}
            if(keywordCount < 10) {
              if(commonWords.indexOf(word[0].toLowerCase()) == -1) {
                // if(keyword != '') keyword += '+OR+'
                keyword['_id'] = word[0]
                keyword['value'] = word[1]
  
                if(keywordCount < 3) {
                  keyword['checked'] = true
                } else {
                  keyword['checked'] = false
                }
  
                keywordList.push(keyword)
                keywordCount++
              }
            }
          });
  
          // console.log(keywordList)
  
          // this.topKeywords = keyword.split('+OR+').join(',')
  
          let pubmedSearchKeyword = keywordList.slice(0,3).map(function(keyword, index) { return keyword._id}).join('+AND+')
          // let pubmedSearchKeyword = keyword.split('+OR+').join('+OR+')
  
          let abstractSearchFilter = Object.assign({}, filters)
          abstractSearchFilter['search'] = pubmedSearchKeyword
          

          this.relevantResultKeywords = pubmedSearchKeyword.replace(/\+/g, " "); 
  

          if(this.isBackToSearch) {
            let backToSearchFilters = Object.assign({}, abstractSearchFilter)
      
            delete backToSearchFilters['countries']
            delete backToSearchFilters['fYears']
            delete backToSearchFilters['authors']
            delete backToSearchFilters['organizationIdeas']
      
            backToSearchFilters['getRefinedByCount'] = true

            this.userService.getIdeas(this.user._id, this.category, backToSearchFilters).subscribe(
              abstracts => {
                if(abstracts.success || abstracts.success == false) this.sessionTimeOut()
                  
                this.updateIdeasListForIdeas(abstracts, false)
                this.isBackToSearch = false
                abstractSearchFilter['getRefinedByCount'] = false

                this.userService.getIdeas(this.user._id, this.category, abstractSearchFilter).subscribe(
                  abstracts => {
                    if(abstracts.success || abstracts.success == false) this.sessionTimeOut()
                    this.loadingSearchResult = false    
                    this.updateIdeasListForIdeas(abstracts, false)
            
                    this.loadPagination()
                  }
                )
              }
            )
          } else {
            this.userService.getIdeas(this.user._id, this.category, abstractSearchFilter).subscribe(
              abstracts => {
                if(abstracts.success || abstracts.success == false) this.sessionTimeOut()
                if(this.searchBy == 'abstract') {
                  this.loadingSearchResult = false
                }
  
                this.updateIdeasListForIdeas(abstracts, false)
              }
            )
          }
          
        }
      )
    } else {
      this.relevantResultKeywords = ''
    }
  }

  updateIdeasListForIdeas(abstracts, isExactMatch) {
    if(!this.isBackToSearch || isExactMatch) {
      if(!isExactMatch) {
        this.abstracts = []
        // this.ideaTypesKeys.forEach((key, i) => this.ideaTypes[key] = 0)
    
        if(abstracts.allIdeasCount) {
          this.allIdeasCount = abstracts.allIdeasCount
          if(this.totalRecordsInDb == 0) this.totalRecordsInDb = abstracts.allIdeasCount
        }
        this.targetedIdeasCount = abstracts.recordsTotal
  
        this.showEmptyResultMsg = (abstracts.hits.hits.length == 0);
        this.filteredRecords = abstracts.recordsTotal
        this.totalPages = Math.ceil(this.filteredRecords / this.recordLength)
    
        if(this.totalPages > 1000) this.totalPages = 1000
    
        abstracts.hits.hits.forEach(abstract => {
          this.abstracts.push({
            _id: abstract._id,
            title: abstract._source.title,
            name: abstract._source.researcher.name.last+', '+abstract._source.researcher.name.first,
            organization: abstract._source.researcher.organization,
            country: abstract._source.researcher.address.country,
            type: abstract._source.researcher.organizationType,
            submittedOn: abstract._source.submittedOn,
            abstract: abstract._source.abstract,
            aims: abstract._source.aims,
            FYear: abstract._source.FYear,
            remarks: abstract._source.remarks,
            viewCount: (abstract._source.viewCount) ? abstract._source.viewCount : 0,
            score: (abstract._score) ? abstract._score.toFixed(2) : 0
          })
    
          // this.ideaTypesKeys.forEach(key => {
          //   this.ideaTypes[key] += (abstract[this.ideaTypesColumnMapping[key]] == 'Y') ? 1 : 0
          // });
        })
      } else {
        if(abstracts.hits && abstracts.hits.hits[0]) {
          let abstract = abstracts.hits.hits[0]
          this.exactMatch = {
            _id: abstract._id,
            title: abstract._source.title,
            name: abstract._source.researcher.name.last+', '+abstract._source.researcher.name.first,
            organization: abstract._source.researcher.organization,
            country: abstract._source.researcher.address.country,
            type: abstract._source.researcher.organizationType,
            submittedOn: abstract._source.submittedOn,
            abstract: abstract._source.abstract,
            aims: abstract._source.aims,
            FYear: abstract._source.FYear,
            remarks: abstract._source.remarks,
            viewCount: (abstract._source.viewCount) ? abstract._source.viewCount : 0,
            score: (abstract._score) ? abstract._score.toFixed(2) : 0
          }
          this.hasExactMatch = true
        } else {
          this.hasExactMatch = false
          this.exactMatch = {}
        }
        this.showEmptyMatchResult = true
      }
    }

    if(abstracts.aggregations && abstracts.aggregations.distinct_Country && abstracts.aggregations.distinct_Country.buckets[0]) {
      this.countries = []
      abstracts.aggregations.distinct_Country.buckets.forEach(country => {
        this.countries.push(country)
      });
      // this.countries.sort()
    }

    if(abstracts.aggregations && abstracts.aggregations.distinct_FYear && abstracts.aggregations.distinct_FYear.buckets[0]) {
      this.fYears = []
      abstracts.aggregations.distinct_FYear.buckets.forEach(fyear => {
        if(fyear.key != '' && fyear.key.length == 4) {
          this.fYears.push(fyear)
        }
      });
      this.fYears.sort((a, b) => a.key.localeCompare(b.key));
      this.fYears.reverse()
    }

    if(abstracts.aggregations && abstracts.aggregations.distinct_Authors && abstracts.aggregations.distinct_Authors.buckets[0]) {
      this.authors = abstracts.aggregations.distinct_Authors.buckets;
      // this.authors.sort()
    }

    if(abstracts.aggregations && abstracts.aggregations.distinct_Organizations && abstracts.aggregations.distinct_Organizations.buckets[0]) {
      this.organizationIdeas = abstracts.aggregations.distinct_Organizations.buckets;
      // this.organizationIdeas.sort()
    }

    this.isSelectAllChecked['COUNTRY'] = this.countries.every(country => {
      return (this.appliedFilters['countries'].indexOf(country.key) != -1)
    })

    this.isSelectAllChecked['FYEAR'] = this.fYears.every(fyear => {
      return (this.appliedFilters['fYears'].indexOf(fyear.key) != -1)
    })
    
    this.isSelectAllChecked['ORGANIZATIONIDEAS'] = this.organizationIdeas.every(organization => {
      return (this.appliedFilters['organizationIdeas'].indexOf(organization.key) != -1)
    })
    
    this.isSelectAllChecked['AUTHORS'] = this.authors.every(author => {
      return (this.appliedFilters['authors'].indexOf(author.key) != -1)
    })

    if(document.querySelector('.popover')) {
      document.querySelector('.popover').remove()
    }
  }

  convertJSONtoString(data) {
    return JSON.stringify(data)
  }

  loadIdeasOfPageForPublications(getRefinedByCount = false) {
    let filters = Object.assign({}, this.appliedFilters)
    filters['start'] = ((this.selectedPage - 1) * this.recordLength)
    filters['length'] = this.recordLength

    if(getRefinedByCount) filters['getRefinedByCount'] = true

    filters['userType'] = this.user.member[0].type
    filters['organization'] = this.user.organizationName

    this.esearchTerm = ''

    // var targets = this.appliedFilters['targets'].map(function(target) {
    //   return '"'+target+'"[tiab]'
    // });

    var journals = this.appliedFilters['journals'].map(function(journals) {
      return '"'+journals+'"[ta]'
    });

    var pubTypes = this.appliedFilters['pubType'].map(function(pubType) {
      return '"'+pubType+'"[pt]'
    });

    var countries = this.appliedFilters['countries'].map(function(countries) {
      return '"'+countries+'"[pl]'
    });

    // var publishYears = this.appliedFilters['publishYears'].map(function(publishYears) {
    //   return '"'+publishYears+'"[dp]'
    // });
    
    if(journals.length > 0) {
      this.esearchTerm += "("+journals.join('+OR+')+")"
    }

    // if(publishYears.length > 0) {
    //   if(this.esearchTerm != '') this.esearchTerm += 'AND'
    //   this.esearchTerm += "("+publishYears.join('+OR+')+")"
    // }

    if(pubTypes.length > 0) {
      if(this.esearchTerm != '') this.esearchTerm += 'AND'
      this.esearchTerm += "("+pubTypes.join('+OR+')+")"
    }

    if(countries.length > 0) {
      if(this.esearchTerm != '') this.esearchTerm += 'AND'
      this.esearchTerm += "("+countries.join('+OR+')+")"
    }

    let targetFilter = "("+this.appliedFilters['targets'].map(function(target) {
      return "("+target+")"
    }).join('+OR+')+")"

    if(targetFilter != '') {
      if(this.esearchTerm != '') this.esearchTerm += 'AND'
      this.esearchTerm += "(" +targetFilter+")"
    }


    if(this.searchKeyword != '') {
      if(this.esearchTerm != '') this.esearchTerm += '+AND+'

      // let searchTerm = this.searchKeyword.replace(/'/g, "\\'").replace(/"/g, '\\"')
      let searchTerm = this.searchKeyword

      if(this.searchBy == 'abstract') {
        searchTerm = searchTerm.trim()
        let searchTermIndex = (searchTerm.indexOf('\n') != -1) ? searchTerm.indexOf('\n') : (searchTerm.length)
        searchTerm = searchTerm.substr(0, searchTermIndex)+'[TIAB]'
      }

      if(this.searchBy == 'journal') {
        searchTerm = searchTerm.trim()
        let searchTermIndex = (searchTerm.indexOf('\n') != -1) ? searchTerm.indexOf('\n') : (searchTerm.length)
        searchTerm = ''+searchTerm.substr(0, searchTermIndex)+'[ta]'
      }

      this.esearchTerm += searchTerm
    }

    let queryStartsAt = new Date().getTime()

    let similarArticles = []

    if(this.searchBy == 'abstract') {
      let publicationsList = {}

      this.userService.eSearchforTerm(this.esearchTerm, this.sortBy, this.selectedPage, this.publishYearFilter).subscribe(
        result => {
  
          let uIds = result.esearchresult.idlist.join(',')
          this.userService.eFetchForUID(uIds).subscribe(
            result => {
              var parseString = require('xml2js').parseString;
              parseString(result, (err, publications) => {

                this.loadingExactMatch = false

                this.loadingSearchResult = false;
                this.updateIdeasListForPublications(publications, true)
                
                setTimeout(() => {

                  this.userService.getCommonWords().subscribe(
                    commonWords => {
                      let keywordList = []

                      let wordCount = {}

                      let abstractContent = ''
                      abstractContent = this.searchKeyword
                      abstractContent.split(' ').forEach(word => {
                        word = word.replace(/,/g, '').replace(/!/g, '').replace('.', '').toLowerCase()
                        if(wordCount[word]) wordCount[word] += 1
                        else wordCount[word] = 1
                      });

                      let sortable = []
                      for (var word in wordCount) {
                        sortable.push([word, wordCount[word]]);
                      }
                      
                      sortable.sort(function(a, b) {
                        return b[1] - a[1];
                      });

                      let keywordCount = 0;
                      sortable.forEach(word => {
                        let keyword = {}
                        if(keywordCount < 10) {
                          if(commonWords.indexOf(word[0].toLowerCase()) == -1) {
                            // if(keyword != '') keyword += '+OR+'
                            keyword['_id'] = word[0]
                            keyword['value'] = word[1]

                            if(keywordCount < 3) {
                              keyword['checked'] = true
                            } else {
                              keyword['checked'] = false
                            }

                            keywordList.push(keyword)
                            keywordCount++
                          }
                        }
                      });

                      let pubmedSearchKeyword = keywordList.slice(0,3).map(function(keyword, index) { return keyword._id}).join('+AND+')

                      this.relevantResultKeywords = pubmedSearchKeyword.replace(/\+/g, " "); 
                      this.userService.eSearchforTerm(pubmedSearchKeyword, this.sortBy, this.selectedPage, this.publishYearFilter).subscribe(
                        result => {
                          this.totalRecordsInDb = result.esearchresult.count
                          this.allIdeasCount = result.esearchresult.count
                          this.targetedIdeasCount = result.esearchresult.count

                          this.filteredRecords = result.esearchresult.count
                          this.totalPages = Math.ceil(this.filteredRecords / 10)
                          
                          let uIds = result.esearchresult.idlist.join(',')
                          this.userService.eFetchForUID(uIds).subscribe(
                            result => {
                              var parseString = require('xml2js').parseString;
                              parseString(result, (err, publications) => {

                                if(publications.PubmedArticleSet && publications.PubmedArticleSet.PubmedArticle) {
                                  if(!publicationsList['PubmedArticleSet']) {
                                    publicationsList = publications
                                  } else {
                                    publicationsList['PubmedArticleSet']['PubmedArticle'].concat(publications.PubmedArticleSet.PubmedArticle)
                                  }
                                }

                                this.loadingSearchResult = false
                                
                                this.updateIdeasListForPublications(publicationsList, false)
                                this.loadPagination()
                              })
                            }
                          )
                        }
                      )
                    }
                  )
                }, 2000)

              });
            }
          )
        }
      )

    } else {
      this.relevantResultKeywords = ''

      if(this.searchBy == 'journal') {
        this.sortBy = 'relevance';
      }

      this.userService.eSearchforTerm(this.esearchTerm, this.sortBy, this.selectedPage, this.publishYearFilter).subscribe(
        result => {
          this.totalRecordsInDb = result.esearchresult.count
          this.allIdeasCount = result.esearchresult.count
          this.targetedIdeasCount = result.esearchresult.count
      
          this.filteredRecords = result.esearchresult.count
          this.totalPages = Math.ceil(result.esearchresult.count / 10)
  
  
          let uIds = result.esearchresult.idlist.join(',')
          this.userService.eFetchForUID(uIds).subscribe(
            result => {
              var parseString = require('xml2js').parseString;
              parseString(result, function (err, publications) {

                this.loadingSearchResult = false

                this.updateIdeasListForPublications(publications, false)
                this.loadPagination()
              }.bind(this));
            }
          )
        }
      )
    }

    let esearchTermForFilters = ''
    if(targetFilter != '') {
      esearchTermForFilters += "(" +targetFilter+")"
    }


    if(this.searchKeyword != '') {
      if(esearchTermForFilters != '') esearchTermForFilters += '+AND+'

      // let searchTerm = this.searchKeyword.replace(/'/g, "\\'").replace(/"/g, '\\"')
      let searchTerm = this.searchKeyword

      if(this.searchBy == 'abstract') {
        searchTerm = searchTerm.trim()
        let searchTermIndex = (searchTerm.indexOf('\n') != -1) ? searchTerm.indexOf('\n') : (searchTerm.length)
        searchTerm = searchTerm.substr(0, searchTermIndex)+'[TIAB]'
      }

      if(this.searchBy == 'journal') {
        searchTerm = searchTerm.trim()
        let searchTermIndex = (searchTerm.indexOf('\n') != -1) ? searchTerm.indexOf('\n') : (searchTerm.length)
        searchTerm = ''+searchTerm.substr(0, searchTermIndex)+'[ta]'
      }

      esearchTermForFilters += searchTerm
    }

    // setTimeout(() => {
    //   this.userService.eSearchforTerm(esearchTermForFilters, this.sortBy, this.selectedPage, this.publishYearFilter).subscribe(
    //     result => {
    //       let uIds = result.esearchresult.idlist.join(',')
    //       this.userService.eFetchForUID(uIds).subscribe(
    //         result => {
    //           var parseString = require('xml2js').parseString;
    //           parseString(result, function (err, publications) {
    //             this.loadFiltesForPublications(publications)
    //           }.bind(this));
    //         }
    //       )
    //     }
    //   )
    // }, 1000);
    
  }

  loadFiltesForPublications(abstracts, isExactMatch) {
    this.journals = []
    this.countries = []

    if(abstracts.PubmedArticleSet && abstracts.PubmedArticleSet.PubmedArticle) {
      abstracts.PubmedArticleSet.PubmedArticle.forEach(abstract => {
        let abstractText = {}

        if(abstract.MedlineCitation[0].Article[0].Abstract) {
          abstract.MedlineCitation[0].Article[0].Abstract[0].AbstractText.forEach((abstract, index) => {
            if(abstract['_']) {
              if(abstract['$'] && abstract['$']['Label']) {
                abstractText[index+'_'+abstract['$']['Label']] = abstract['_']
              } else {
                abstractText[index+'_Abstract'] = abstract['_']
              }
            } else {
              abstractText['0_Abstract'] = abstract
            }
          });
        } else {
          abstractText = {
            '0_Abstract' : 'Empty'
          }
        }

        
        let publication = {
          journal: abstract.MedlineCitation[0].Article[0].Journal[0].Title[0],
          country: abstract.MedlineCitation[0].MedlineJournalInfo[0].Country,
        }

        if(this.journals.indexOf(publication.journal) == -1){
          this.journals.push(publication.journal)
        }

        if(this.countries.indexOf(publication.country[0]) == -1){
          this.countries.push(publication.country[0])
        }
        
        // console.log(this.appliedFilters)
        this.isSelectAllChecked['PUBTYPE'] = this.publicationTypes.every(pubType => {
          return (this.appliedFilters['pubType'].indexOf(pubType) != -1)
        })
        this.isSelectAllChecked['JOURNAL'] = this.journals.every(journal => {
          return (this.appliedFilters['journals'].indexOf(journal) != -1)
        })
        this.isSelectAllChecked['MY_PREF_JOURNAL'] = this.user.member[0].prefJournal.every(journal => {
          return (this.appliedFilters['journals'].indexOf(journal) != -1)
        })
        this.memberTargets.forEach((member, memberIndex) => {
          if(member.prefJournal) {
            this.isSelectAllChecked['MEM_PREF_JOURNAL_'+memberIndex] = member.prefJournal.every(journal => {
              return (this.appliedFilters['journals'].indexOf(journal) != -1)
            })
          }
        });

        this.isSelectAllChecked['countries'] = this.countries.every(country => {
          var countryName = (country.key) ? country.key : country;
          return (this.appliedFilters['countries'].indexOf(countryName) != -1)
        })
      });
    }
  }

  updateIdeasListForPublications(abstracts, isExactMatch) {

    if(!isExactMatch) {
      this.publications = []
      this.journals = []
      this.countries = []
    }

    if(abstracts.PubmedArticleSet && abstracts.PubmedArticleSet.PubmedArticle) {
      abstracts.PubmedArticleSet.PubmedArticle.forEach(abstract => {
        let abstractText = {}
        let shortAbstractText = ''

        if(abstract.MedlineCitation[0].Article[0].Abstract) {
          abstract.MedlineCitation[0].Article[0].Abstract[0].AbstractText.forEach((abstract, index) => {
            if(abstract['_']) {
              if(abstract['$'] && abstract['$']['Label']) {
                abstractText[index+'_'+abstract['$']['Label']] = abstract['_']
              } else {
                abstractText[index+'_Abstract'] = abstract['_']
              }
            } else {
              abstractText['0_Abstract'] = abstract
            }
          });
        } else {
          abstractText = {
            '0_Abstract' : 'Empty'
          }
        }

        Object.keys(abstractText).forEach((abstractTitle) => {
          if(shortAbstractText != '') shortAbstractText +=  '<br/>'
          shortAbstractText += '<strong>'+abstractTitle.substr(2) + ':</strong> ' + abstractText[abstractTitle]  
        });
        
        
        let publication = {
          title: (abstract.MedlineCitation[0].Article[0].ArticleTitle[0]['_']) ? abstract.MedlineCitation[0].Article[0].ArticleTitle[0]['_'] : abstract.MedlineCitation[0].Article[0].ArticleTitle[0],
          abstract: JSON.stringify(abstractText),
          shortAbstractText: shortAbstractText,
          publishedOn: (abstract.MedlineCitation[0].Article[0].ArticleDate) ? abstract.MedlineCitation[0].Article[0].ArticleDate[0].Year[0] + '-' + abstract.MedlineCitation[0].Article[0].ArticleDate[0].Month[0] + '-' + abstract.MedlineCitation[0].Article[0].ArticleDate[0].Day[0] : 'Not Available',
          researcher: (abstract.MedlineCitation[0].Article[0].AuthorList) ? (abstract.MedlineCitation[0].Article[0].AuthorList[0].Author[0].LastName + ' ' + abstract.MedlineCitation[0].Article[0].AuthorList[0].Author[0].ForeName + ' ' + abstract.MedlineCitation[0].Article[0].AuthorList[0].Author[0].Initials) : 'No Author',
          journal: abstract.MedlineCitation[0].Article[0].Journal[0].Title[0],
          country: abstract.MedlineCitation[0].MedlineJournalInfo[0].Country,
          pmid: abstract.MedlineCitation[0].PMID[0]['_'],
          doi: (abstract.MedlineCitation[0].Article[0].ELocationID) ? abstract.MedlineCitation[0].Article[0].ELocationID[(abstract.MedlineCitation[0].Article[0].ELocationID.length - 1)]['_'] : null,
          isPrefJournal: this.hasPrefJournal(abstract.MedlineCitation[0].Article[0].Journal[0].Title[0]),
          pubType: (abstract.MedlineCitation[0].Article[0].PublicationTypeList[0].PublicationType[0]['_']) ? abstract.MedlineCitation[0].Article[0].PublicationTypeList[0].PublicationType[0]['_'] : abstract.MedlineCitation[0].Article[0].PublicationTypeList[0].PublicationType[0],
        }

        if(!isExactMatch) {
          this.publications.push(publication)
        } else {
          this.exactMatch = publication
          this.hasExactMatch = true
        }
  
        if(this.journals.indexOf(publication.journal) == -1){
          this.journals.push(publication.journal)
        }

        if(this.countries.indexOf(publication.country[0]) == -1){
          this.countries.push(publication.country[0])
        }

        // console.log(this.publications)
        this.isSelectAllChecked['PUBTYPE'] = this.publicationTypes.every(pubType => {
          return (this.appliedFilters['pubType'].indexOf(pubType) != -1)
        })
        this.isSelectAllChecked['JOURNAL'] = this.journals.every(journal => {
          return (this.appliedFilters['journals'].indexOf(journal) != -1)
        })
        this.isSelectAllChecked['MY_PREF_JOURNAL'] = this.user.member[0].prefJournal.every(journal => {
          return (this.appliedFilters['journals'].indexOf(journal) != -1)
        })
        this.memberTargets.forEach((member, memberIndex) => {
          if(member.prefJournal) {
            this.isSelectAllChecked['MEM_PREF_JOURNAL_'+memberIndex] = member.prefJournal.every(journal => {
              return (this.appliedFilters['journals'].indexOf(journal) != -1)
            })
          }
        });

        this.isSelectAllChecked['countries'] = this.countries.every(country => {
          var countryName = (country.key) ? country.key : country;
          return (this.appliedFilters['countries'].indexOf(countryName) != -1)
        })
  
        // if(abstract.MedlineCitation[0].Article[0].ArticleDate && this.publishYears.indexOf(abstract.MedlineCitation[0].Article[0].ArticleDate[0].Year[0]) == -1) {
        //   this.publishYears.push(abstract.MedlineCitation[0].Article[0].ArticleDate[0].Year[0])
        // } else if(abstract.MedlineCitation[0].DateRevised[0] && this.publishYears.indexOf(abstract.MedlineCitation[0].DateRevised[0].Year[0]) == -1) {
        //   // this.publishYears.push(abstract.MedlineCitation[0].DateRevised[0].Year[0])
        // }
      });

    } 
    
    if(abstracts.PubmedArticleSet && abstracts.PubmedArticleSet.PubmedBookArticle) {
      abstracts.PubmedArticleSet.PubmedBookArticle.forEach(abstract => {
        let abstractText = {}
        let shortAbstractText = ''

        if(abstract.BookDocument[0].Abstract) {
          abstract.BookDocument[0].Abstract[0].AbstractText.forEach((abstract, index) => {
            if(abstract['_']) {
              if(abstract['$'] && abstract['$']['Label']) {
                abstractText[index+'_'+abstract['$']['Label']] = abstract['_']
              } else {
                abstractText[index+'_Abstract'] = abstract['_']
              }
            } else {
              abstractText['0_Abstract'] = abstract
            }
          });
        } else {
          abstractText = {
            '0_Abstract' : 'Empty'
          }
        }

        Object.keys(abstractText).forEach((abstractTitle) => {
          if(shortAbstractText != '') shortAbstractText +=  '<br/>'
          shortAbstractText += '<strong>'+abstractTitle.substr(2) + ':</strong> ' + abstractText[abstractTitle]  
        });

        let publication = {
          title: (abstract.BookDocument[0].Book[0].BookTitle[0]['_']) ? abstract.BookDocument[0].Book[0].BookTitle[0]['_'] : abstract.BookDocument[0].Book[0].BookTitle[0],
          abstract: ((abstract.BookDocument[0].Abstract[0].AbstractText[0]['_']) ? abstract.BookDocument[0].Abstract[0].AbstractText[0]['_'] : abstract.BookDocument[0].Abstract[0].AbstractText[0]),
          shortAbstractText: shortAbstractText,
          publishedOn: (abstract.BookDocument[0].Book[0].PubDate) ? (abstract.BookDocument[0].Book[0].PubDate[0].Year[0] + ((abstract.BookDocument[0].Book[0].PubDate[0].Month) ? '-' + abstract.BookDocument[0].Book[0].PubDate[0].Month[0] : '' ) + ((abstract.BookDocument[0].Book[0].PubDate[0].Day) ? '-' + abstract.BookDocument[0].Book[0].PubDate[0].Day[0] : '')) : null,
          researcher: (abstract.BookDocument[0].Book[0].AuthorList) ? ((abstract.BookDocument[0].Book[0].AuthorList[0].Author[0].ForeName) ? (abstract.BookDocument[0].Book[0].AuthorList[0].Author[0].LastName + ' ' + abstract.BookDocument[0].Book[0].AuthorList[0].Author[0].ForeName + ' ' + abstract.BookDocument[0].Book[0].AuthorList[0].Author[0].Initials) : ((typeof abstract.BookDocument[0].AuthorList[0].Author[0].CollectiveName != 'undefined') ? abstract.BookDocument[0].AuthorList[0].Author[0].CollectiveName : 'Not Available')) : ((typeof abstract.BookDocument[0].AuthorList[0].Author[0].CollectiveName != 'undefined') ? abstract.BookDocument[0].AuthorList[0].Author[0].CollectiveName[0] : 'Not Available'),
          publisher: abstract.BookDocument[0].Book[0].Publisher[0].PublisherName[0],
          country: abstract.BookDocument[0].Book[0].Publisher[0].PublisherLocation[0],
          pmid: abstract.BookDocument[0].PMID[0]['_'],
          pubType: 'Book',
          doi: null
        }
        
        if(!isExactMatch) {
          this.publications.push(publication)
        } else {
          this.exactMatch = publication
          this.hasExactMatch = true
        }
  
        // if(this.journals.indexOf(publication.journal) == -1)
        //   this.journals.push(publication.journal)
  
        // if(abstract.BookDocument[0].Book[0].PubDate && this.publishYears.indexOf(abstract.BookDocument[0].Book[0].PubDate[0].Year[0]) == -1)
        //   this.publishYears.push(abstract.BookDocument[0].Book[0].PubDate[0].Year[0])
      });
    }

    if(isExactMatch && !abstracts.PubmedArticleSet) {
      this.similarArticilesHeading = 'Displaying most relevant results'
    } else if(isExactMatch) {
      this.similarArticilesHeading = 'Displaying most relevant results'
    }

    if(!isExactMatch) {
      this.showEmptyResultMsg = (this.publications.length == 0)
    } else {
      this.showEmptyMatchResult = true
    }

    this.loadingSearchResult = false

       
    if(document.querySelector('.popover')) {
      document.querySelector('.popover').remove()
    }

  }

  hasPrefJournal(journal) {
    let isExists = false

    if(this.user.member[0].prefJournal) {
      this.user.member[0].prefJournal.forEach(journalName => {
        if(journalName == journal) isExists = true
      });
    }

    return isExists;
  }

  addToPrefJournal(journalName, publicationIndex) {
    if(!this.user.member[0].prefJournals) {
      this.user.member[0].prefJournals = [] 
    }

    if(this.user.member[0].prefJournals && this.user.member[0].prefJournals.indexOf(journalName) == -1) {
      this.user.member[0].prefJournals.push(journalName)
      this.preferredJournals.push(journalName)
    }

    localStorage.setItem('user', JSON.stringify(this.user))

    this.userService.updatePrefJournal(this.user.member[0]._id, this.user.member[0].prefJournals).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        this.publications[publicationIndex].isPrefJournal = true
      }
    )
  }

  removeFromPrefJournal(journalName, publicationIndex) {
    if(this.user.member[0].prefJournals && this.user.member[0].prefJournals.indexOf(journalName) != -1) {
      this.user.member[0].prefJournals.splice(this.user.member[0].prefJournals.indexOf(journalName), 1)
      this.preferredJournals.splice(this.user.member[0].prefJournals.indexOf(journalName), 1)
    }

    localStorage.setItem('user', JSON.stringify(this.user))

    this.userService.updatePrefJournal(this.user.member[0]._id, this.user.member[0].prefJournals).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        this.publications[publicationIndex].isPrefJournal = false
      }
    )
  }

  loadIdeasOfPageForClinicalTrial(getRefinedByCount = false) {
    let filters = Object.assign({}, this.appliedFilters)
    filters['start'] = ((this.selectedPage - 1) * this.recordLength)
    filters['length'] = this.recordLength

    if(getRefinedByCount) filters['getRefinedByCount'] = true

    filters['userType'] = this.user.member[0].type
    filters['organization'] = this.user.organizationName

    // this.esearchTerm = ''
    // if(this.searchKeyword != '') this.esearchTerm = this.searchKeyword
    // } else {
      this.esearchTerm = ''

      var sponsors = this.appliedFilters['sponsors'].map(function(sponsors) {
        return 'AREA[LeadSponsorName]"'+sponsors.trim()+'"'
      });

      var organizations = this.appliedFilters['organizations'].map(function(organizations) {
        return 'AREA[ResponsiblePartyInvestigatorAffiliation]"'+organizations+'"'
      });

      var phases = this.appliedFilters['phases'].map(function(phases) {
        return 'AREA[Phase]"'+phases+'"'
      });
      
      if(sponsors.length > 0) {
        this.esearchTerm += "("+sponsors.join('+OR+')+")"
      }

      if(organizations.length > 0) {
        if(this.esearchTerm != '') this.esearchTerm += 'AND'
        this.esearchTerm += "("+organizations.join('+OR+')+")"
      }

      if(phases.length > 0) {
        if(this.esearchTerm != '') this.esearchTerm += 'AND'
        this.esearchTerm += "("+phases.join('+OR+')+")"
      }

      let targetFilter = "("+this.appliedFilters['targets'].map(function(target) {
        return "("+target+")"
      }).join('+OR+')+")"
  
      if(targetFilter != '()') {
        if(this.esearchTerm != '') this.esearchTerm += '+AND+'
        this.esearchTerm += "(" +targetFilter+")"
      }
      
      // if(this.appliedFilters['targets'].length > 0) {
      //   if(this.esearchTerm != '') this.esearchTerm += 'AND'
      //   if(this.filterType == 'all') this.esearchTerm += "("+this.appliedFilters['targets'].join('+AND+')+")"
      //   else this.esearchTerm += "("+this.appliedFilters['targets'].join('+OR+')+")"
      // }
    // }

    if(this.searchKeyword != '') {
      if(this.esearchTerm != '') this.esearchTerm += '+AND+'
      this.esearchTerm += '('
      if(this.searchBy == 'abstract') this.esearchTerm += '"'
      this.esearchTerm += this.searchKeyword
      if(this.searchBy == 'abstract') this.esearchTerm += '"'
      this.esearchTerm += ')'
    }

    let queryStartsAt = new Date().getTime()

    let similarArticles = []

    let start = ((this.selectedPage - 1) * 10) + 1
    let end = (this.selectedPage * 10)
    this.userService.getClinicalTrialsForTerm(this.esearchTerm, start, end).subscribe(
      result => {
        // if(result.status) {
          // this.sponsors = result.filters['sponsors']
          // this.organizations = result.filters['organizations']
          this.phases = ['Phase 1', 'Phase 2', 'Phase 3', 'Phase 4']

          if(this.searchBy == 'keyword') {
            this.loadingSearchResult = false
            this.updateIdeasListForClinicalTrials(result, false)
          }

          if(this.searchBy == 'keyword') {
            this.loadPagination()
            this.relevantResultKeywords = ''
          } else {
            this.userService.getCommonWords().subscribe(
              commonWords => {
                let keywordList = []
    
                let wordCount = {}
  
                let abstractContent = ''
                abstractContent = this.searchKeyword
  
                abstractContent.split(' ').forEach(word => {
                  word = word.replace(/,/g, '').replace(/!/g, '').replace('.', '').toLowerCase()
                  if(wordCount[word]) wordCount[word] += 1
                  else wordCount[word] = 1
                });
    
                let sortable = []
                for (var word in wordCount) {
                  sortable.push([word, wordCount[word]]);
                }
                
                sortable.sort(function(a, b) {
                  return b[1] - a[1];
                });
    
                let keywordCount = 0;
                sortable.forEach(word => {
                  let keyword = {}
                  if(keywordCount < 10) {
                    if(commonWords.indexOf(word[0].toLowerCase()) == -1) {
                      // if(keyword != '') keyword += '+OR+'
                      keyword['_id'] = word[0]
                      keyword['value'] = word[1]
  
                      if(keywordCount < 3) {
                        keyword['checked'] = true
                      } else {
                        keyword['checked'] = false
                      }
  
                      keywordList.push(keyword)
                      keywordCount++
                    }
                  }
                });
  
                let pubmedSearchKeyword = keywordList.slice(0,3).map(function(keyword, index) { return keyword._id}).join('+AND+')
  
                this.relevantResultKeywords = pubmedSearchKeyword.replace(/\+/g, " ");
                setTimeout(() => {
                  this.userService.getClinicalTrialsForTerm(pubmedSearchKeyword, start, end).subscribe(
                    result => {
                      // if(result.status) {
                        this.loadingSearchResult = false
                        this.updateIdeasListForClinicalTrials(result, false)
                        this.loadPagination()
                      // }
                    }
                  )
                }, 1500); 
              }
            )
          }
        // }
      }
    )

    let esearchTermForFilter = '';

    if(targetFilter != '()') {
      esearchTermForFilter += "(" +targetFilter+")"
    }

    if(this.searchKeyword != '') {
      if(esearchTermForFilter != '') esearchTermForFilter += '+AND+'
      esearchTermForFilter += '('
      if(this.searchBy == 'abstract') esearchTermForFilter += '"'
      esearchTermForFilter += this.searchKeyword
      if(this.searchBy == 'abstract') esearchTermForFilter += '"'
      esearchTermForFilter += ')'
    }

    setTimeout(() => {
      // If Other filters to be ignored use "esearchTermForFilter" as first param
      this.userService.getClinicalTrialsFilters(this.esearchTerm, start, end).subscribe(
        result => {
          let filters = {
            sponsors: [],
            organizations: [],
            phases: []
          }
  
          try {
            // console.log(esearchTermForFilter);
            // console.log(result)
            if(result['StudyFieldsResponse']['StudyFields']) {
              result['StudyFieldsResponse']['StudyFields'].forEach(filterParams => {
                if(filterParams['LeadSponsorName'][0] && filterParams['LeadSponsorName'][0] != '' && filters['sponsors'].indexOf(filterParams['LeadSponsorName'][0]) == -1) {
                  filters['sponsors'].push(filterParams['LeadSponsorName'][0])
                }
                if(filterParams['ResponsiblePartyInvestigatorAffiliation'][0] && filterParams['ResponsiblePartyInvestigatorAffiliation'][0] != '' && filters['organizations'].indexOf(filterParams['ResponsiblePartyInvestigatorAffiliation'][0]) == -1) {
                  filters['organizations'].push(filterParams['ResponsiblePartyInvestigatorAffiliation'][0])
                }
                // if(filterParams['Phase'][0] && filterParams['Phase'][0] != '' && filters['phases'].indexOf(filterParams['Phase'][0]) == -1) {
                //   filters['phases'].push(filterParams['Phase'][0])
                // }
              });
            }
  
            this.sponsors = filters['sponsors']
            this.organizations = filters['organizations']
            // this.phases = filters['phases']

            this.isSelectAllChecked['SPONSOR'] = this.sponsors.every(sponsor => {
              return (this.appliedFilters['sponsors'].indexOf(sponsor) != -1)
            })
            this.isSelectAllChecked['ORGANIZATION'] = this.organizations.every(organization => {
              return (this.appliedFilters['organizations'].indexOf(organization) != -1)
            })
            this.isSelectAllChecked['PHASE'] = this.phases.every(phase => {
              return (this.appliedFilters['phases'].indexOf(phase) != -1)
            })
          } catch(ex) {
            this.sponsors = []
            this.organizations = []
          }
        }
      )
    }, 1000);
  }

  updateIdeasListForClinicalTrials(clinicalTrials, isExactMatch) {

    if(!isExactMatch) {
      this.totalRecordsInDb = clinicalTrials.StudyFieldsResponse.NStudiesFound
      this.allIdeasCount = clinicalTrials.StudyFieldsResponse.NStudiesFound
      this.targetedIdeasCount = clinicalTrials.StudyFieldsResponse.NStudiesFound
  
      this.totalPages = Math.ceil(clinicalTrials.StudyFieldsResponse.NStudiesFound / 10)
      this.clinicalTrials = clinicalTrials.StudyFieldsResponse.StudyFields
      
      this.showEmptyResultMsg = (!this.clinicalTrials);

      this.filteredRecords = (this.clinicalTrials) ? this.clinicalTrials.length : 0
      
      if(document.querySelector('.popover')) {
        document.querySelector('.popover').remove()
      }
    } else {
      if(clinicalTrials.StudyFieldsResponse && clinicalTrials.StudyFieldsResponse.StudyFields && clinicalTrials.StudyFieldsResponse.StudyFields[0]) {
        this.hasExactMatch = true
        this.exactMatch = clinicalTrials.StudyFieldsResponse.StudyFields[0]
        this.similarArticilesHeading = 'Displaying most relevant results'
      } else {
        this.hasExactMatch = false
        this.similarArticilesHeading = 'Displaying most relevant results'
      }
      this.showEmptyMatchResult = true
    }
    this.loadingSearchResult = false
  }

  loadIdeasOfPageForNews(getRefinedByCount = false) {
    let filters = Object.assign({}, this.appliedFilters)
    filters['start'] = ((this.selectedPage - 1) * this.recordLength)
    filters['length'] = this.recordLength

    if(getRefinedByCount) filters['getRefinedByCount'] = true

    filters['userType'] = this.user.member[0].type
    filters['organization'] = this.user.organizationName

    this.esearchTerm = ''
    if(this.searchKeyword != '') {
      this.esearchTerm = this.searchKeyword
    } else {
      this.esearchTerm = ''

      if(this.appliedFilters['targets'].length > 0) {
        this.esearchTerm = this.appliedFilters['targets'].map(function(value) {
          return '('+value+')'
        }).join('+OR+')
      }
    }

    let queryStartsAt = new Date().getTime()

    let similarArticles = []

    this.userService.getNews(this.esearchTerm).subscribe(
      result => {
        var parseString = require('xml2js').parseString;
        parseString(result.feeds, function (err, news) {
          this.updateIdeasListForNews(news)
          this.loadPagination()
        }.bind(this));
      }
    )
  }

  updateIdeasListForNews(news) {

    this.totalRecordsInDb = (news.rss.channel[0].item) ? news.rss.channel[0].item.length : 0
    this.allIdeasCount = (news.rss.channel[0].item) ? news.rss.channel[0].item.length : 0
    this.targetedIdeasCount = (news.rss.channel[0].item) ? news.rss.channel[0].item.length : 0

    this.totalPages = Math.ceil(((news.rss.channel[0].item) ? news.rss.channel[0].item.length : 0) / 10)

    if(news.rss.channel[0].item) {
      this.news = news.rss.channel[0].item.slice(((this.selectedPage - 1) * 10), (this.selectedPage * 10))
    } else {
      this.news = []
    }

    this.filteredRecords = this.news.length

    this.showEmptyResultMsg = (this.news.length == 0)
    this.loadingSearchResult = false

    if(document.querySelector('.popover')) {
      document.querySelector('.popover').remove()
    }
  }

  getCountriesList() {
    this.userService.getCountryList().subscribe(
      countries => {
        this.countriesList = countries
        this.countriesList.unshift({name:'Choose...', code: ''})
      }
    )
  }

  navigateToPage(selectedPage) {
    if(selectedPage == '...') return
    this.loadingSearchResult = true;
    this.selectedPage = selectedPage
    this.loadIdeasOfPage()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  navigateToNexPage() {
    this.loadingSearchResult = true;
    this.selectedPage = (this.selectedPage != this.totalPages) ? this.selectedPage + 1 : this.selectedPage
    this.loadIdeasOfPage()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  navigateToPreviousPage() {
    this.loadingSearchResult = true;
    this.selectedPage = (this.selectedPage != 1) ? this.selectedPage - 1 : this.selectedPage
    this.loadIdeasOfPage()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  viewAbstract(content) {
    let contentParam = content
    contentParam['appliedFilters'] = JSON.stringify(this.appliedFilters)
    contentParam['searchKeyword'] = this.searchKeyword
    contentParam['totalRecordsInDb'] = this.totalRecordsInDb
    contentParam['browseBy'] = this.browseBy
    contentParam['pageNo'] = this.selectedPage
    contentParam['searchBy'] = this.searchBy

    if(this.currentPage == 'publications' || this.myspaceIdeaTypeFilter == 'publications') contentParam['fromPublication'] = true

    if(this.currentPage == 'news' || this.myspaceIdeaTypeFilter == 'news') {
      contentParam['source'] = (content['source'][0]['_']) ? "<a target='_blank' href='"+content['source'][0]['$'].url+"'>"+content['source'][0]['_']+"" : content['source'][0]
      contentParam['fromNews'] = true
    }

    if(this.currentPage == 'clinical-trials' || this.myspaceIdeaTypeFilter == 'clinicaltrials') contentParam['fromClinicalTrials'] = true

    if(this.currentPage == 'myspace') contentParam['fromMySpace'] = true

    contentParam['selectedMySpaceTarget'] = this.selectedMySpaceTarget

    let navigationExtras: NavigationExtras = {
        queryParams: contentParam, skipLocationChange: true
    };
    this.router.navigate(['/view-abstract'], navigationExtras)
  }

  loadSavedIdeas() {
    if(this.currentTab != 'savedAbstracts') this.selectedPage = 1;
    this.currentTab = 'savedAbstracts'
    this.searchKeyword = ''
    let queryStartsAt = new Date().getTime(),
    start = ((this.selectedPage - 1) * this.recordLength),
    limit = this.recordLength

    this.browseBy = 'saved'

    this.userService.getSavedAbstracts(this.user._id, start, limit).subscribe(
      abstracts => {
            
        if(abstracts.success || abstracts.success == false) this.sessionTimeOut()

        this.updateIdeasListForIdeas(abstracts, false)
        this.loadingSearchResult = false
        this.totalPages = Math.ceil(this.user.savedAbstracts.length / this.recordLength)
        this.selectedPage = 1;
        this.loadPagination()
      }
    )
  }

  loadClaimedIdeas() {
    if(this.currentTab != 'claimedAbstracts') this.selectedPage = 1;
    this.currentTab = 'claimedAbstracts'
    this.searchKeyword = ''
    let queryStartsAt = new Date().getTime(),
    start = ((this.selectedPage - 1) * this.recordLength),
    limit = this.recordLength

    this.browseBy = 'claimed'

    this.userService.getClaimedAbstracts(this.user._id, start, limit).subscribe(
      abstracts => {
            
        if(abstracts.success || abstracts.success == false) this.sessionTimeOut()

        this.updateIdeasListForIdeas(abstracts, false)
        this.loadingSearchResult = false
        this.totalPages = Math.ceil(this.user.claimedAbstracts.length / this.recordLength)
        this.selectedPage = 1;
        this.loadPagination()
      }
    )
  }

  updateCategory(category) {
    if(this.currentTab != 'filteredAbstracts') this.selectedPage = 1;
    this.currentTab = 'filteredAbstracts'
    if(category != 'targets') {
      this.searchKeyword = ''
      localStorage.removeItem('searchKeyword')
    }
    this.category = category
    this.browseBy = category
    if(category == 'all') {
      this.searchBy = 'keyword'
    }
    this.loadIdeasOfPage((category != 'all'))
  }

  loadManageOrganization() {
    this.userService.getMemberById(this.user._id).subscribe(
      (organization) => {
        this.activeTabs = []
        this.memberDetailsModal = 'Update member details'
        this.organizationData = organization
        this.organizationData.member[0].confirmEmail = this.organizationData.member[0].email
        this.selectTab('organization')
      }
    )
    
    $(document).on('click', '.caret', function() {
      this.parentElement.querySelector(".nested").classList.toggle("active");
      this.classList.toggle("caret-down");
    })

    var popOverSettings = {
      placement: 'right',
      trigger: 'hover',
      html: true,
      selector: '[data-toggle="popover"]'
    }
    
    $('body').popover(popOverSettings);
  }

  selectTab(tabName) {
    this.selectedTab = tabName;
  }

  addNewMember() {
    this.organizationData.member.push({
      type: 'Subordinate'
    })
    this.addActiveTab((this.organizationData.member.length - 1))
  }

  deleteMember(memberIndex) {
    this.organizationData.member.splice(memberIndex, 1)
    let activeTabIndex = this.activeTabs.indexOf((memberIndex - 1))
    this.activeTabs.splice(activeTabIndex, 1)
    this.selectTab('organization')
  }

  addActiveTab(tabId) {
    if(this.activeTabs.indexOf(tabId) == -1) {
      this.activeTabs.push(tabId)
    }
    this.selectedTab = tabId
  }

  closeActiveTab(tabId) {
    this.activeTabs.splice(this.activeTabs.indexOf(tabId), 1)
    this.selectTab('organization')
  }

  saveMember() {
    // console.log(this.organizationData)
    this.userService.saveMember(this.organizationData).subscribe(
      data => {
        if(data.status) {
          let alertMessage = 'Member Details has been updated successfully!'
          if(!this.organizationData._id) alertMessage = 'New Member is added successfully!'
          swal({
            title: alertMessage,
            type: 'success',
            confirmButtonText: 'Okay'
          })

          this.user.target = this.organizationData.target

          localStorage.setItem('user', JSON.stringify(this.user))

          this.userService.getMemberTargets(this.user._id).subscribe(
            (memberTargets) => {
              this.memberTargets = memberTargets[0].member.slice(1)
              // this.memberTargets.forEach((member, memberIndex) => {
              //   this.memberTargetSelecteAll[memberIndex] = (localStorage.getItem('searchKeyword') == null)
              //   if(member.target) {
              //     member.target.forEach(target => {
              //       this.appliedFilters['targets'].push(target.booleanSearch)
              //       this.showClearAll['MT_'+memberIndex+'_'+target.title] = (localStorage.getItem('searchKeyword') == null)
              //     });
              //   }
              // });

              // console.log(this.isChecked);
              this.appliedFilters['targets'] = []
              Object.keys(this.isChecked).forEach(filterKey => {
                if(this.isChecked[filterKey]) {
                  if(filterKey.indexOf('ORG_TAR_') == 0) {
                    var orgTargetIndex = filterKey.substr((filterKey.lastIndexOf('_') + 1))
                    this.appliedFilters['targets'].push(this.user.target[orgTargetIndex].booleanSearch)
                  } else if(filterKey.indexOf('MEM_TAR_') == 0) {
                    var targetKeySeparated = filterKey.split('_');
                    var memberIndex = targetKeySeparated[targetKeySeparated.length - 2]
                    var memberTargetIndex = targetKeySeparated[targetKeySeparated.length - 1]
                    this.appliedFilters['targets'].push(this.memberTargets[memberIndex].target[memberTargetIndex].booleanSearch)
                  }
                }
              });
              this.loadIdeasOfPage(true)
          })

          this.closeSetupOrganizationModel.nativeElement.click();
        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

  addNewTarget() {
    this.user.target.push({})
    this.organizationData.target.push({})
  }

  addNewMemberTarget(memberIndex) {
    let fromManageModal = (memberIndex != null)
    if(memberIndex == null) memberIndex = this.selectedMember
    
    if(!this.organizationData.member[memberIndex]['target']) {
      this.organizationData.member[memberIndex]['target'] = []
      this.memberTargets[(fromManageModal) ? (memberIndex - 1) : memberIndex] = {
        'target': []
      }
    }
    this.organizationData.member[memberIndex]['target'].push({})
    this.memberTargets[(fromManageModal) ? (memberIndex - 1) : memberIndex].target.push({})
  }

  deleteTarget(targetIndex) {
    this.user.target.splice(targetIndex, 1)
    this.organizationData.target.splice(targetIndex, 1)
  }

  deleteMemberTarget(memberIndex, targetIndex) {
    let fromManageModal = (memberIndex != null)
    if(memberIndex == null) memberIndex = this.selectedMember
    if(this.organizationData.member[memberIndex]['target']) this.organizationData.member[memberIndex]['target'].splice(targetIndex, 1)

    if(fromManageModal) memberIndex = memberIndex - 1
    this.memberTargets[memberIndex].target.splice(targetIndex, 1)
  }

  saveTargets() {
    this.userService.saveTargets(this.user).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        
        if(response.status) localStorage.setItem('user', JSON.stringify(this.user))

        this.selectedPage = 1
        this.loadIdeasOfPage(true)
        this.editTargetsClose.nativeElement.click();
      }
    )
  }

  saveMemberTargets() {
    this.userService.saveMemberTargets(this.memberTargets[this.selectedMember]._id, this.memberTargets[this.selectedMember].target).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        
        if(response.status) localStorage.setItem('user', JSON.stringify(this.user))

        this.appliedFilters['targets'] = []
        Object.keys(this.isChecked).forEach(filterKey => {
          if(this.isChecked[filterKey]) {
            if(filterKey.indexOf('ORG_TAR_') == 0) {
              var orgTargetIndex = filterKey.substr((filterKey.lastIndexOf('_') + 1))
              this.appliedFilters['targets'].push(this.user.target[orgTargetIndex].booleanSearch)
            } else if(filterKey.indexOf('MEM_TAR_') == 0) {
              var targetKeySeparated = filterKey.split('_');
              var memberIndex = targetKeySeparated[targetKeySeparated.length - 2]
              var memberTargetIndex = targetKeySeparated[targetKeySeparated.length - 1]
              this.appliedFilters['targets'].push(this.memberTargets[memberIndex].target[memberTargetIndex].booleanSearch)
            }
          }
        });
        this.selectedPage = 1
        this.loadIdeasOfPage(true)

        

        this.userService.getMemberById(this.user._id).subscribe(
          (organization) => {
            this.activeTabs = []
            this.memberDetailsModal = 'Update member details'
            this.organizationData = organization
            this.organizationData.member[0].confirmEmail = this.organizationData.member[0].email
            this.selectTab('organization')
          }
        )

        this.editMemberTargetsClose.nativeElement.click()
      }
    )
  }

  selectedIdea(ideaType, ideas) {
    this.mySpaceIdeaType = ideaType
    this.selectedMySpaceIdeas = ideas
  }

  saveToMySpace() {
    let memberId = this.myspaceTarget.split('_')[0]
    let targetId = this.myspaceTarget.split('_')[1]
    console.log(this.selectedMySpaceIdeas, this.mySpaceIdeaType)
    this.userService.saveToMySpace(this.user.member[0]._id, this.mySpaceIdeaType, memberId, targetId, JSON.stringify(this.selectedMySpaceIdeas)).subscribe(
      data => {
        if(!data.status) {
          swal("Error!", "Abstract has not been saved!", "error");
          this.sessionTimeOut()
        } else {
          this.loadMySpaceIdeas()
          // this.myspaceIdeas['ideas'].push(this.selectedMySpaceIdeas._id)
          this.myspaceTarget = '';
          this.closeSaveToMySpace.nativeElement.click();
          swal("Success!", "Abstract has been saved!", "success");
        }
      }
    )
  }

  unsaveFromMySpace(ideaType, targetId) {
    this.userService.unsaveFromMySpace(this.user.member[0]._id, ideaType, targetId).subscribe(
      data => {
        if(data.status) {
          swal("Error!", "Abstract has not been removed from My Space!", "error");
          // this.sessionTimeOut()
        } else {
          this.loadMySpaceIdeas()
          swal("Success!", "Abstract has been removed from My Space!", "success");
        }
      }
    )
  }

  selectMySpaceIdeaTypeFilter(ideaType) {
    this.myspaceIdeaTypeFilter = ideaType
    this.filterMySpaceIdeas()
  }

  getMySpaceIdeasCountByTarget() {
    let selectedMemberIndex = this.selectedMySpaceTarget.split('_')[0]
    let selectedTargetIndex = this.selectedMySpaceTarget.split('_')[1]

    let selectedMemberId = ''
    let selectedTargetId = ''

    if(selectedMemberIndex == '') {
      selectedTargetId = this.user.target[selectedTargetIndex]._id
    } else {
      selectedMemberId = this.memberTargets[selectedMemberIndex]._id
      selectedTargetId = this.memberTargets[selectedMemberIndex].target[selectedTargetIndex]._id
    }

    this.myspaceIdeasCount = {
      "ideas": 0,
      "publications": 0,
      "clinicaltrials": 0,
      "news": 0,
      "pipelines": 0
    }

    this.myspace['ideas'].forEach((ideas, ideaIndex) => {
      if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
        this.myspaceIdeasCount['ideas'] = this.myspace['ideas'][ideaIndex]['ideas'].length
      }
    });
    this.myspace['publications'].forEach((ideas, ideaIndex) => {
      if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
        this.myspaceIdeasCount['publications'] = this.myspace['publications'][ideaIndex]['ideas'].length
      }
    });
    this.myspace['clinicaltrials'].forEach((ideas, ideaIndex) => {
      if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
        this.myspaceIdeasCount['clinicaltrials'] = this.myspace['clinicaltrials'][ideaIndex]['ideas'].length
      }
    });
    this.myspace['news'].forEach((ideas, ideaIndex) => {
      if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
        this.myspaceIdeasCount['news'] = this.myspace['news'][ideaIndex]['ideas'].length
      }
    });
    this.myspace['pipelines'].forEach((ideas, ideaIndex) => {
      if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
        this.myspaceIdeasCount['pipelines'] = this.myspace['pipelines'][ideaIndex]['ideas'].length
      }
    });
    console.log(this.myspaceIdeasCount)
  }

  filterMySpaceIdeas() {
    this.getMySpaceIdeasCountByTarget()

    let selectedMemberIndex = this.selectedMySpaceTarget.split('_')[0]
    let selectedTargetIndex = this.selectedMySpaceTarget.split('_')[1]

    let selectedMemberId = ''
    let selectedTargetId = ''

    if(selectedMemberIndex == '') {
      selectedTargetId = this.user.target[selectedTargetIndex]._id
    } else {
      selectedMemberId = this.memberTargets[selectedMemberIndex]._id
      selectedTargetId = this.memberTargets[selectedMemberIndex].target[selectedTargetIndex]._id
    }
    console.log(selectedMemberIndex, selectedTargetIndex, this.selectedMySpaceTarget)
    console.log(selectedMemberId, selectedTargetId)
    this.abstracts = []
    this.publications = []
    this.clinicalTrials = []
    this.news = []
    let matchFound = false

    if(this.myspaceIdeaTypeFilter == 'ideas') {
      this.myspace['ideas'].forEach((ideas, ideaIndex) => {
        if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
          this.abstracts = this.myspace['ideas'][ideaIndex]['ideas'].map(idea => JSON.parse(idea))
          console.log(this.abstracts)
  
          this.totalPages = Math.ceil(this.abstracts.length / 10)
          this.loadPagination()
          if(this.abstracts.length == 0) {
            this.showEmptyResultMsg = true
            this.loadingSearchResult = false
          } else {
            this.showEmptyResultMsg = false
            this.loadingSearchResult = false
            matchFound = true
          }
        }
  
        if(!matchFound) {
          this.showEmptyResultMsg = true
          this.loadingSearchResult = false
        }
  
        console.log(matchFound, this.showEmptyResultMsg, this.loadingSearchResult)
      });
    } else if(this.myspaceIdeaTypeFilter == 'publications') {
      this.myspace['publications'].forEach((ideas, ideaIndex) => {
        if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
          this.publications = this.myspace['publications'][ideaIndex]['ideas'].map(idea => JSON.parse(idea))
          console.log(this.publications)
  
          this.totalPages = Math.ceil(this.publications.length / 10)
          this.loadPagination()
          if(this.publications.length == 0) {
            this.showEmptyResultMsg = true
            this.loadingSearchResult = false
          } else {
            this.showEmptyResultMsg = false
            this.loadingSearchResult = false
            matchFound = true
          }
        }
  
        if(!matchFound) {
          this.showEmptyResultMsg = true
          this.loadingSearchResult = false
        }
  
        console.log(matchFound, this.showEmptyResultMsg, this.loadingSearchResult)
      });
    } else if(this.myspaceIdeaTypeFilter == 'clinicaltrials') { 
      this.myspace['clinicaltrials'].forEach((ideas, ideaIndex) => {
        if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
          this.clinicalTrials = this.myspace['clinicaltrials'][ideaIndex]['ideas'].map(idea => JSON.parse(idea))
          console.log(this.clinicalTrials)
  
          this.totalPages = Math.ceil(this.clinicalTrials.length / 10)
          this.loadPagination()
          if(this.clinicalTrials.length == 0) {
            this.showEmptyResultMsg = true
            this.loadingSearchResult = false
          } else {
            this.showEmptyResultMsg = false
            this.loadingSearchResult = false
            matchFound = true
          }
        }
  
        if(!matchFound) {
          this.showEmptyResultMsg = true
          this.loadingSearchResult = false
        }
  
        console.log(matchFound, this.showEmptyResultMsg, this.loadingSearchResult)
      });
    } else if(this.myspaceIdeaTypeFilter == 'news') {
      this.myspace['news'].forEach((ideas, ideaIndex) => {
        if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
          this.news = this.myspace['news'][ideaIndex]['ideas'].map(idea => JSON.parse(idea))
          console.log(this.news)
  
          this.totalPages = Math.ceil(this.news.length / 10)
          this.loadPagination()
          if(this.news.length == 0) {
            this.showEmptyResultMsg = true
            this.loadingSearchResult = false
          } else {
            this.showEmptyResultMsg = false
            this.loadingSearchResult = false
            matchFound = true
          }
        }
  
        if(!matchFound) {
          this.showEmptyResultMsg = true
          this.loadingSearchResult = false
        }
  
        console.log(matchFound, this.showEmptyResultMsg, this.loadingSearchResult)
      });
    } else if(this.myspaceIdeaTypeFilter == 'pipelines') {
      this.myspace['pipelines'].forEach((ideas, ideaIndex) => {
        if(ideas['memberTargetId'] == selectedMemberId && ideas['targetId'] == selectedTargetId) {
          this.pipelines = this.myspace['pipelines'][ideaIndex]['ideas'].map(idea => JSON.parse(idea))
          console.log(this.pipelines)
  
          this.totalPages = Math.ceil(this.pipelines.length / 10)
          this.loadPagination()
          if(this.pipelines.length == 0) {
            this.showEmptyResultMsg = true
            this.loadingSearchResult = false
          } else {
            this.showEmptyResultMsg = false
            this.loadingSearchResult = false
            matchFound = true
          }
        }
  
        if(!matchFound) {
          this.showEmptyResultMsg = true
          this.loadingSearchResult = false
        }
  
        console.log(matchFound, this.showEmptyResultMsg, this.loadingSearchResult)
      });
    }
  }

  loadIdeasOfPageForPipeline(getRefinedByCount = false) {
    let filters = Object.assign({}, this.appliedFilters)
    
    if(this.searchBy == 'abstract') {
      filters['start'] = 0
    } else {
      filters['start'] = ((this.selectedPage - 1) * this.recordLength)
    }
    filters['length'] = this.recordLength

    if(getRefinedByCount) filters['getRefinedByCount'] = true

    if(this.searchKeyword != '') {
      filters['search'] = this.searchKeyword
      if(this.searchBy == 'abstract') {
        filters['search'] = '"' + filters['search'] + '"'
      }
    }

    filters['country'] = this.countryFilter
    filters['city'] = this.cityFilter
    filters['companyName'] = this.companyName
    filters['state'] = this.stateFilter

    filters['searchBy'] = this.searchBy

    // filters['isBackToSearch'] = this.isBackToSearch;

    
    this.userService.getPipelines(filters).subscribe(
      abstracts => {
        this.isTargetUpdated = false;
        if(abstracts.success || abstracts.success == false) this.sessionTimeOut()

        if(this.searchBy == 'abstract') {
          this.loadingExactMatch = false
        } else {
          this.loadingSearchResult = false
        }
        
        this.updateIdeasListForPipeline(abstracts, (this.searchBy == 'abstract' && this.category != 'all'))

        this.loadPagination()

      }
    )
  }

  updateIdeasListForPipeline(abstracts, isExactMatch) {
    console.log(abstracts)
    this.pipelines = []
    // this.ideaTypesKeys.forEach((key, i) => this.ideaTypes[key] = 0)

    if(abstracts.allIdeasCount) {
      this.allIdeasCount = abstracts.allIdeasCount
      if(this.totalRecordsInDb == 0) this.totalRecordsInDb = abstracts.allIdeasCount
    }
    this.targetedIdeasCount = abstracts.recordsTotal

    this.showEmptyResultMsg = (abstracts.hits.hits.length == 0);
    this.filteredRecords = abstracts.recordsTotal
    this.totalPages = Math.ceil(this.filteredRecords / this.recordLength)

    if(this.totalPages > 1000) this.totalPages = 1000

    abstracts.hits.hits.forEach(abstract => {
      let abstractData = Object.assign({}, abstract._source)
      abstractData['_id'] = abstract._id
      this.pipelines.push(abstractData)

      // this.ideaTypesKeys.forEach(key => {
      //   this.ideaTypes[key] += (abstract[this.ideaTypesColumnMapping[key]] == 'Y') ? 1 : 0
      // });
    })

    if(document.querySelector('.popover')) {
      document.querySelector('.popover').remove()
    }
  }

  filterPipelineResult() {
    this.loadIdeasOfPage(false)
  }

  showColumnClear(columnName) {
    this.columnSearchVisibility[columnName] = true
  }

  clearColumnSearch(columnName) {
    switch (columnName) {
      case 'companyName':
        this.companyName = '';
        break;
      case 'city':
        this.cityFilter = '';
        break;
      case 'state':
        this.stateFilter = '';
        break;
      case 'country':
        this.countryFilter = '';
        break;
    }
    this.columnSearchVisibility[columnName] = false
  }
}

import { Component, OnInit, Renderer2, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../shared/user.service';

declare var pleaseWait :any;

import { NgxSpinnerService } from 'ngx-spinner';

import { Router, ActivatedRoute, Params, NavigationExtras } from "@angular/router";

import swal from 'sweetalert2';

import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  public targetTitle: any = []
  public abstractData: any = {
    "ideaSubmitted": {},
    "ideaFunded": {}
  }
  public types: any = []
  public news: any = []
  public totalRecordsInDb: number = 0
  public allIdeasCount: number = 0
  public targetedIdeasCount: number = 0
  public filteredRecords: number = 0
  public recordLength: number = 10
  public pagination: any = []
  public selectedPage: number = 1
  public totalPages: number = 1
  public columnSortOrder: any = {
    'relevance' : {
      'sortBy': 'score'
    }, 'pIName' : {
      'sortBy': 'researcher.name.last,researcher.name.first',
      'sortOrder': '1,1'
    }, 'organization' : {
      'sortBy': 'researcher.organization',
      'sortOrder': '1'
    }, 'FYearDesc' : {
      'sortBy': 'FYear',
      'sortOrder': '-1'
    }, 'FYearAsc' : {
      'sortBy': 'FYear',
      'sortOrder': '1'
    }, 'submittedOnDesc' : {
      'sortBy': 'submittedOn',
      'sortOrder': '-1'
    }, 'submittedOnAsc' : {
      'sortBy': 'submittedOn',
      'sortOrder': '1'
    }
  }

  public sortBy: string = 'pub+date'

  public browseBy: string = 'targets';

  public analytics: any = []
  public timeTakenByQuery: number = 0

  public selectedTargetIndex: number = 0;
  public showClearAll: any = {}
  
  public organizationTypes: any = []
  public countriesList: any = []
  
  public ideaTypes: any = {}
  public ideaTypesKeys: any = []
  public journals: any = []
  public publishYears: any = []

  public searchKeyword: any = ''

  public targetSelecteAll: boolean = false
  public memberTargetSelecteAll: any = {}

  public appliedFilters: any = {
    'targets': [],
    'journals': [],
    'publishYears': []
  }

  public category = 'targets'

  public esearchTerm = ''

  public user: any = {}
  public userType: string = ''
  public memberTargets: any = []

  public showEmptyResultMsg: boolean = false;
  
  public searchBy: string = 'keyword'
  public advancedSearch: boolean = false
  public selectedMember: number = 0;

  public activeTabs: any = []
  public memberDetailsModal: string = ''
  public organizationData: any = {}
  public selectedTab: string = 'organization'

  public memberTypeList = [
    "Subordinate",
    "Restricted Member"
  ]

  public newsExternalUrl: string = 'https://google.com';


  @ViewChild('submitAbstractModel') submitAbstractModel:ElementRef;
  @ViewChild('closeAbstractModel') closeAbstractModel:ElementRef;
  @ViewChild('abstractFormElement') abstractForm:ElementRef;
  @ViewChild('editTargets') editTargets:ElementRef;
  @ViewChild('editTargetsClose') editTargetsClose: ElementRef;
  @ViewChild('editMemberTargetsClose') editMemberTargetsClose: ElementRef
  @ViewChild('closeSetupOrganizationModel') closeSetupOrganizationModel:ElementRef;

  constructor(private userService: UserService, private renderer: Renderer2, private router: Router, private activatedRouter: ActivatedRoute, private chRef: ChangeDetectorRef, private spinner: NgxSpinnerService, private sanitzer: DomSanitizer) {
    this.renderer.addClass(document.body, 'bg-light');
    this.renderer.removeClass(document.body, 'bg-blue');
  }

  ngOnInit() {
    if (localStorage.getItem("user") === null) {
      localStorage.setItem('redirectUrl', window.location.href)
      // window.location.href = 'http://login.onixhub.com'
      window.location.href = 'http://login.onixhub.com'
    }

    if(localStorage.getItem('searchKeyword') != null) {
      this.searchKeyword = localStorage.getItem('searchKeyword')
      this.searchBy = localStorage.getItem('searchBy')
      this.targetSelecteAll = false
    }

    this.user = JSON.parse(localStorage.getItem('user'))
    this.userType = this.user.member[0].type

    this.ideaTypesKeys.forEach((key, i) => this.ideaTypes[key] = 0)

    this.activatedRouter.queryParams.subscribe((params: Params) => {

      if(params['loadWithFilters'] == 'true') {
        this.appliedFilters = JSON.parse(params.appliedFilters)
        this.searchKeyword = params.searchKeyword
        this.totalRecordsInDb = params.totalRecordsInDb
        this.browseBy = params.browseBy
        this.selectedPage = params.pageNo
      }

      let category = 'targets'

      this.userService.getMemberById(this.user._id).subscribe(
        (organization) => {
          this.activeTabs = []
          console.log(organization)
          this.memberDetailsModal = 'Update member details'
          this.organizationData = organization
          this.organizationData.member[0].confirmEmail = this.organizationData.member[0].email
          this.selectTab('organization')


        }
      )
      
      $(document).on('click', '.caret', function() {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      })

      var popOverSettings = {
        placement: 'right',
        trigger: 'hover',
        html: true,
        selector: '[data-toggle="popover"]'
      }
      
      $('body').popover(popOverSettings);

      this.userService.getOrganizationTypes().subscribe(
        organizationTypes => {
          if(organizationTypes.success || organizationTypes.success == false) this.sessionTimeOut()
          this.organizationTypes = organizationTypes

          // this.loadIdeasOfPage(true)
          
          this.userService.getTargetCount(this.user._id, category).subscribe(
            analytics => {
              analytics.forEach(keyword => {
                if(!this.analytics[keyword.title]) this.analytics[keyword.title] = {
                  count: 0,
                  keywords: []
                }
                this.analytics[keyword.title]['count'] += keyword.count
                this.analytics[keyword.title]['keywords'].push(keyword)
              });
              this.targetTitle = Object.keys(this.analytics)

              if(localStorage.getItem('searchKeyword') == null) {
                Object.keys(this.analytics).forEach((targetTitle, targetIndex) => {
                  if(params['loadWithFilters'] == 'true') {
                    if(this.appliedFilters['targets'].indexOf(this.analytics[targetTitle]['keywords'][0]['keyword']) != -1) {
                      // this.selectAllTarget(targetTitle, false)
                      this.showClearAll[targetTitle] = true

                      var isAllSelected = this.user.target.every((target) => {
                        return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
                      })

                      if(isAllSelected) {
                        this.targetSelecteAll = true
                      }
                    }
                  } else {
                    if(targetIndex == 0) this.selectAllTarget(targetTitle, false)
                  }
                })

                if(Object.keys(this.analytics).length == 1) {
                  this.targetSelecteAll = true;
                }
              }

                            
              if(this.user.member[0].type == 'Manager') {
                this.userService.getMemberTargets(this.user._id).subscribe(
                  (memberTargets) => {
                    this.memberTargets = memberTargets[0].member.slice(1)
                    this.memberTargets.forEach((member, memberIndex) => {
                      if(params['loadWithFilters'] == 'true') {
                        this.memberTargetSelecteAll[memberIndex] = member.target.every((target) => {
                          return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
                        })
                      }
                      // this.memberTargetSelecteAll[memberIndex] = (localStorage.getItem('searchKeyword') == null)
                      if(member.target) {
                        member.target.forEach(target => {
                          if(params['loadWithFilters'] == 'true') {
                            if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
                              // this.appliedFilters['targets'].push(target.booleanSearch)
                              this.showClearAll['MT_'+memberIndex+'_'+target.title] = (localStorage.getItem('searchKeyword') == null)
                            }
                          }
                        });
                      }
                    });

                    if(localStorage.getItem('searchKeyword') != null) {
                      this.customSearch()
                    } else {
                      this.loadIdeasOfPage(true)
                    }
                  }
                )
              } else {
                this.memberTargets = this.user.member
                // this.memberTargetSelecteAll[0] = true
                if(params['loadWithFilters'] == 'true') {
                  this.memberTargetSelecteAll[0] = this.user.member[0].target.every((target) => {
                    return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
                  })
                }
                this.user.member[0].target.forEach(target => {
                  if(params['loadWithFilters'] == 'true') {
                    if(this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1) {
                      // this.appliedFilters['targets'].push(target.booleanSearch)
                      this.showClearAll['MT_0_'+target.title] = (localStorage.getItem('searchKeyword') == null)
                    }
                  }
                });
                this.loadIdeasOfPage(true)
              }

              // Object.values(this.analytics).forEach(target => {
              //   console.log(target)
              //   target['keywords'].forEach(keyword => {
              //     this.appliedFilters['targets'].push(keyword.keyword)
              //   });
              // });
              
              // this.loadIdeasOfPage(true)
            }
          )
        }
      )

      this.category = category
    });

  }

  showKeywordOfTarget(targetIndex) {
    this.selectedTargetIndex = targetIndex
  }

  selectAllTargetTitles(event) {
    if(!this.targetSelecteAll) {
      Object.keys(this.analytics).forEach(target => {
        this.selectAllTarget(target, false)
      });
      this.targetSelecteAll = true
    } else {
      Object.keys(this.analytics).forEach(target => {
        this.clearAllTarget(target, false)
      });
      this.targetSelecteAll = false
      this.clearAllTarget(null, true)
    }
  }

  selectAllMemberTargets(memberIndex, event) {
    if(!this.memberTargetSelecteAll[memberIndex]) {
      this.memberTargets[memberIndex].target.forEach(target => {
        this.selectMemberTarget(memberIndex, target, false)
      });
      this.memberTargetSelecteAll[memberIndex] = true
      this.selectAllTarget(null, true)
    } else {
      this.memberTargets[memberIndex].target.forEach(target => {
        this.clearAllMemberTarget(memberIndex, target, false)
      });
      this.memberTargetSelecteAll[memberIndex] = false
      this.clearAllMemberTarget(memberIndex, null, true)
    }
  }

  selectMemberTarget(memberIndex, target, refreshPage) {
    if(target) {
      this.appliedFilters['targets'].push(target.booleanSearch)
      this.showClearAll['MT_'+memberIndex+'_'+target.title] = true

      var isAllSelected = this.memberTargets[memberIndex].target.every((target) => {
        return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
      })

      if(isAllSelected) {
        this.memberTargetSelecteAll[memberIndex] = true
      }
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  selectAllTarget(targetTitle, refreshPage) {
    if(targetTitle) {
      this.analytics[targetTitle].keywords.forEach(keyword => {
        this.appliedFilters['targets'].push(keyword.keyword)
      })
      this.showClearAll[targetTitle] = true

      // if(Object.keys(this.analytics).length == this.appliedFilters['targets'].length) {
      //   this.targetSelecteAll = true
      // }

      var isAllSelected = this.user.target.every((target) => {
        return (this.appliedFilters['targets'].indexOf(target.booleanSearch) != -1)
      })

      if(isAllSelected) {
        this.targetSelecteAll = true
      }
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }

  clearAllTarget(targetTitle, refreshPage) {
    if(targetTitle) {
      if(this.analytics[targetTitle]) {
        this.analytics[targetTitle].keywords.forEach(keyword => {
          let targetIndex = this.appliedFilters['targets'].indexOf(keyword.keyword)
          if(targetIndex != -1) {
            this.appliedFilters['targets'].splice(targetIndex, 1)
          }
        })
        this.targetSelecteAll = false
      }

      this.showClearAll[targetTitle] = false
    }
    
    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }
  
  clearAllMemberTarget(memberIndex, target, refreshPage) {
    if(target) {
      let targetIndex = this.appliedFilters['targets'].indexOf(target.booleanSearch)
      if(targetIndex != -1) {
        this.appliedFilters['targets'].splice(targetIndex, 1)
      }
      this.memberTargetSelecteAll[memberIndex] = false
      targetIndex = this.appliedFilters['targets'].indexOf(target.booleanSearch)
      
      this.showClearAll['MT_'+memberIndex+'_'+target.title] = false
    }

    if(refreshPage) {
      this.selectedPage = 1
      this.loadIdeasOfPage()
    }
  }
  
  loadContent(content) {
    this.abstractData = content
  }

  viewAbstract(content) {
    console.log(content)
    let contentParam = content
    contentParam['source'] = (content['source'][0]['_']) ? "<a target='_blank' href='"+content['source'][0]['$'].url+"'>"+content['source'][0]['_']+"" : content['source'][0]
    contentParam['appliedFilters'] = JSON.stringify(this.appliedFilters)
    contentParam['searchKeyword'] = this.searchKeyword
    contentParam['totalRecordsInDb'] = this.totalRecordsInDb
    contentParam['browseBy'] = this.browseBy
    contentParam['pageNo'] = this.selectedPage
    contentParam['fromNews'] = true

    // this.newsExternalUrl = content.link[0];

    let navigationExtras: NavigationExtras = {
        queryParams: contentParam, skipLocationChange: true
    };
    this.router.navigate(['/view-abstract'], navigationExtras)
  }

  logout() {
    swal({
      title: 'Are you sure?',
      text: "Do you want to logout?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I\'m Sure'
    }).then((result) => {
      if (result.value) {
        localStorage.clear()
        // this.router.navigate(['']);
        window.location.href = 'https://onixhub.com'
      }
    })
  }

  sessionTimeOut() {
    localStorage.clear()
    // window.location.href = 'http://login.onixhub.com?sessionTimeout=true'
    window.location.href = 'http://login.onixhub.com?sessionTimeout=true'
  }

  applyFilters(filterType, keyword, index, title = null) {
    if(this.appliedFilters[filterType].indexOf(keyword) != -1)
      this.appliedFilters[filterType].splice(this.appliedFilters[filterType].indexOf(keyword), 1)
    else
      this.appliedFilters[filterType].push(keyword)

    if(filterType == 'targets') {
      this.showClearAll[title] = this.analytics[title].keywords.every(keyword => {
        return (this.appliedFilters[filterType].indexOf(keyword.keyword) != -1)
      })
    }
    
    this.selectedPage = 1
    this.loadIdeasOfPage()

  }

  updateIdeasList(news) {

    this.totalRecordsInDb = (news.rss.channel[0].item) ? news.rss.channel[0].item.length : 0
    this.allIdeasCount = (news.rss.channel[0].item) ? news.rss.channel[0].item.length : 0
    this.targetedIdeasCount = (news.rss.channel[0].item) ? news.rss.channel[0].item.length : 0

    this.totalPages = Math.ceil(((news.rss.channel[0].item) ? news.rss.channel[0].item.length : 0) / 10)

    if(news.rss.channel[0].item) {
      this.news = news.rss.channel[0].item.slice(((this.selectedPage - 1) * 10), (this.selectedPage * 10))
    } else {
      this.news = []
    }

    this.filteredRecords = this.news.length

    this.showEmptyResultMsg = (this.news.length == 0)

    if(document.querySelector('.popover')) {
      document.querySelector('.popover').remove()
    }

    
    this.userService.getCountryList().subscribe(
      countries => {
        this.countriesList = countries
        this.countriesList.unshift({name:'Choose...', code: ''})
        console.log(this.countriesList)
      }
    )
  }

  loadPagination() {
    this.pagination = []
    
    console.log(this.totalPages)
    if(this.totalPages <= 6) {
      for(let pageNo = 1; pageNo <= this.totalPages; pageNo++)
        this.pagination.push({
          pageNo: pageNo,
          active: (pageNo == this.selectedPage)
        })
    } else {
      if(this.selectedPage > 3)
      this.pagination.push({
        pageNo: 1,
        active: false
      })
      if(this.selectedPage > 4)
        this.pagination.push({
          pageNo: '...',
          active: false
        })

      let renderPageNo = (this.selectedPage - 2)
      for(let renderedCount = 0; renderedCount < 5; renderedCount++) {
        if(renderPageNo >= 1 && renderPageNo <= this.totalPages) {
          this.pagination.push({
            pageNo: renderPageNo,
            active: (renderPageNo == this.selectedPage)
          })
        }
        renderPageNo++
      }
      
      if(this.selectedPage <= (this.totalPages - 4))
        this.pagination.push({
          pageNo: '...',
          active: false
        })
      if(this.selectedPage <= (this.totalPages - 3))
        this.pagination.push({
          pageNo: this.totalPages,
          active: false
        })
    }
  }

  loadIdeasOfPage(getRefinedByCount = false) {
    let filters = Object.assign({}, this.appliedFilters)
    filters['start'] = ((this.selectedPage - 1) * this.recordLength)
    filters['length'] = this.recordLength

    if(getRefinedByCount) filters['getRefinedByCount'] = true

    filters['userType'] = this.user.member[0].type
    filters['organization'] = this.user.organizationName

    this.esearchTerm = ''
    if(this.searchKeyword != '') {
      this.esearchTerm = this.searchKeyword
    } else {
      this.esearchTerm = ''

      if(this.appliedFilters['targets'].length > 0) {
        this.esearchTerm = this.appliedFilters['targets'].map(function(value) {
          return '('+value+')'
        }).join('+OR+')
      }
    }

    let queryStartsAt = new Date().getTime()

    let similarArticles = []

    this.userService.getNews(this.esearchTerm).subscribe(
      result => {
        console.log(result)
        var parseString = require('xml2js').parseString;
        parseString(result.feeds, function (err, news) {
          console.log(news)
          this.updateIdeasList(news)
          this.loadPagination()
        }.bind(this));
      }
    )

    
    // this.userService.eSearchforTerm(this.esearchTerm, this.sortBy, this.selectedPage).subscribe(
    //   result => {
    //     this.totalRecordsInDb = result.esearchresult.count
    //     this.allIdeasCount = result.esearchresult.count
    //     this.targetedIdeasCount = result.esearchresult.count
    
    //     this.filteredRecords = result.esearchresult.count
    //     this.totalPages = Math.ceil(result.esearchresult.count / 10)


    //     let uIds = result.esearchresult.idlist.join(',')
    //     this.userService.eFetchForUID(uIds).subscribe(
    //       result => {
    //         var parseString = require('xml2js').parseString;
    //         parseString(result, function (err, news) {
    //           console.log(news)
    //           this.updateIdeasList(news)
    //           this.loadPagination()
    //         }.bind(this));
    //       }
    //     )
    //   }
    // )
  }

  navigateToPage(selectedPage) {
    if(selectedPage == '...') return
    this.selectedPage = selectedPage
    this.loadIdeasOfPage()
  }

  navigateToNexPage() {
    this.selectedPage = (this.selectedPage != this.totalPages) ? this.selectedPage + 1 : this.selectedPage
    this.loadIdeasOfPage()
  }

  navigateToPreviousPage() {
    this.selectedPage = (this.selectedPage != 1) ? this.selectedPage - 1 : this.selectedPage
    this.loadIdeasOfPage()
  }

  customSearch() {
        
    localStorage.setItem('searchKeyword', this.searchKeyword)
    localStorage.setItem('searchBy', this.searchBy)
    
    let searchData = {
      organization: this.user._id,
      id: this.user.member[0]._id,
      email: this.user.member[0].email,
      search: this.searchKeyword,
      tab: "News"
    }
    
    this.userService.logSearch(searchData).subscribe(response => {
      console.log(response)
    })

    this.appliedFilters['targets'] = []
    this.appliedFilters['ideaTypes'] = []
    this.appliedFilters['organizationTypes'] = []

    Object.keys(this.analytics).forEach(targetTitle => {
      this.clearAllTarget(targetTitle, false)
    })

    this.memberTargets.forEach((member, memberIndex) => {
      if(member.target) {
        member.target.forEach(target => {
          this.showClearAll['MT_'+memberIndex+'_'+target.title] = false
        });
      }
      this.memberTargetSelecteAll[memberIndex] = false
    })

    this.targetSelecteAll = false
    
    this.selectedPage = 1
    this.loadIdeasOfPage(true)
    this.showAdvancedSearch(false)
  }

  formateDate(date) {
    let dateObj = new Date(date)
    return dateObj.getFullYear()+'-'+("00"+(dateObj.getMonth()+1)).substr(-2)+"-"+("00"+(dateObj.getDate())).substr(-2)
  }

  addNewTarget() {
    this.user.target.push({})
    this.organizationData.target.push({})
  }
  
  // addNewMemberTarget() {
  //   this.memberTargets[0].target.push({})
  // }

  addNewMemberTarget(memberIndex) {
    console.log(memberIndex)
    console.log(this.organizationData.member[memberIndex])
    console.log(this.memberTargets)

    let fromManageModal = (memberIndex != null)
    if(memberIndex == null) memberIndex = this.selectedMember
    
    console.log(memberIndex)
    
    if(!this.organizationData.member[memberIndex]['target']) {
      this.organizationData.member[memberIndex]['target'] = []
      this.memberTargets[(fromManageModal) ? (memberIndex - 1) : memberIndex] = {
        'target': []
      }
    }
    this.organizationData.member[memberIndex]['target'].push({})


    this.memberTargets[(fromManageModal) ? (memberIndex - 1) : memberIndex].target.push({})
  }

  deleteTarget(targetIndex) {
    this.user.target.splice(targetIndex, 1)
    this.organizationData.target.splice(targetIndex, 1)
  }

  deleteMemberTarget(memberIndex, targetIndex) {
    let fromManageModal = (memberIndex != null)
    if(memberIndex == null) memberIndex = this.selectedMember
    if(this.organizationData.member[memberIndex]['target']) this.organizationData.member[memberIndex]['target'].splice(targetIndex, 1)

    if(fromManageModal) memberIndex = memberIndex - 1
    this.memberTargets[memberIndex].target.splice(targetIndex, 1)
    console.log(memberIndex, targetIndex, this.organizationData.member)
  }

  
  // deleteMemberTarget(targetIndex) {
  //   this.memberTargets[0].target.splice(targetIndex, 1)
  // }

  saveTargets() {
    this.userService.saveTargets(this.user).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        
        if(response.status) localStorage.setItem('user', JSON.stringify(this.user))

        this.targetTitle = []
        this.analytics = []

        this.selectedPage = 1
        this.loadIdeasOfPage()

        this.userService.getTargetCount(this.user._id, this.category).subscribe(
          analytics => {
            analytics.forEach(keyword => {
              if(!this.analytics[keyword.title]) this.analytics[keyword.title] = {
                count: 0,
                keywords: []
              }
              this.analytics[keyword.title]['count'] += keyword.count
              this.analytics[keyword.title]['keywords'].push(keyword)
            });
            this.targetTitle = Object.keys(this.analytics)
            this.editTargets.nativeElement.click();
            this.editTargetsClose.nativeElement.click()
          }
        )
      }
    )
  }

  saveMemberTargets() {
    this.userService.saveMemberTargets(this.memberTargets[this.selectedMember]._id, this.memberTargets[this.selectedMember].target).subscribe(
      response => {
        if(response.success || response.success == false) this.sessionTimeOut()
        
        if(response.status) localStorage.setItem('user', JSON.stringify(this.user))

        this.targetTitle = []
        this.analytics = []

        this.selectedPage = 1
        this.loadIdeasOfPage()

        this.userService.getMemberById(this.user._id).subscribe(
          (organization) => {
            this.activeTabs = []
            console.log(organization)
            this.memberDetailsModal = 'Update member details'
            this.organizationData = organization
            this.organizationData.member[0].confirmEmail = this.organizationData.member[0].email
            this.selectTab('organization')
          }
        )
          
        this.userService.getTargetCount(this.user._id, this.category).subscribe(
          analytics => {
            analytics.forEach(keyword => {
              if(!this.analytics[keyword.title]) this.analytics[keyword.title] = {
                count: 0,
                keywords: []
              }
              this.analytics[keyword.title]['count'] += keyword.count
              this.analytics[keyword.title]['keywords'].push(keyword)
            });
            this.targetTitle = Object.keys(this.analytics)
            this.editTargets.nativeElement.click();
            this.editMemberTargetsClose.nativeElement.click()
          }
        )
      }
    )
  }

  updateSortOrder(sortBy) {
    this.sortBy = sortBy

    this.selectedPage = 1
    this.loadIdeasOfPage()
  }

  showAdvancedSearch(show) {
    this.advancedSearch = show
  }

  updateSearchBy(searchBy) {
    this.searchBy = searchBy
    this.searchKeyword = ''
    localStorage.removeItem('searchKeyword')
  }

  clearSearch() {
    this.searchKeyword = ''
    this.searchBy = 'keyword'
    localStorage.removeItem('searchKeyword')

    Object.keys(this.analytics).forEach(targetTitle => {
      this.selectAllTarget(targetTitle, false)
    })

    this.memberTargets.forEach((memberTarget, targetIndex) => {
      this.selectAllMemberTargets(targetIndex, null);
    });

    this.selectedPage = 1
    this.loadIdeasOfPage(true)
    this.showAdvancedSearch(false)
  }

  selectMember(memberId) {
    this.selectedMember = memberId
  }

  saveMember() {
    console.log(this.organizationData)
    this.userService.saveMember(this.organizationData).subscribe(
      data => {
        if(data.status) {
          let alertMessage = 'Member Details has been updated successfully!'
          if(!this.organizationData._id) alertMessage = 'New Member is added successfully!'
          swal({
            title: alertMessage,
            type: 'success',
            confirmButtonText: 'Okay'
          })
          
          this.userService.getMemberTargets(this.user._id).subscribe(
            (memberTargets) => {
              this.memberTargets = memberTargets[0].member.slice(1)
              // this.memberTargets.forEach((member, memberIndex) => {
              //   this.memberTargetSelecteAll[memberIndex] = (localStorage.getItem('searchKeyword') == null)
              //   if(member.target) {
              //     member.target.forEach(target => {
              //       this.appliedFilters['targets'].push(target.booleanSearch)
              //       this.showClearAll['MT_'+memberIndex+'_'+target.title] = (localStorage.getItem('searchKeyword') == null)
              //     });
              //   }
              // });
              this.loadIdeasOfPage(true)
          })
          
          this.closeSetupOrganizationModel.nativeElement.click();
        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

  addActiveTab(tabId) {
    if(this.activeTabs.indexOf(tabId) == -1) {
      this.activeTabs.push(tabId)
    }
    this.selectedTab = tabId
  }

  closeActiveTab(tabId) {
    this.activeTabs.splice(this.activeTabs.indexOf(tabId), 1)
    this.selectTab('organization')
  }

  selectTab(tabName) {
    this.selectedTab = tabName;
  }

  addNewMember() {
    this.organizationData.member.push({
      type: 'Subordinate'
    })
    console.log(this.organizationData.member)
    this.addActiveTab((this.organizationData.member.length - 1))

    // setTimeout(() => {
    //   var toggler = document.getElementsByClassName("caret");

    //   toggler[toggler.length - 1].addEventListener("click", function() {
    //     this.parentElement.querySelector(".nested").classList.toggle("active");
    //     this.classList.toggle("caret-down");
    //   });
    // }, 500)
  }

  deleteMember(memberIndex) {
    this.organizationData.member.splice(memberIndex, 1)
    let activeTabIndex = this.activeTabs.indexOf((memberIndex - 1))
    this.activeTabs.splice(activeTabIndex, 1)
    this.selectTab('organization')
  }

  sanitizeExternalURL(url) {
    return this.sanitzer.bypassSecurityTrustResourceUrl(url);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router'
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.css']
})
export class AuthorizeComponent implements OnInit {

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      var token = params['token']

      this.userService.authorize(token).subscribe(
        data => {
          console.log(data.success)
          if(data.success) {
            localStorage.setItem('user', JSON.stringify(data.member))
            localStorage.setItem('token', token)
            this.router.navigate([''])
          } else {
            window.location.href = 'https://onixhub.com/login.html'
          }
        }
      )
    })
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute, NavigationExtras, Params } from "@angular/router"
import { UserService } from '../shared/user.service'

@Component({
  selector: 'app-view-abstract',
  templateUrl: './view-abstract.component.html',
  styleUrls: ['./view-abstract.component.css']
})
export class ViewAbstractComponent implements OnInit {

  public user: any
  public abstractData : any
  public article: string = ''
  public clinicalTrialData: any

  public loadingSimilarArticles: boolean = true
  public similarArticles: any = []
  public topKeywords: any = []
  public appliedFilters: any = {}
  public searchKeyword: string = ''
  public totalRecordsInDb: number = 0
  public browseBy: string = 'targets'
  public pageNo: string = ''
  public searchBy: string = 'keyword'
  public selectedMySpaceTarget: string = '_0'

  public fromIdeas: boolean = true
  public fromPublication: boolean = false
  public fromNews: boolean = false
  public fromClinicalTrials: boolean = false
  public fromMySpace: boolean = false

  public publicationAbstract: any = {}

  public similarArticlesCount: number = 0;

  public similarArticlesPage: number = 1;

  public seeMoreText: string = 'See more'
  public hideSeemore: boolean = false

  public customKeyword: string = ''

  public abstractText: string = ''
  public abstractTitle: string = ''

  public includeAll: boolean = true

  @ViewChild('abstract') abstract: ElementRef
  @ViewChild('title') title: ElementRef
  @ViewChild('abstractTop') abstractTop: ElementRef

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem("user") === null) {
      localStorage.setItem('redirectUrl', window.location.href)
      // window.location.href = 'http://login.onixhub.com'
      window.location.href = 'http://login.onixhub.com'
    }

    this.user = JSON.parse(localStorage.getItem('user'))

    this.route.queryParams.subscribe(params => {
      if(typeof params['sortBy'] != 'undefined') {
        localStorage.setItem('sortBy', 'submittedOnDesc')
      }
      if(typeof params['searchBy'] != 'undefined') {
        this.searchBy = params['searchBy']
      }
    });

    this.route.params.subscribe((params: Params) => {
      var id = params['abstractid']
      if(id) {
        this.userService.getAbstractById(id).subscribe(
          data => {
            if(data.status) {
              let abstractData = {
                _id: data.abstract._id,
                abstract: data.abstract.abstract,
                aims: data.abstract.aims,
                appliedFilters: "{\"targets\":[],\"ideaTypes\":[],\"organizationTypes\":[]}",
                browseBy: "targets",
                country: data.abstract.researcher.address.country,
                name: data.abstract.pIName,
                organization: data.abstract.researcher.organization,
                pageNo: "1",
                remarks: data.abstract.remarks,
                score: "0.45",
                searchKeyword: "",
                submittedOn: data.abstract.submittedOn,
                title: data.abstract.title,
                totalRecordsInDb: 0,
                type: data.abstract.researcher.organizationType,
                viewCount: data.abstract.viewCount+""
              }
              this.abstractData = abstractData

              this.appliedFilters = JSON.parse(abstractData['appliedFilters'])
              this.searchKeyword = abstractData.searchKeyword
              this.totalRecordsInDb = abstractData.totalRecordsInDb
              this.browseBy = abstractData.browseBy
              this.pageNo = abstractData.pageNo

              this.userService.updateViewCount(abstractData._id).subscribe(
                status => {
                  // console.log('Update view count : '+ status.status)
                }
              )
        
              this.userService.getTopMatchedKeywords(this.user._id, abstractData._id).subscribe(
                topKeywords => {
                  if(topKeywords.success || topKeywords.success == false) this.sessionTimeOut()
                  if(topKeywords.length > 0) {
                    let term  = topKeywords.slice(0,3).map(function(keyword, index) { return keyword._id}).join('+AND+')
                    // this.topKeywords = topKeywords.map(function(keyword, index) { return keyword._id})
                    this.topKeywords = topKeywords.map(function(keyword, index) { return {
                        "keyword": keyword._id,
                        "count": keyword.value,
                        "checked": (index < 3)
                      }
                    })
                    this.userService.eSearchforTerm(term, 'pub+date', 1, null).subscribe(
                      result => {
                        this.similarArticlesCount = result.esearchresult.count
                        let uIds = result.esearchresult.idlist.join(',')
                        this.userService.eSummaryforUID(uIds).subscribe(
                          result => {
                            for(let keys in result.result) {
                              if(keys != 'uids') this.similarArticles.push(result.result[keys])
                            }
                            this.loadingSimilarArticles = false

                            if(this.similarArticlesCount <= this.similarArticles.length) {
                              this.hideSeemore = true
                            }
                          }
                        )
                      }
                    )
                  }
                }
              )
            } else {
              // window.location.href = 'http://login.onixhub.com'
              window.location.href = 'http://login.onixhub.com'
            }
          }
        )
      } else {
        this.route.queryParams.subscribe(params => {
          // console.log(params)
          this.abstractData = params
          this.appliedFilters = JSON.parse(params['appliedFilters'])
          this.searchKeyword = params.searchKeyword
          this.totalRecordsInDb = params.totalRecordsInDb
          this.browseBy = params.browseBy
          this.pageNo = params.pageNo
          this.selectedMySpaceTarget = params.selectedMySpaceTarget
    
          // console.log(this.abstractData)
          // console.log(this.abstractData.abstract)
    
          if(params.fromMySpace) this.fromMySpace = true

          if(params.fromPublication) {
            this.fromIdeas = false
            this.fromPublication = true
            this.fromClinicalTrials = false
          }
    
          if(params.fromNews) {
            this.fromIdeas = false
            this.fromNews = true
          }
    
          if(params.fromClinicalTrials) {
            this.fromIdeas = false
            this.fromClinicalTrials = true
          }
          
          if(this.fromIdeas) {
            this.userService.updateViewCount(params._id).subscribe(
              status => {
                // console.log('Update view count : '+ status.status)
              }
            )
      
            this.userService.getTopMatchedKeywords(this.user._id, params._id).subscribe(
              topKeywords => {
                if(topKeywords.success || topKeywords.success == false) this.sessionTimeOut()
                if(topKeywords.length > 0) {
                  let term  = topKeywords.slice(0,3).map(function(keyword, index) { return keyword._id}).join('+AND+')
                  this.topKeywords = topKeywords.map(function(keyword, index) { return {
                      "keyword": keyword._id,
                      "count": keyword.value,
                      "checked": (index < 3)
                    }
                  })
                  this.userService.eSearchforTerm(term, 'pub+date', 1, null).subscribe(
                    result => {
                      this.similarArticlesCount = result.esearchresult.count
                      let uIds = result.esearchresult.idlist.join(',')
                      this.userService.eSummaryforUID(uIds).subscribe(
                        result => {
                          for(let keys in result.result) {
                            if(keys != 'uids') this.similarArticles.push(result.result[keys])
                          }
                          this.loadingSimilarArticles = false

                          if(this.similarArticlesCount <= this.similarArticles.length) {
                            this.hideSeemore = true
                          }
                        }
                      )
                    }
                  )
                }
              }
            )
          } else if(this.fromPublication || this.fromClinicalTrials) {
            if(this.fromPublication) {
              try {
                this.publicationAbstract = JSON.parse(this.abstractData.abstract)
              } catch(e) {
                this.publicationAbstract = (this.abstractData.abstract)
              }
            }

            this.userService.getCommonWords().subscribe(
              commonWords => {
                let keywordList = []
    
                let wordCount = {}

                let abstractContent = ''
                if(this.fromClinicalTrials) {
                  abstractContent = (this.abstractData.DetailedDescription[0]) ? this.abstractData.DetailedDescription[0] : this.abstractData.OfficialTitle[0]
                } else if(this.fromPublication) {
                  abstractContent = (this.abstractData.shortAbstractText) ? this.abstractData.shortAbstractText : this.abstractData.description[0]
                }
                abstractContent.split(' ').forEach(word => {
                  word = word.replace(/,/g, '').replace(/!/g, '').replace('.', '').toLowerCase()
                  if(wordCount[word]) wordCount[word] += 1
                  else wordCount[word] = 1
                });
    
                let sortable = []
                for (var word in wordCount) {
                  sortable.push([word, wordCount[word]]);
                }
                
                sortable.sort(function(a, b) {
                  return b[1] - a[1];
                });
    
                let keywordCount = 0;
                sortable.forEach(word => {
                  let keyword = {}
                  if(keywordCount < 10) {
                    if(commonWords.indexOf(word[0].toLowerCase()) == -1) {
                      // if(keyword != '') keyword += '+OR+'
                      keyword['_id'] = word[0]
                      keyword['value'] = word[1]

                      if(keywordCount < 3) {
                        keyword['checked'] = true
                      } else {
                        keyword['checked'] = false
                      }

                      keywordList.push(keyword)
                      keywordCount++
                    }
                  }
                });

                // console.log(keywordList)
    
                // this.topKeywords = keyword.split('+OR+').join(',')

                let pubmedSearchKeyword = keywordList.slice(0,3).map(function(keyword, index) { return keyword._id}).join('+AND+')
                this.topKeywords = keywordList.map(function(keyword, index) { return {
                    "keyword": keyword._id,
                    "count": keyword.value,
                    "checked": (index < 3)
                  }
                })
                // let pubmedSearchKeyword = keyword.split('+OR+').join('+OR+')

                if(!this.fromClinicalTrials) {
                  this.userService.eSearchforTerm(pubmedSearchKeyword, 'pub+date', 1, null).subscribe(
                    result => {
                      this.similarArticlesCount = result.esearchresult.count
                      let uIds = result.esearchresult.idlist.join(',')
                      this.userService.eSummaryforUID(uIds).subscribe(
                        result => {
                          for(let keys in result.result) {
                            if(keys != 'uids') this.similarArticles.push(result.result[keys])
                          }
                          this.loadingSimilarArticles = false

                          if(this.similarArticlesCount <= this.similarArticles.length) {
                            this.hideSeemore = true
                          }
                        }
                      )
                    }
                  )
                } else {
                  let start = 1
                  let end = 10
                  this.userService.getClinicalTrials(pubmedSearchKeyword, start, end).subscribe(
                    result => {
                      if(result.status) {
                        this.similarArticlesCount = result.clinicalTrials.StudyFieldsResponse.NStudiesFound
                        this.similarArticles = result.clinicalTrials.StudyFieldsResponse.StudyFields
                        this.loadingSimilarArticles = false
                      }

                      if(this.similarArticlesCount <= this.similarArticles.length) {
                        this.hideSeemore = true
                      }
                    }
                  )
                }
              }
            )
          }
        })
      }
    })
  }

  backToSearchResult() {
    let destinationPage = 'ideas';
    let myspaceIdeaType = 'ideas'

    if(this.fromMySpace) {
      destinationPage = 'myspace'
      if(this.fromPublication) myspaceIdeaType = 'publications'
      else if(this.fromClinicalTrials) myspaceIdeaType = 'clinicaltrials'
      else if(this.fromNews) myspaceIdeaType = 'news'

    } else if(this.fromClinicalTrials) {
      destinationPage = 'clinical-trials'
    } else if(this.fromNews) {
      destinationPage = 'news'
    } else if(this.fromPublication) {
      destinationPage = 'publications'
    }

    let navigationExtras: NavigationExtras = {
      queryParams: {
        'appliedFilters': JSON.stringify(this.appliedFilters),
        'searchKeyword': this.searchKeyword,
        'totalRecordsInDb': this.totalRecordsInDb,
        'browseBy': this.browseBy,
        'pageNo': this.pageNo,
        'loadWithFilters': 'true',
        'destination': destinationPage,
        'searchBy': this.searchBy,
        'myspaceIdeaType': myspaceIdeaType,
        'selectedMySpaceTarget': this.selectedMySpaceTarget
      }, skipLocationChange: true
    };

    this.router.navigate([''], navigationExtras)
  }

  loadArticleData(article) {
    // console.log(article)
    if(this.fromClinicalTrials) {
      this.clinicalTrialData = article
    } else {
      this.article = ''
      this.userService.eFetchForUIDText(article.uid).subscribe(
        artcileContent => {
          var doi = artcileContent.substr(artcileContent.lastIndexOf('DOI: ')+5, artcileContent.substr(artcileContent.lastIndexOf('DOI: ')).indexOf('\n')-5).trim()
          this.article = artcileContent.split(' ').reverse().join('~').replace(new RegExp(doi), '<a href="https://doi.org/'+doi+'" target="_blank">'+doi+'</a>').split('~').reverse().join(' ')
        }
      )
    }
  }

  sessionTimeOut() {
    localStorage.clear()
    // window.location.href = 'http://login.onixhub.com?sessionTimeout=true'
    window.location.href = 'http://login.onixhub.com?sessionTimeout=true'
  }

  updateSimilarKeywords(keywordIndex, selectAll) {
    if(!selectAll) {
      this.topKeywords[keywordIndex].checked = !this.topKeywords[keywordIndex].checked
    }

    let termArray = []

    this.topKeywords.forEach(keyword => {
      if(keyword.checked) {
        termArray.push(keyword.keyword)
      }
    });

    let term = termArray.join('+OR+')
    if(this.includeAll) {
      term = termArray.join('+AND+')
    }
    
    if(!this.fromClinicalTrials) {
      this.userService.eSearchforTerm(term, 'pub+date', 1, null).subscribe(
        result => {
          this.similarArticlesCount = result.esearchresult.count
          this.similarArticles = []
          // console.log(this.similarArticles.length, result.esearchresult)
          let uIds = result.esearchresult.idlist.join(',')
          this.userService.eSummaryforUID(uIds).subscribe(
            result => {
              for(let keys in result.result) {
                if(keys != 'uids') this.similarArticles.push(result.result[keys])
              }
              this.loadingSimilarArticles = false

              if(this.similarArticlesCount <= this.similarArticles.length) {
                this.hideSeemore = true
              }
            }
          )
        }
      )
    } else {
      let start = 1
      let end = 10
      this.userService.getClinicalTrials(term, start, end).subscribe(
        result => {
          if(result.status) {
            this.similarArticlesCount = result.clinicalTrials.StudyFieldsResponse.NStudiesFound
            this.similarArticles = result.clinicalTrials.StudyFieldsResponse.StudyFields
            this.loadingSimilarArticles = false
          }

          if(this.similarArticlesCount <= this.similarArticles.length) {
            this.hideSeemore = true
          }
        }
      )
    }
  }

  loadMoreSimilarArticles() {
    this.seeMoreText = 'Loading...'
    this.similarArticlesPage = this.similarArticlesPage + 1

    let termArray = []

    this.topKeywords.forEach(keyword => {
      if(keyword.checked) {
        termArray.push(keyword.keyword)
      }
    });

    let term  = termArray.join('+OR+')
    if(this.includeAll) {
      term  = termArray.join('+AND+')
    }

    if(!this.fromClinicalTrials) {
      this.userService.eSearchforTerm(term, 'pub+date', this.similarArticlesPage, null).subscribe(
        result => {
          this.similarArticlesCount = result.esearchresult.count
          let uIds = result.esearchresult.idlist.join(',')
          this.userService.eSummaryforUID(uIds).subscribe(
            result => {
              for(let keys in result.result) {
                if(keys != 'uids') this.similarArticles.push(result.result[keys])
              }
              this.seeMoreText = 'See more'

              if(this.similarArticlesCount <= this.similarArticles.length) {
                this.hideSeemore = true
              }
            }
          )
        }
      )
    } else {
      let start = ((this.similarArticlesPage - 1) * 20) + 1
      let end = (this.similarArticlesPage * 20)

      this.userService.getClinicalTrials(term, start, end).subscribe(
        result => {
          if(result.status) {
            this.similarArticlesCount = result.clinicalTrials.StudyFieldsResponse.NStudiesFound
            this.similarArticles = result.clinicalTrials.StudyFieldsResponse.StudyFields
            this.loadingSimilarArticles = false

            this.seeMoreText = 'See more'

            if(this.similarArticlesCount <= this.similarArticles.length) {
              this.hideSeemore = true
            }
          }
        }
      )
    }
  }

  highlightText(text, keywordIndex) {
    if(this.abstractText == '') {
      this.abstractText = this.abstract.nativeElement.innerHTML
      this.abstractTitle = this.title.nativeElement.innerHTML
    }

    this.abstractTop.nativeElement.scrollIntoView()

    this.topKeywords[keywordIndex].highlighted = true

    let abstractHighlighted = this.abstractText
    let abstractTitleHighlighted = this.abstractTitle

    this.topKeywords.forEach(keyword => {
      if(keyword.highlighted) {
        abstractHighlighted = keyword.keyword ?
          abstractHighlighted.replace(new RegExp('( '+keyword.keyword+' )','gi'), '<span class="highlight-text">$1</span>')
          : abstractHighlighted;

        abstractTitleHighlighted = keyword.keyword ?
          abstractTitleHighlighted.replace(new RegExp('( '+keyword.keyword+' )','gi'), '<span class="highlight-text">$1</span>')
          : abstractTitleHighlighted;
      }
    });

    this.abstract.nativeElement.innerHTML = abstractHighlighted
    this.title.nativeElement.innerHTML = abstractTitleHighlighted

    // this.abstract.nativeElement.innerHTML = text ?
    // this.abstract.nativeElement.innerHTML.replace(new RegExp('( '+text+' )','gi'), '<span class="highlight-text"> $1 </span>')
    // : this.abstract.nativeElement.innerHTML;

    // this.title.nativeElement.innerHTML = text ?
    // this.title.nativeElement.innerHTML.replace(new RegExp('( '+text+' )','gi'), '<span class="highlight-text"> $1 </span>')
    // : this.title.nativeElement.innerHTML;
  }

  unhighlightText(text, keywordIndex) {
    this.topKeywords[keywordIndex].highlighted = false

    let abstractHighlighted = this.abstractText
    let abstractTitleHighlighted = this.abstractTitle

    this.topKeywords.forEach(keyword => {
      if(keyword.highlighted) {
        abstractHighlighted = keyword.keyword ?
          abstractHighlighted.replace(new RegExp('( '+keyword.keyword+' )','gi'), '<span class="highlight-text">$1</span>')
          : abstractHighlighted;

        abstractTitleHighlighted = keyword.keyword ?
          abstractTitleHighlighted.replace(new RegExp('( '+keyword.keyword+' )','gi'), '<span class="highlight-text">$1</span>')
          : abstractTitleHighlighted;
      }
    });

    this.abstract.nativeElement.innerHTML = abstractHighlighted
    this.title.nativeElement.innerHTML = abstractTitleHighlighted

    // var startingText = new RegExp('<span class="highlight-text"> '+text, 'g');
    // var endingText = new RegExp('text"> '+text, 'g');

    // this.abstract.nativeElement.innerHTML = text ?
    // this.abstract.nativeElement.innerHTML.replace(/<span class="highlight/g,"").replace(/-/g,'').replace(endingText, text).replace(/ <\/span>/g, "")
    // : this.abstract.nativeElement.innerHTML;

    // this.title.nativeElement.innerHTML = text ?
    // this.title.nativeElement.innerHTML.replace(/<span class="highlight/g,"").replace(/-/g,'').replace(endingText, text).replace(/ <\/span>/g, "")
    // : this.title.nativeElement.innerHTML;
  }

  addCustomKeyword() {
    // console.log(this.customKeyword)
    // console.log(this.abstractText)

    if(this.abstractText == '') {
      this.abstractText = this.abstract.nativeElement.innerHTML
      this.abstractTitle = this.title.nativeElement.innerHTML
    }

    var wordOccurence = (this.abstractText.match(new RegExp(' '+this.customKeyword+' ', 'g')) || []).length;
    // console.log(wordOccurence)

    wordOccurence += (this.abstractTitle.match(new RegExp(' '+this.customKeyword+' ', 'g')) || []).length;
    // console.log((this.abstractTitle.match(new RegExp(' '+this.customKeyword+' ', 'g')) || []).length)

    this.topKeywords.push({
      "keyword": this.customKeyword,
      "count": wordOccurence,
      "checked": true
    })
    this.customKeyword = ''
  }

  selectAllKeywords(event) {
    if(event.currentTarget.checked) {
      this.topKeywords.forEach(keyword => {
        keyword.checked = true
      });
    } else {
      this.topKeywords.forEach(keyword => {
        keyword.checked = false
      });
    }

    this.updateSimilarKeywords(null, true)
  }

  includeAllKeywords(event) {
    this.includeAll = event.currentTarget.checked

    this.updateSimilarKeywords(null, true)
  }

  isString(abstract) {
    return typeof abstract === 'string'; 
  }
}

